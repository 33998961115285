import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function AppInfo({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="-5.5 0 24 24" width={size} {...props}>
      <G data-name="app info" id="app_info" transform="translate(0.5 0.5)">
        <Path
          d="M15.691.5H7.353A1.84,1.84,0,0,0,5.5,2.326V19.674A1.84,1.84,0,0,0,7.353,21.5h8.338a1.84,1.84,0,0,0,1.853-1.826V2.326A1.84,1.84,0,0,0,15.691.5Z"
          data-name="Path 155728"
          fill="none"
          id="Path_155728"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(-5.5 -0.5)"
        />
        <Path
          d="M17.543,19.5H5.5"
          data-name="Path 155729"
          fill="none"
          id="Path_155729"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(-5.5 -2.152)"
        />
        <Path
          d="M17.543,4.5H5.5"
          data-name="Path 155730"
          fill="none"
          id="Path_155730"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(-5.5 -0.848)"
        />
        <Path
          d="M10.5,2.5h2.779"
          data-name="Path 155731"
          fill="none"
          id="Path_155731"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(-5.868 -0.674)"
        />
        <Path
          d="M12.353,15.978V11.413a.907.907,0,0,0-.271-.646.933.933,0,0,0-.655-.267H10.5"
          data-name="Path 155732"
          fill="none"
          id="Path_155732"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(-5.868 -1.37)"
        />
        <Path
          d="M10.5,16.5h3.706"
          data-name="Path 155733"
          fill="none"
          id="Path_155733"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(-5.868 -1.891)"
        />
        <Path
          d="M11.982,21.707a.228.228,0,1,1,0-.457"
          data-name="Path 155734"
          fill="none"
          id="Path_155734"
          stroke="#373737"
          strokeWidth="1"
          transform="translate(-5.96 -2.305)"
        />
        <Path
          d="M12,21.707a.228.228,0,1,0,0-.457"
          data-name="Path 155735"
          fill="none"
          id="Path_155735"
          stroke="#373737"
          strokeWidth="1"
          transform="translate(-5.979 -2.305)"
        />
        <G data-name="Group 7489" id="Group_7489" transform="translate(5.558 6.848)">
          <Path
            d="M11.732,8.457a.228.228,0,1,1,0-.457"
            data-name="Path 155736"
            fill="none"
            id="Path_155736"
            stroke="#373737"
            strokeWidth="1"
            transform="translate(-11.5 -8)"
          />
          <Path
            d="M11.75,8.457a.228.228,0,1,0,0-.457"
            data-name="Path 155737"
            fill="none"
            id="Path_155737"
            stroke="#373737"
            strokeWidth="1"
            transform="translate(-11.518 -8)"
          />
        </G>
      </G>
    </Svg>
  );
});
