import { Radio, Text } from 'native-base';
import React, { ReactNode } from 'react';

import { Locale } from 'app/generated/hygraph';
import { useI18n } from 'app/providers/I18nProvider';

export type LocaleSelectorProps = {
  children?: ReactNode;
};

export default function LocaleRadio() {
  const { locale, locales, setLocale } = useI18n();

  return (
    <Radio.Group
      accessibilityLabel="Selected Language"
      name="locales"
      testID="LocaleSelector"
      value={locale}
      onChange={($locale) => setLocale($locale as Locale)}
    >
      {locales.map(([$locale, { label }]) => (
        <Radio key={$locale} value={$locale}>
          <Text px={3} py={3.5}>
            {label}
          </Text>
        </Radio>
      ))}
    </Radio.Group>
  );
}
