/* eslint-disable global-require */
import Constants from 'expo-constants';
import { Box, Button, HStack, IconButton, Image, Text, VStack } from 'native-base';
import { Linking } from 'react-native';

import { Close } from 'app/components/icons';
import usePromptAppDownload from 'app/hooks/usePromptAppDownload';
import { useI18n } from 'app/providers/I18nProvider';
import { tID } from 'app/services/TestHelper';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const appIcon = require('app/assets/images/icon-android.png');

export default function AppDownloadBanner() {
  const { t } = useI18n();
  const { canPrompt, markPrompted } = usePromptAppDownload();

  const onClose = () => {
    markPrompted();
  };

  const onGoToPlayStore = () => {
    Linking.openURL(Constants.expoConfig?.extra?.install?.playStoreURL);
    markPrompted();
  };

  if (!canPrompt) return null;

  return (
    <Box
      backgroundColor="white"
      height={70}
      p={2}
      shadow={5}
      testID={tID('AppDownloadBanner')}
      zIndex={1}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <HStack alignItems="center" space={2}>
          <IconButton
            icon={<Close />}
            size={18}
            testID={tID('AppDownloadBannerCloseButton')}
            onPress={onClose}
          />
          <Box
            borderColor="brand.border"
            borderRadius={5}
            borderWidth={1}
            overflow="hidden"
            size={50}
          >
            <Image alt="MyCityPass" size="100%" source={appIcon} />
          </Box>
          <VStack>
            <Text fontSize="md" fontWeight="700">
              {t('app_auth_header')}
            </Text>
            <Text fontSize="sm">{t('app_download_banner_text')}</Text>
          </VStack>
        </HStack>

        <Button
          backgroundColor="brand.primary"
          size="sm"
          style={{ minHeight: 32, minWidth: 95, padding: 2 }}
          testID={tID('AppDownloadBannerOpenButton')}
          onPress={onGoToPlayStore}
        >
          {t('app_download_banner_open')}
        </Button>
      </HStack>
    </Box>
  );
}
