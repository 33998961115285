import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function HoursInfo({ color, size, ...props }) {
  return (
    <Svg fill="none" height={size} viewBox="0 0 18 18" width={size}>
      <Path
        d="M1.05859 8.99951C1.05888 10.5817 1.52832 12.1283 2.40755 13.4437C3.28677 14.7591 4.5363 15.7842 5.99814 16.3895C7.45997 16.9948 9.06846 17.153 10.6202 16.8441C12.172 16.5353 13.5973 15.7733 14.716 14.6544C15.8346 13.5355 16.5964 12.11 16.905 10.5582C17.2135 9.00642 17.055 7.39796 16.4495 5.93624C15.8439 4.47452 14.8186 3.22517 13.503 2.34619C12.1874 1.4672 10.6408 0.998047 9.05859 0.998047C8.00789 0.998047 6.96749 1.20502 5.99679 1.60715C5.02609 2.00928 4.14411 2.59869 3.40122 3.34171C2.65833 4.08473 2.06909 4.96682 1.66714 5.93759C1.26519 6.90836 1.0584 7.94881 1.05859 8.99951Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
      />
      <Path
        d="M10.8773 12.6387H9.78638C9.59372 12.6377 9.40922 12.5607 9.27299 12.4245C9.13676 12.2883 9.0598 12.1038 9.05884 11.9111V7.18396C9.05884 7.08699 9.02032 6.994 8.95176 6.92544C8.88319 6.85688 8.7902 6.81836 8.69324 6.81836H7.60449"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
      />
      <Path
        d="M8.87347 4.99805C8.83746 4.99805 8.80226 5.00873 8.77232 5.02873C8.74238 5.04874 8.71904 5.07717 8.70526 5.11044C8.69148 5.14371 8.68788 5.18032 8.69491 5.21563C8.70193 5.25095 8.71927 5.28339 8.74473 5.30886C8.7702 5.33432 8.80264 5.35166 8.83795 5.35868C8.87327 5.36571 8.90988 5.3621 8.94315 5.34832C8.97642 5.33454 9.00485 5.31121 9.02486 5.28127C9.04486 5.25132 9.05554 5.21612 9.05554 5.18011C9.05554 5.13183 9.03636 5.08552 9.00221 5.05137C8.96807 5.01723 8.92176 4.99805 8.87347 4.99805Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
      />
    </Svg>
  );
});
