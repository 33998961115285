import { IStackProps, VStack } from 'native-base';
import React, { ReactNode } from 'react';

import AspectImage from 'app/components/AspectImage';
import { useProductContext } from 'app/providers/ProductProvider';
import { getCityName, getLogoWhite } from 'app/services/ProductHelper';

export type ProductLogoProps = IStackProps & {
  children?: ReactNode;
};

export default function ProductLogo({ ...props }: ProductLogoProps) {
  const { productContent } = useProductContext();
  const logo = getLogoWhite(productContent);

  return (
    <VStack alignItems="center" testID="ProductLogo" {...props}>
      <AspectImage
        // can't get backdrop-filter: drop-shadow working here
        _web={{ shadow: 'none' }}
        alt={getCityName(productContent)}
        h="20"
        ratio={logo.ratio}
        shadow={9}
        url={logo.url}
      />
    </VStack>
  );
}
