import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotSouthernCalifornia({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M38.66 82.21c-.4-5.02-3.07-6.47-5.34-6.47-3.23 0-5.01 2.1-5.01 5.74 0 9.95 20.38 14.72 20.38 30.57 0 9.62-6.47 14.96-15.85 14.96s-14.64-7.44-15.04-16.17l9.54-1.37c.4 5.9 2.75 8.81 5.98 8.81 3.48 0 5.82-1.86 5.82-5.34 0-11.57-20.38-14.64-20.38-31.38 0-9.3 5.66-14.56 15.53-14.56 8.17 0 13.02 5.9 13.91 14.15l-9.54 1.05Zm14.37-4.33c0-6 3.53-10.87 11.17-10.87s11.17 4.88 11.17 10.87v21.86c0 5.99-3.53 10.87-11.17 10.87s-11.17-4.88-11.17-10.87V77.88Zm7.29 21.69c0 3.17.94 4.7 3.88 4.7s3.88-1.53 3.88-4.7V78.06c0-3.17-.94-4.7-3.88-4.7s-3.88 1.53-3.88 4.7v21.51Zm40.99-12.26v-4.53c0-4.93-2.26-7.04-4.85-7.04-4.04 0-5.34 2.1-5.34 6.47v29.6c0 4.37 1.29 6.47 5.34 6.47 3.64 0 4.85-2.1 4.85-5.9v-7.04h10.03v6.71c0 8.25-4.85 14.96-14.88 14.96S81.1 120.3 81.1 112.05V81.97c0-8.25 4.85-14.96 15.36-14.96s14.88 7.28 14.88 16.01v4.29h-10.03Zm22.39 38.9h-9.71l10.76-58.39h12.45l10.76 58.39h-9.71l-1.78-12.94h-11l-1.78 12.94Zm3.23-21.67h8.09l-3.96-25.07h-.16l-3.96 25.07Zm25.13 21.67V67.82h10.03v49.65h15.77v8.73h-25.8Zm-97.63-5.79h19.52v5.78H54.43z"
        fill="#000"
      />
    </Svg>
  );
});
