import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function CheckCircle({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 32 32" width={size} {...props}>
      <Path d="M28.444 13.867c.111.636.178 1.374.187 2.125v.059c0 7.663-6.212 13.876-13.876 13.876S.879 23.715.879 16.051c0-7.663 6.212-13.876 13.876-13.876 2.964 0 5.71.929 7.964 2.512l-.044-.029-1.778 1.831a11.224 11.224 0 00-6.157-1.818c-6.259 0-11.333 5.074-11.333 11.333S8.481 27.337 14.74 27.337c6.167 0 11.183-4.925 11.33-11.057v-.014zM27.209 3.618L15.369 15.76l-4.836-4.551-3.902 3.902 8.738 8.453L31.111 7.52z" />
    </Svg>
  );
});
