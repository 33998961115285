import Constants from 'expo-constants';
import { GraphQLClient } from 'graphql-request';
import { RateLimiter } from 'limiter';

import { Stage } from 'app/generated/hygraph';
import Logger from 'app/services/Logger';

export enum ContentEnv {
  Master = 'master',
  Development = 'development',
}

export type HygraphServiceProps = {
  env: ContentEnv;
  stage?: Stage;
};

export type HygraphService = ReturnType<typeof HygraphService>;

const MODULE = '[HygraphService]';
let instance: HygraphService;

export default function HygraphService(
  { env = ContentEnv.Master, stage = Stage.Published }: HygraphServiceProps = {
    env: ContentEnv.Master,
    stage: Stage.Published,
  }
) {
  if (!instance) {
    instance = createInstance({ env, stage });
  }
  return instance;
}

function getConfig() {
  return {
    apiURLTemplate: Constants.expoConfig?.extra?.hygraph?.apiURLTemplate ?? '',
    apiDraftToken: Constants.expoConfig?.extra?.hygraph?.apiDraftToken ?? undefined,
    rpsLimit: Constants.expoConfig?.extra?.hygraph?.rpsLimit ?? 4,
  };
}

function createInstance({ env }: HygraphServiceProps) {
  const client = createClient(env);

  return {
    client,
    setEnv: ($env: ContentEnv) => setEnv(client, $env),
    setStage: ($stage: Stage) => setStage(client, $stage),
  };
}

function createClient(env: ContentEnv) {
  const client = new GraphQLClient(getEndpoint(env), {
    method: 'GET',
    jsonSerializer: JSON,
    ...(__DEV__ && {
      fetch: fetchWithDebugging,
    }),
  });

  client.request = createRequestWrapper(client);

  return client;
}

function getEndpoint(env: ContentEnv = ContentEnv.Master) {
  return getConfig().apiURLTemplate.replace('{env}', env);
}

function setEnv(client: GraphQLClient, env: ContentEnv) {
  client.setEndpoint(getEndpoint(env));
}

function setStage(client: GraphQLClient, stage: Stage) {
  const { apiDraftToken } = getConfig();

  if (stage === Stage.Draft && apiDraftToken) {
    client.setHeader('Authorization', `Bearer ${apiDraftToken}`);
  } else {
    client.setHeader('Authorization', '');
  }
}

// adds rate limiting and logging
function createRequestWrapper(client: GraphQLClient) {
  type ClientRequest = typeof client.request;

  const { request } = client;
  const config = getConfig();
  const limiter = new RateLimiter({
    tokensPerInterval: config.rpsLimit,
    interval: 'second',
  });
  const requestWrapper: ClientRequest = async (...args) => {
    try {
      Logger.debug(`${MODULE} request`, {
        method: 'GET',
        url: config.apiURLTemplate,
        operationName: (args[0].toString().match(/query (\w+)/) ?? [])[1] ?? 'UNKNOWN',
        variables: args[1],
      });
      await limiter.removeTokens(1);
      const res = await request.apply(client, args as Parameters<ClientRequest>);

      // Logger.debug(`${MODULE} request success`, {
      //   method: 'GET',
      //   url: config.apiURLTemplate,
      //   data: res,
      // });

      return res;
    } catch (err) {
      Logger.error(`${MODULE} request error`, { err });
      throw err;
    }
  };

  return requestWrapper;
}

async function fetchWithDebugging(...args: Parameters<typeof fetch>) {
  const res = await fetch(...args);
  const url = args[0] as string;
  const queryParams = (url.split('?')[1] ?? '')
    .split('&')
    .reduce<Record<string, string>>((acc, param) => {
      const [key, value] = param.split('=');
      acc[key] = value;
      return acc;
    }, {});

  Logger.debug(`${MODULE} fetch request success`, {
    method: args[1]?.method,
    status: res.status,
    operationName: queryParams.operationName,
    variables: parseVariables(queryParams.variables),
    complexity: {
      actual: prettyNum(res.headers.get('x-gcms-query-complexity-actual')),
      estimate: prettyNum(res.headers.get('x-gcms-query-complexity-estimate')),
    },
  });

  return res;
}

function parseVariables(vars: Maybe<string>) {
  if (!vars) return vars;
  try {
    return JSON.parse(decodeURIComponent(vars));
  } catch (err) {
    return vars;
  }
}

function prettyNum(value: Maybe<string>) {
  return Number(value).toLocaleString();
}
