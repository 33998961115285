import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

const LOGO_RATIO = 0.318697;

export default withIconWrapper(function CitypassLogo({ color, size = 50, ...props }) {
  return (
    <Svg
      height={size * LOGO_RATIO}
      viewBox="-135 342.7 339.8 108.3"
      width={size}
      x="0px"
      y="0px"
      {...props}
    >
      <Path
        d="M118.1 422.6c-9.5 0-16.6-5.4-19.4-14.8l9-1.8c2.2 6 7.1 7.4 10.7 7.4 3.8 0 7.9-2.7 7.9-6 0-4.9-5.4-6.6-11.1-9.4-6.7-3.3-14.3-6.4-14.3-16.1 0-4.6 1.5-8.2 4.4-10.7 3-2.6 7.4-4.6 13-4.6 9 0 15.5 5.6 18.4 12.5l-8.9 2.5c-2.2-4-5.9-5.9-9.7-5.9-4.1 0-7.6 2.2-7.6 5.7 0 5.4 5.3 6.6 10.4 9.2 7.1 3.4 15.8 6.5 15.8 16.9-.1 7.8-8.8 15.1-18.6 15.1zM160.4 422.6c-9.5 0-16.6-5.4-19.4-14.8l9-1.8c2.2 6 7.1 7.4 10.7 7.4 3.8 0 7.9-2.7 7.9-6 0-4.9-5.4-6.6-11.1-9.4-6.7-3.3-14.3-6.4-14.3-16.1 0-4.6 1.5-8.2 4.4-10.7 3-2.6 7.4-4.6 13-4.6 9 0 15.5 5.6 18.4 12.5l-8.9 2.5c-2.2-4-5.9-5.9-9.7-5.9-4.1 0-7.6 2.2-7.6 5.7 0 5.4 5.3 6.6 10.4 9.2 7.1 3.4 15.8 6.5 15.8 16.9-.1 7.8-8.8 15.1-18.6 15.1z"
        fill="#eb0029"
      />
      <Path
        d="M-40 382.6v-13h6.9c1.3 0 2.4 1.1 2.4 2.4v10.6h5.9c1.1 0 2 .7 2.3 1.7l1.8 5.8h-10v22.2c0 2.1 1.1 2.7 3.4 2.7 1.4 0 2.9-.5 4.3-.8v7.7c-2.6.3-4.8.6-7.4.6-6.9 0-9.7-3.5-9.7-9.1V390h-5.4v-7.5h5.5zM-18.4 428.1c1.4.3 2.6.3 3.9.3 3.2 0 5.1-.9 6.2-3.1.9-1.9 1.5-3 1.5-4.6 0-1.4-.8-3.1-1.2-4.7l-10.5-33.5h6.4c1.8 0 3.4 1.2 3.9 3l5.7 21.2h.2l6.7-24.1h9.4L1.3 421.9c-4.1 11.2-7.5 14.3-15.2 14.3-2.3 0-3.4-.2-4.5-.3v-7.8z"
        fill="#252C3E"
      />
      <Path
        d="M70 367.3h8.9c2.1 0 4 1.4 4.6 3.5L98 421.9H87.9l-3-11.8H67.6l-3.2 11.8H54.3L70 367.3zm-.1 34.1h12.7l-6.1-23.7h-.2l-6.4 23.7zM-56.3 376.3c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3 5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3zM42.4 367.3H20.3v54.5h9.6v-22h12.5c9 0 16.2-7.3 16.2-16.2 0-9-7.3-16.3-16.2-16.3zm-.6 24H29.9v-15.5h11.9c4.3 0 7.8 3.5 7.8 7.8-.1 4.2-3.6 7.7-7.8 7.7z"
        fill="#eb0029"
      />
      <Path
        d="M-83.8 413.4c-5.3 0-9.8-3.9-10.7-8.7-.7-3.8-.6-7.2-.6-10.1 0-2.9-.1-6.3.6-10.1.7-4.4 5.4-8.7 10.7-8.7 4.3 0 8 2.5 9.8 6.2l8.9-2.5c-2.9-7.5-10.2-12.9-18.7-12.9-10.3 0-19.1 7.8-20.2 17.9 0 0-.4 3.9-.4 10.2 0 6.3.4 10 .4 10 1.1 10 9.8 17.9 20.2 17.9 8.6 0 16-5.5 18.8-13.1l-8.9-2.4c-1.7 3.7-5.5 6.3-9.9 6.3zM-61 421.8v-39.2h7c1.3 0 2.4 1.1 2.4 2.4v36.8H-61z"
        fill="#252C3E"
      />
      <Path
        d="M180.6 421.8c-1.7 0-3-1.3-3-3 0-1.6 1.3-3 3-3s3 1.3 3 3c0 1.6-1.3 3-3 3zm0-5.6c-1.5 0-2.6 1.2-2.6 2.7 0 1.5 1.1 2.6 2.6 2.6s2.6-1.2 2.6-2.7c.1-1.5-1.1-2.6-2.6-2.6zm.8 3l.8 1.1h-.8l-.7-1h-.6v1h-.7v-3.2h1.4c.8 0 1.2.4 1.2 1s-.2.9-.6 1.1zm-.6-1.4h-.7v.9h.7c.4 0 .5-.2.5-.5s-.2-.4-.5-.4z"
        fill="#eb002a"
      />
    </Svg>
  );
});
