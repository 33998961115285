import { Center, Flex, IFlexProps, Spinner, Text } from 'native-base';
import React, { ReactNode } from 'react';

export type LoadingProps = IFlexProps & {
  children?: ReactNode;
  testID?: string;
  text?: string;
};

export default function Loading({ text, testID = 'Loading', ...props }: LoadingProps) {
  return (
    <Flex h="full" justifyContent="center" testID={testID} {...props} role="progressbar">
      <Spinner color="gray.500" role="presentation" size="lg" />
      <Center>{text && <Text>{text}</Text>}</Center>
    </Flex>
  );
}
