import * as React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function AllDestinations({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="-2 -1 20.75 19.25" width={size} {...props}>
      <G
        fill="none"
        stroke="#373737"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
        transform="translate(.5 .5)"
      >
        <Path
          d="M3.016 14.257l.717-3.59H4.5a1.067 1.067 0 001.035-1.325l-.533-2.133A1.067 1.067 0 003.967 6.4H.161"
          data-name="Path 155755"
        />
        <Path
          d="M14.39 3.2H11.5a1.067 1.067 0 00-1.035.809l-.533 2.133a1.067 1.067 0 001.035 1.324h1.122l.563 3.376a1.067 1.067 0 001.052.891h1.37"
          data-name="Path 155756"
        />
        <Circle cx={8} cy={8} data-name="Ellipse 403" r={8} />
      </G>
    </Svg>
  );
});
