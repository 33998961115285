/* eslint-disable no-useless-escape, no-control-regex */

import { Children, JSXElementConstructor, ReactNode } from 'react';

export function isEmail(email: string): boolean {
  // same as knockout.validation from GuestCenter
  // https://github.com/Knockout-Contrib/Knockout-Validation/blob/913a0b234121af5ce6a11dd3cefbfdb045d3a8db/src/rules.js#L198
  return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
    email
  );
}

export function isValidAuthLink(url: string) {
  // include edgepilot links for now as this will help with internal testing until
  return isDynamicLinkLink(url) || url.startsWith('https://link.edgepilot.com/');
}

export function isDynamicLinkLink(url: string) {
  return (
    url.startsWith('https://links.citypass.com/') ||
    url.startsWith('https://links.citypassdev.com/')
  );
}

export function hasValidChildren(children: ReactNode, types: JSXElementConstructor<any>[]) {
  return Children.toArray(children).every((child) => {
    const childType = (child as React.ReactElement).type;
    return typeof childType === 'string' ? false : types.includes(childType);
  });
}
