import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotTampaBay({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M71.74 74.46h-10.7v-9.63h32.45v9.63h-10.7v54.74H71.73V74.46Zm26.48-9.63h16.4c11.86 0 17.12 6.06 17.12 17.03 0 5.53-2.32 11.32-7.67 13.46 5.62 2.5 8.92 6.95 8.92 15.25 0 10.61-4.73 18.63-17.3 18.63H98.22V64.83Zm11.05 26.39h4.01c4.19 0 7.4-2.85 7.4-8.2 0-5.97-2.32-9.09-7.22-9.09h-4.19v17.3Zm0 28.89h4.64c5.88 0 8.02-3.39 8.02-9.9s-2.5-9.9-9.09-9.9h-3.57v19.79Z"
        fill="#000"
      />
    </Svg>
  );
});
