import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotToronto({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M50.69 74.46h-10.7v-9.63h32.45v9.63h-10.7v54.74H50.68V74.46Zm26.49 5.97c0-9.09 5.35-16.49 16.94-16.49s16.94 7.4 16.94 16.49v33.17c0 9.09-5.35 16.49-16.94 16.49s-16.94-7.4-16.94-16.49V80.43Zm11.06 32.9c0 4.81 1.43 7.13 5.88 7.13s5.88-2.32 5.88-7.13V80.7c0-4.81-1.43-7.13-5.88-7.13s-5.88 2.32-5.88 7.13v32.63Zm41 15.87h-11.06V64.83h15.51c12.39 0 18.72 5.26 18.72 18.99 0 10.34-4.01 14.53-7.76 16.23l9.36 29.15h-11.23l-7.85-26.66c-1.43.18-3.74.27-5.71.27v26.39Zm0-35.48h3.92c5.97 0 8.2-2.23 8.2-9.9s-2.23-9.9-8.2-9.9h-3.92v19.79Z"
        fill="#000"
      />
    </Svg>
  );
});
