import { Box, IScrollViewProps, ScrollView, VStack } from 'native-base';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { ScrollView as RNScrollView } from 'react-native';

import OfflineBanner from 'app/components/OfflineBanner';
import ResponsiveContainer, { DEFAULT_PADDING } from 'app/components/ResponsiveContainer';

export type ScreenProps = IScrollViewProps & {
  padded?: boolean;
  before?: ReactNode;
  beforeContainer?: ReactNode;
  after?: ReactNode;
};

function Screen(
  { children, padded = true, before, after, beforeContainer, ...props }: ScreenProps,
  ref: Ref<RNScrollView>
) {
  return (
    <>
      {before}
      <OfflineBanner />
      <ScrollView {...props} ref={ref}>
        {beforeContainer}
        <ResponsiveContainer
          padded={false}
          {...(padded ? { pl: DEFAULT_PADDING, pr: DEFAULT_PADDING, pt: DEFAULT_PADDING } : {})}
        >
          {children}
        </ResponsiveContainer>
        <Box h="12" />
      </ScrollView>
      {after}
    </>
  );
}

export default forwardRef(Screen);
