import { Button } from 'native-base';
import React, { useRef } from 'react';

import CPAlertDialog from 'app/components/CPAlertDialog';
import { useI18n } from 'app/providers/I18nProvider';
import { tID } from 'app/services/TestHelper';

export type RedirectDialogProps = {
  url: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
};

export default function RedirectDialog({
  url,
  isOpen,
  onClose,
  onConfirm,
  onCancel,
}: RedirectDialogProps) {
  const { t } = useI18n();
  const ref = useRef();

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };
  const handleCancel = () => {
    if (onCancel) onCancel();
    onClose();
  };

  return (
    <CPAlertDialog isOpen={isOpen} leastDestructiveRef={ref}>
      <CPAlertDialog.Content testID={tID('RedirectDialog')}>
        <CPAlertDialog.Header>{t('app_menu_redirect_title')}</CPAlertDialog.Header>
        <CPAlertDialog.Body>
          {t('app_menu_redirect_description', { url: withoutQueryParams(url) })}
        </CPAlertDialog.Body>
        <CPAlertDialog.Footer>
          <Button onPress={handleConfirm}>{t('gen_yes')}</Button>
          <Button variant="link" onPress={handleCancel}>
            {t('gen_no')}
          </Button>
        </CPAlertDialog.Footer>
      </CPAlertDialog.Content>
    </CPAlertDialog>
  );
}

function withoutQueryParams(url = '') {
  return url.split('?')[0];
}
