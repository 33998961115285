import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Corner({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size} {...props}>
      <Path
        d="M4.921 1.531C.437 4.265 0 6.107 0 22.266 0 38.424.437 40.267 4.921 43c2.461 1.501 5.304 1.525 176.524 1.528l174.024.003.003 174.024c.003 195.134-.535 177.17 5.392 180.235 3.865 1.999 30.826 1.746 34.215-.321C400.428 395.208 400 412.467 400 200c0-212.467.428-195.208-4.921-198.469C391.142-.87 8.858-.87 4.921 1.531"
        stroke={color}
        strokeWidth="6"
      />
    </Svg>
  );
});
