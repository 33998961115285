import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Cross({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="1 2.75 26 26" width={size} {...props}>
      <Path
        d="M16 0L8 8 0 0M0 16l8-8 8 8"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        transform="translate(6, 7)"
      />
    </Svg>
  );
});
