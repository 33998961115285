import { UseQueryOptions } from 'react-query';

import { GetDestinationsQuery, useGetDestinationsQuery } from 'app/generated/hygraph';
import { useI18n } from 'app/providers/I18nProvider';
import HygraphService from 'app/services/HygraphService';

import placeholderData from './data.json';

const { client } = HygraphService();

if (!placeholderData.products?.every((p) => Boolean(p.key && p.name && p.slug && p.cityName))) {
  throw new Error(
    'useDestinations initial data from data.json does not match type GetDestinationsQuery'
  );
}

export type Destination = Single<ReturnType<typeof useDestinations>['data']>;

export default function useDestinations({
  enabled = true,
  notifyOnChangeProps,
}: {
  enabled?: UseQueryOptions['enabled'];
  notifyOnChangeProps?: UseQueryOptions['notifyOnChangeProps'];
} = {}) {
  const { locale } = useI18n();
  const { data, ...useQueryResult } = useGetDestinationsQuery(
    client,
    { locale },
    {
      select: ($data) => $data.products,
      enabled,
      placeholderData: placeholderData as GetDestinationsQuery,
      notifyOnChangeProps,
    }
  );

  return {
    ...useQueryResult,
    data: data ?? (placeholderData.products as GetDestinationsQuery['products']),
  };
}
