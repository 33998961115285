import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Tips({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 21.499 23.749" width={size} {...props}>
      <Path
        d="M20.249 20.999a1.53 1.53 0 01-1.558 1.5H1.558A1.53 1.53 0 010 20.999V1.5A1.53 1.53 0 011.558 0h11.037a1.588 1.588 0 011.1.439l6.1 5.872a1.472 1.472 0 01.456 1.06z"
        stroke={color}
        transform="translate(.625 .625)"
      />
      <Path
        d="M20.249 8.318h-6.654a1.663 1.663 0 01-1.663-1.663V0M8.632 8.701l-2.364 3.152a.832.832 0 01-1.253.089L3.732 10.66M8.632 15.331l-2.364 3.152a.832.832 0 01-1.253.089l-1.283-1.283M11.807 11.999h4.284M11.807 17.962h4.284"
        stroke={color}
        transform="translate(.625 .625)"
      />
    </Svg>
  );
});
