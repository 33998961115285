import { CustomRendererProps, TBlock, useInternalRenderer } from 'react-native-render-html';

import OpenChat from 'app/components/HTML/LinkRenderer/OpenChat';

/**
 * This is a custom renderer for the `a` tag that handles specific behaviors based on the `data-trigger` attribute.
 * It allows rendering a specialized component when the `data-trigger` attribute matches a known key.
 *
 * Usage Example:
 * ```tsx
 * import HTML from 'app/components/HTML/HTML';
 * import LinkRenderer from 'app/components/HTML/LinkRenderer';
 *
 * const htmlContent = '<a href="#" data-trigger="open-chat">Chat with us!</a>';
 * <HTML html={htmlContent} renderers={{ a: LinkRenderer }} />
 * ```
 */

const LinkRenderTriggerComponents: Record<
  string,
  (props: CustomRendererProps<TBlock>) => JSX.Element
> = {
  'open-chat': OpenChat,
};

export default function LinkRenderer({ TDefaultRenderer, ...props }: CustomRendererProps<TBlock>) {
  const { Renderer, rendererProps } = useInternalRenderer('a', { TDefaultRenderer, ...props });

  const trigger = props.tnode.attributes['data-trigger'];
  if (trigger && LinkRenderTriggerComponents[trigger]) {
    const Component = LinkRenderTriggerComponents[trigger];
    return <Component {...props} TDefaultRenderer={TDefaultRenderer} />;
  }

  return <Renderer {...rendererProps} />;
}
