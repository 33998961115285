import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotSanFrancisco({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M84.26 80.7c-.45-5.53-3.39-7.13-5.88-7.13-3.57 0-5.53 2.32-5.53 6.33 0 10.97 22.47 16.23 22.47 33.7 0 10.61-7.13 16.49-17.47 16.49s-16.14-8.2-16.58-17.83l10.52-1.52c.45 6.51 3.03 9.72 6.6 9.72 3.83 0 6.42-2.05 6.42-5.88 0-12.75-22.47-16.14-22.47-34.59 0-10.25 6.24-16.05 17.12-16.05 9 0 14.35 6.51 15.33 15.6L84.27 80.7Zm17.66 48.5V64.83h30.85v9.63h-19.79v16.58h14.8v9.63h-14.8v28.53h-11.06Z"
        fill="#000"
      />
    </Svg>
  );
});
