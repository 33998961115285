import { HeaderBackButton } from '@react-navigation/elements';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import { Toast } from 'native-base';
import React from 'react';

import HeaderStatusBar from 'app/components/HeaderStatusBar';
import useAuth from 'app/hooks/useAuth';
import useHeaderScreenOptions from 'app/hooks/useHeaderScreenOptions';
import AuthenticationEnterEmailScreen from 'app/screens/Authentication/AuthenticationEnterEmailScreen';
import AuthenticationTicketLoaderScreen from 'app/screens/Authentication/AuthenticationTicketLoaderScreen/AuthenticationTicketLoaderScreen';
import AuthenticationVerifyEmailScreen from 'app/screens/Authentication/AuthenticationVerifyEmailScreen';

import AuthenticationNavProvider from './AuthenticationNavProvider';

export type AuthenticationStackParamList = {
  AuthenticationStepEnterEmail: undefined; // AuthenticationParams;
  AuthenticationStepVerifyEmail?: {
    mode?: string;
  };
  AuthenticationStepTicketLoader: undefined;
};

export type AuthenticationStackScreenProps<Screen extends keyof AuthenticationStackParamList> =
  NativeStackScreenProps<AuthenticationStackParamList, Screen>;

const Stack = createNativeStackNavigator<AuthenticationStackParamList>();
const MODULE = '[AuthenticationStackNavigator]';

export default function AuthenticationStackNavigator() {
  const headerScreenOptions = useHeaderScreenOptions();
  const { resetVerificationState } = useAuth();

  const handleVerifyBackBtn = () => {
    resetVerificationState();
    Toast.closeAll();
  };

  return (
    <AuthenticationNavProvider>
      <Stack.Navigator
        screenOptions={{
          ...headerScreenOptions,
          title: '',
          headerShown: true,
          headerBackVisible: false,
          headerShadowVisible: false,
        }}
      >
        <Stack.Screen
          component={AuthenticationEnterEmailScreen}
          name="AuthenticationStepEnterEmail"
          options={{
            header: () => <HeaderStatusBar />,
          }}
        />

        <Stack.Screen
          component={AuthenticationVerifyEmailScreen}
          name="AuthenticationStepVerifyEmail"
          options={{
            headerLeft: (props) => <HeaderBackButton {...props} onPress={handleVerifyBackBtn} />,
          }}
        />

        <Stack.Screen
          component={AuthenticationTicketLoaderScreen}
          name="AuthenticationStepTicketLoader"
          options={{
            header: () => <HeaderStatusBar />,
          }}
        />
      </Stack.Navigator>
    </AuthenticationNavProvider>
  );
}
