import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function PrivacyPolicy({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 23.867 21.825" width={size}>
      <G
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
        {...props}
      >
        <Path
          d="m1242.95 692.977-6.444 1.208a.645.645 0 0 1 -.756-.507l-.66-3.4a2.894 2.894 0 0 0 -1.1-5.653l-.66-3.4a.64.64 0 0 1 .511-.75l18.394-3.52a.645.645 0 0 1 .756.507l.655 3.374a2.9 2.9 0 0 0 1.107 5.7l.655 3.374"
          transform="translate(-1232.694 -676.312)"
        />
        <Path
          d="m1264.049 706.848h4.922c2.685 0 3.58 2.5 3.58 4.475a6.257 6.257 0 0 1 -1.342 3.58v-2.237c0-.9-.448-1.342-2.237-1.342h-4.922v1.6a.448.448 0 0 1 -.764.317l-3.842-3.844a.448.448 0 0 1 0-.633l3.842-3.842a.448.448 0 0 1 .764.317z"
          transform="translate(-1249.309 -693.703)"
        />
        <Path
          d="m1250.137 692.077a9.1 9.1 0 0 1 3.162 3.278c.488.9.614-.167 1.12-1.751a8.648 8.648 0 0 1 3.929-5.175"
          transform="translate(-1242.933 -684.592)"
        />
      </G>
    </Svg>
  );
});
