import React, { ReactNode } from 'react';

import ErrorFallback from 'app/components/ErrorFallback';
import { BugsnagErrorBoundary } from 'app/services/Bugsnag';

export type ErrorBoundaryProps = {
  children?: ReactNode;
};

/**
 * Cannot use NativeBase here since it wraps all other components.
 */
export default function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return <BugsnagErrorBoundary FallbackComponent={ErrorFallback}>{children}</BugsnagErrorBoundary>;
}
