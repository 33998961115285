import noop from 'lodash/noop';
import { createContext, useContext, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { Locale, useGetContentQuery } from 'app/generated/hygraph';
import useStore, { Store } from 'app/hooks/useStore';
import HygraphService from 'app/services/HygraphService';
import {
  createTemplateHelper,
  createTranslationHelper,
  initialContent,
  mapRemoteContentToI18n,
  TemplateHelper,
  TranslationHelper,
} from 'app/services/I18n';

const { client } = HygraphService();

type I18nContextProps = Pick<Store, 'locale' | 'locales' | 'setLocale'> & {
  content: typeof initialContent;
  t: TranslationHelper;
  tpl: TemplateHelper;
};

const I18nContext = createContext<I18nContextProps>({
  locale: Locale.En,
  locales: [],
  setLocale: noop,
  content: initialContent,
  t: createTranslationHelper(() => ({
    content: initialContent,
    locale: Locale.En,
    showContentKeys: false,
  })),
  tpl: createTemplateHelper(() => ({
    content: initialContent,
    locale: Locale.En,
  })),
});

export const useI18n = () => useContext(I18nContext);

const notifyOnChangeProps: NonNullable<
  Parameters<typeof useGetContentQuery>[2]
>['notifyOnChangeProps'] = ['data'];

export default function I18nProvider({ children }: { children: JSX.Element }) {
  const [locale, locales, setLocale, showContentKeys] = useStore(
    (s) => [s.locale, s.locales, s.setLocale, s.showContentKeys],
    shallow
  );
  const { data: content = initialContent } = useGetContentQuery(
    client,
    { locale },
    {
      notifyOnChangeProps,
      keepPreviousData: true,
      select: mapRemoteContentToI18n,
    }
  );

  const ctx = useMemo(() => {
    return {
      locale,
      locales,
      setLocale,
      content,
      t: createTranslationHelper(() => ({
        content,
        locale,
        showContentKeys,
      })),
      tpl: createTemplateHelper(() => ({ content, locale })),
    };
  }, [content, locale, locales, setLocale, showContentKeys]);

  return <I18nContext.Provider value={ctx}>{children}</I18nContext.Provider>;
}

export const useRefetchI18n = () => {
  const { locale } = useI18n();
  const { refetch } = useGetContentQuery(
    client,
    { locale },
    { enabled: false, notifyOnChangeProps: ['dataUpdatedAt', 'data'] }
  );
  return () => refetch({ throwOnError: true });
};
