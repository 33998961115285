import { Platform } from 'react-native';
// import { getBundleId } from 'react-native-device-info';

// const appIdentifier = getBundleId();

export function getTestID(testID: string) {
  if (!testID) {
    return '';
  }
  if (!Platform) {
    return testID;
  }

  const prefix = 'com.citypass.my:id/';
  const hasPrefix = testID.startsWith(prefix);

  switch (Platform.OS) {
    case 'android':
      return !hasPrefix ? `${prefix}${testID}` : testID;
    default:
      return hasPrefix ? testID.slice(prefix.length) : testID;
  }
}

export const tID = getTestID;
