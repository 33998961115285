import { Box } from 'native-base';
import * as React from 'react';
import { FC } from 'react';

import Atlanta from 'app/components/icons/ProductLogoSpotAtlanta';
import Boston from 'app/components/icons/ProductLogoSpotBoston';
import Chicago from 'app/components/icons/ProductLogoSpotChicago';
import ChicagoC3 from 'app/components/icons/ProductLogoSpotChicagoC3';
import Dallas from 'app/components/icons/ProductLogoSpotDallas';
import Denver from 'app/components/icons/ProductLogoSpotDenver';
import Houston from 'app/components/icons/ProductLogoSpotHouston';
import NewYork from 'app/components/icons/ProductLogoSpotNewYork';
import NewYorkC3 from 'app/components/icons/ProductLogoSpotNewYorkC3';
import NewYorkCAll from 'app/components/icons/ProductLogoSpotNewYorkCAll';
import Orlando from 'app/components/icons/ProductLogoSpotOrlando';
import Philadelphia from 'app/components/icons/ProductLogoSpotPhiladelphia';
import SanDiego from 'app/components/icons/ProductLogoSpotSanDiego';
import SanFrancisco from 'app/components/icons/ProductLogoSpotSanFrancisco';
import SanFranciscoC3 from 'app/components/icons/ProductLogoSpotSanFranciscoC3';
import Seattle from 'app/components/icons/ProductLogoSpotSeattle';
import SeattleC3 from 'app/components/icons/ProductLogoSpotSeattleC3';
import SouthernCalifornia from 'app/components/icons/ProductLogoSpotSouthernCalifornia';
import TampaBay from 'app/components/icons/ProductLogoSpotTampaBay';
import Toronto from 'app/components/icons/ProductLogoSpotToronto';

const lookup: Record<string, FC<Omit<ProductSpotLogoProps, 'id'>>> = {
  atlanta: Atlanta,
  boston: Boston,
  chicago: Chicago,
  'chicago-c3': ChicagoC3,
  dallas: Dallas,
  denver: Denver,
  houston: Houston,
  'new-york': NewYork,
  'new-york-c3': NewYorkC3,
  'new-york-c-all': NewYorkCAll,
  orlando: Orlando,
  philadelphia: Philadelphia,
  'san-diego': SanDiego,
  seattle: SanFrancisco,
  'seattle-c3': SanFranciscoC3,
  'san-francisco': Seattle,
  'san-francisco-c3': SeattleC3,
  'southern-california': SouthernCalifornia,
  tampa: TampaBay,
  toronto: Toronto,
};

export type ProductSpotLogoProps = {
  id: string;
  color: string;
  size: number;
};

export default function ProductSpotLogo({ id, color, size }: ProductSpotLogoProps) {
  const SpotLogo = lookup[id] ?? Box;
  return <SpotLogo color={color} size={size} />;
}
