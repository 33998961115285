import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function MapWithPin({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="-.5 -.5 24 24" width={size} {...props}>
      <G fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <Path d="M18.208 11.02a4.303 4.303 0 014.313 4.313c0 1.917-2.799 5.635-3.834 7.006a.556.556 0 01-.421.201.527.527 0 01-.422-.201c-1.083-1.37-3.833-5.089-3.833-7.006a4.303 4.303 0 014.197-4.312z" />
        <Path d="M16.531 15.333a1.677 1.677 0 103.354 0 1.677 1.677 0 10-3.354 0M7.188 5.165V20.5M13.896 10.063V.479M11.95 17.25l-4.485 3.153a.46.46 0 01-.555 0L.68 15.956a.489.489 0 01-.2-.393V.958A.46.46 0 01.737.537a.47.47 0 01.498 0l5.951 4.628 6.43-4.6a.489.489 0 01.557 0l6.229 4.457a.47.47 0 01.201.383v4.178" />
      </G>
    </Svg>
  );
});
