import { extendTheme } from 'native-base';

import breakpoints from 'app/theme/breakpoints';
import colors from 'app/theme/colors';
import components from 'app/theme/components';
import { fontConfig, fonts, fontSizes } from 'app/theme/fonts';

// https://docs.nativebase.io/next/customizing-theme
const theme = extendTheme({
  config: {
    initialColorMode: 'light',
  },
  breakpoints,
  fonts,
  fontConfig,
  fontSizes,
  colors,
  components,
});

export default theme;

type CustomThemeType = typeof theme;

declare module 'native-base' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ICustomTheme extends CustomThemeType {}
}
