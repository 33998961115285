import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function ArrowDown({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size} {...props}>
      <Path
        d="M23.25 7.311L12.53 18.03a.749.749 0 01-1.06 0L.75 7.311"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </Svg>
  );
});
