import { Box, Image, Pressable, ScrollView } from 'native-base';
import React, { useEffect, useMemo, useReducer } from 'react';

import HTML from 'app/components/HTML';
import TextLink from 'app/components/TextLink';
import { ProductContent } from 'app/hooks/useProduct';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics from 'app/services/Analytics';
import { Events } from 'app/services/Analytics.types';
import Logger from 'app/services/Logger';
import { tID } from 'app/services/TestHelper';
import { cdn } from 'app/utils/image';

const MODULE = `[PartnerOffer]`;

type PartnerOfferState = {
  showDescription: boolean;
  hasPressed: boolean;
};

export type PartnerOfferProps = Single<NonNullable<ProductContent['partnerOffers']>> & {
  productContentKey: string;
  adPlacement?: string;
};

export default function PartnerOffer({
  productContentKey,
  adPlacement = 'unknown',
  ...offer
}: PartnerOfferProps) {
  const { t } = useI18n();
  const [{ showDescription, hasPressed }, setState] = useReducer(
    (prev: PartnerOfferState, next: Partial<PartnerOfferState>) => ({ ...prev, ...next }),
    { showDescription: false, hasPressed: false }
  );

  const height = 200;
  const imageUrl = useMemo(
    () => cdn(offer.image?.url, [['resize', { height }]]),
    [height, offer.image?.url]
  );

  const hideDescription = () => setState({ showDescription: false });
  const revealDescription = () => {
    if (!hasPressed) {
      Logger.debug(`${MODULE} partner offer click`, { campaignId: offer.campaignId });
      if (!__DEV__) {
        Analytics.trackEvent(Events.PartnerOfferImpression, {
          adCampaign: offer.campaignId,
          adPlacement,
          productKey: productContentKey,
        });
      }
    }
    setState({ showDescription: true, hasPressed: true });
  };

  // impressions are tracked on initial render only
  useEffect(() => {
    Logger.debug(`${MODULE} partner offer impression`, { campaignId: offer.campaignId });
    if (!__DEV__) {
      Analytics.trackEvent(Events.PartnerOfferImpression, {
        adCampaign: offer.campaignId,
        adPlacement,
        productKey: productContentKey,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      borderColor="brand.border"
      borderWidth="1"
      h={height}
      mr={4}
      overflow="hidden"
      rounded="md"
      style={{
        aspectRatio: (offer.image?.width ?? 1) / (offer.image?.height ?? 1),
      }}
      testID={tID('PartnerOffer')}
    >
      {showDescription ? (
        <ScrollView p="3">
          <TextLink mb="2" onPress={hideDescription}>
            {`<`} {t('gen_back')}
          </TextLink>
          <HTML html={offer.description} />
        </ScrollView>
      ) : (
        <Pressable h="full" w="full" onPress={revealDescription}>
          {imageUrl && (
            <Image
              alt={offer.title ?? 'Partner Offer'}
              h="full"
              source={{ uri: imageUrl }}
              w="full"
            />
          )}
        </Pressable>
      )}
    </Box>
  );
}
