import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function RightQuote({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 46 32.262" width={size} {...props}>
      <Path
        d="M86.3 22.937a9.882 9.882 0 100 19.764 9.271 9.271 0 002.236-.283.5.5 0 01.554.736A13.705 13.705 0 0177.3 50.2a2.5 2.5 0 100 5 19.443 19.443 0 0018.91-19.9v-2.483a9.908 9.908 0 00-9.91-9.881z"
        transform="translate(-50.219 -22.937)"
      />
      <Path
        d="M14.422 22.937a9.882 9.882 0 100 19.764 9.253 9.253 0 002.236-.283.5.5 0 01.556.736A13.705 13.705 0 015.424 50.2a2.5 2.5 0 100 5 19.445 19.445 0 0018.91-19.9v-2.483a9.908 9.908 0 00-9.912-9.881z"
        transform="translate(-2.917 -22.937)"
      />
    </Svg>
  );
});
