import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotDallas({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M44.65 64.83h16.4c11.41 0 17.47 6.24 17.47 19.44v25.5c0 13.19-6.06 19.44-17.47 19.44h-16.4V64.83Zm11.06 55.28h3.83c5.88 0 7.94-2.23 7.94-9V82.94c0-6.78-2.05-9-7.94-9h-3.83v46.18Zm35.48 9.09h-10.7l11.86-64.37h13.73l11.86 64.37h-10.7l-1.96-14.27H93.15l-1.96 14.27Zm3.57-23.89h8.92l-4.37-27.64h-.18l-4.37 27.64Zm28.17 23.89V64.83h11.06v54.74h17.39v9.63h-28.44Z"
        fill="#000"
      />
    </Svg>
  );
});
