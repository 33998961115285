import { Box } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { tID } from 'app/services/TestHelper';

export type BlurWebProps = {
  style?: any;
};

export default function BlurWeb(props: BlurWebProps) {
  const [render, setRender] = useState(false);

  useEffect(() => {
    // chrome and firefox have a backdrop-filter bug that wont show the blur
    // at all if the element started off with a transition so we tick
    // zIndex to trip browser repaint without effecting safari, which works fine
    const blurOverlayTransitionTime = 550;
    setTimeout(() => setRender(true), blurOverlayTransitionTime);
  }, []);

  if (Platform.OS !== 'web') return <Box bg="black:alpha.50" testID={tID('BlurWeb')} {...props} />;

  return (
    <Box
      _web={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: web only https://developer.mozilla.org/en-US/docs/Web/CSS/backdrop-filter
        style: {
          ...props.style,
          backdropFilter: `blur(35px) brightness(90%)`,
          zIndex: render ? 2 : 1,
        },
      }}
      testID={tID('BlurWeb')}
    />
  );
}
