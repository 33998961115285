import { NavigationState } from '@react-navigation/native';
import invert from 'lodash/invert';

export type EventProps = Record<string, string | number>;

export interface IAnalytics {
  init: () => void;
  trackEvent: (event: Events, data?: Maybe<EventProps>) => void;
  setUserId: (id: string) => void;
  setUserProperty: (name: UserProps, value: string | number) => void;
  setUserProperties: (props: Partial<Record<UserProps, string | number>>) => void;
  onScreenChange: (state: NavigationState | undefined) => void;
  trackEventFromLinkPress: (
    attributes: Record<string, string>,
    data?: Record<string, string>
  ) => void;
  isEvent(value: unknown): value is Events;
}

export type NavigationRoute = NavigationState['routes'][0];

export enum Events {
  AttractionAddAppleWallet = 'app_attraction_addAppleWallet',
  AttractionDirections = 'app_attraction_directions',
  AttractionExpandAccordion = 'app_attraction_expandAccordion',
  AttractionExpandDescription = 'app_attraction_expandDescription',
  AttractionImageGalleryControls = 'app_attraction_imageGalleryControls',
  AttractionMakeReservation = 'app_attraction_makeReservation',
  AttractionMapControls = 'app_attraction_mapControls',
  AttractionModifyTickets = 'app_attraction_modifyTickets',
  AttractionViewTickets = 'app_attraction_viewTickets',
  AuthEmailSubmitted = 'app_auth_emailSubmitted',
  AuthEmailVerificationFailed = 'app_auth_emailVerificationFailed',
  AuthEmailVerified = 'app_auth_emailVerified',
  AuthEntitlementsMulti = 'app_auth_entitlementsMulti',
  AuthEntitlementsNone = 'app_auth_entitlementsNone',
  AuthEntitlementsSingle = 'app_auth_entitlementsSingle',
  AuthExploreAppLink = 'app_auth_exploreAppLink',
  AuthManualBarcodeSuccess = 'app_auth_manualBarcodeSuccess',
  AuthManualHelp = 'app_auth_manualHelp',
  AuthManualInvalidBarcode = 'app_auth_manualInvalidBarcode',
  AuthManualLinkTickets = 'app_auth_manualLinkTickets',
  AuthManualScan = 'app_auth_manualScan',
  AuthManualStartPlanning = 'app_auth_manualStartPlanning',
  AuthResendVerifyEmail = 'app_auth_resendVerifyEmail',
  AuthStartPlanning = 'app_auth_startPlanning',
  NavBuyNow = 'app_nav_buyNow',
  NavLinktickets = 'app_nav_linkTickets',
  NavMore = 'app_nav_more',
  NavMore_TBD = 'app_nav_more[item clicked]',
  NavProductHome = 'app_nav_productHome',
  NavTickets = 'app_nav_tickets',
  NotificationExpand = 'app_notification_expand',
  OffersExpandAttraction = 'app_offers_expandAttraction',
  OffersExpandOptionTickets = 'app_offers_expandOptionTickets',
  OffersRedeemOffer = 'app_offers_redeemOffer',
  PartnerOfferClick = 'app_ad_partner_click',
  PartnerOfferImpression = 'app_ad_partner_impression',
  ProductAdBookingcom = 'app_productAd_bookingCom',
  ProductAdBroadway = 'app_productAd_broadway',
  ProductFaq = 'app_product_faq',
  ProductHelp = 'app_product_help',
  ProductOffers = 'app_product_offers',
  ProductReview = 'app_product_review',
  ProductTickets = 'app_product_tickets',
  ReservationCancel = 'app_reservation_cancel',
  ReservationCancelRebook = 'app_reservation_cancelRebook',
  ReservationModify = 'app_reservation_modify',
  ReservationError = 'app_reservation_error',
  ReservationExit = 'app_reservation_exit',
  ReservationRedirectCancel = 'app_reservation_redirectCancel',
  ReservationRedirectContinue = 'app_reservation_redirectContinue',
  ReservationRestart = 'app_reservation_restart',
  ReservationSeeHours = 'app_reservation_seeHours',
  ReservationSelectDate = 'app_reservation_selectDate',
  ReservationSelectLocation = 'app_reservation_selectLocation',
  ReservationSelectTime = 'app_reservation_selectTime',
  ReservationSelectTimeFilter = 'app_reservation_selectTimeFilter',
  ReservationStart = 'app_reservation_start',
  ReservationSubmit = 'app_reservation_submit',
  ReservationSuccess = 'app_reservation_success',
  ReservationTimeContinue = 'app_reservation_timeContinue',
  ReservationUpgradeLink = 'app_reservation_upgradeLink',
  ScreenView = 'screen_view',
  TicketsReservationInfo = 'app_tickets_reservationInfo',
  TicketsSelectAttraction = 'app_tickets_selectAttraction',
  TicketsValidityInfo = 'app_tickets_validityInfo',
  WebPromptDownloadApp = 'app_webPrompt_downloadApp',
  WelcomeChangeLanguage = 'app_welcome_changeLanguage',
  WelcomeExplore = 'app_welcome_explore',
  WelcomeLinkTickets = 'app_welcome_linkTickets',
}

export const EventsByValue = invert(Events);

export enum UserProps {
  AuthTokenExists = 'authToken',
  EntitlementsQtyCode = 'entitlementsQtyCode',
  Language = 'language',
  LinkedTickets = 'linkedTickets',
  TravelParties = 'travelParties',
  VerificationState = 'verificationState',
}
