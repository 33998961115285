import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function ChatBubbleCheck({ size, color, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 23.75 23.75" width={size} {...props}>
      <G fill="none" stroke={color} strokeLinejoin="round" strokeWidth={1.25}>
        <Path
          d="M11.708 18.317L9.754 20.3v-3.97h-.488a6.423 6.423 0 01-6.349-6.458c0-3.552 2.857-6.955 6.349-6.955h7.814c3.492 0 6.349 3.4 6.349 6.955a6.5 6.5 0 01-.488 2.484"
          transform="translate(-2.292 -2.292)"
        />
        <Path
          d="M13.493 19.455a5.962 5.962 0 105.962-5.962 5.962 5.962 0 00-5.962 5.962z"
          transform="translate(-2.292 -2.292)"
        />
        <Path
          d="M22.112 17.784l-2.889 3.853a.746.746 0 01-1.124.079l-1.49-1.49"
          transform="translate(-2.292 -2.292)"
        />
      </G>
    </Svg>
  );
});
