import { Box, Flex, Heading, Pressable, VStack } from 'native-base';
import React, { memo } from 'react';
import { ImageBackground, useWindowDimensions } from 'react-native';

import { ProductLogoSpot } from 'app/components/icons';
import RemoteSVG from 'app/components/RemoteSVG';
import { GetDestinationsQuery } from 'app/generated/hygraph';
import productThumbs from 'app/screens/DestinationsScreen/productThumbs';
import { tID } from 'app/services/TestHelper';
import { cdn } from 'app/utils/image';

const LOGO_SIZE = 40;
const MAX_THUMB_SIZE = 400;
const label = ({
  cityName,
  name,
  type,
}: Pick<DestinationCardProps['product'], 'name' | 'type' | 'cityName'>) => name;

export type DestinationCardProps = {
  product: NonNullable<GetDestinationsQuery['products'][0]>;
  onPress: (key: string) => void;
  spotSize?: number;
  showLabel?: boolean;
};

export default memo(function DestinationCard({
  product,
  onPress,
  spotSize,
  showLabel = true,
}: DestinationCardProps) {
  const { width } = useWindowDimensions();
  const { key, thumb, logoSpot, name, cityName, type } = product;

  if (!key) return null;

  return (
    <Pressable
      bgColor="gray.300"
      flex={1}
      overflow="hidden"
      rounded="lg"
      testID={tID(`destination-${product.key}`)}
      onPress={() => onPress(key)}
    >
      <ImageBackground
        defaultSource={productThumbs[key] ?? undefined}
        source={{
          uri: `${cdn(thumb?.url ?? '', [
            ['resize', { width: Math.max((Math.floor(width), MAX_THUMB_SIZE)) }],
            ['quality', { value: 80 }],
          ])}`,
        }}
        style={{ width: '100%', height: '100%' }}
      >
        <Box bgColor="black:alpha.40" bottom="0" h="50%" position="absolute" w="full" />

        <Box rounded="lg" style={{ aspectRatio: 1 }} testID={tID('DestAspectRatio')} w="full">
          <VStack h="full" justifyContent="center">
            <Flex alignItems="center" h={`${spotSize ?? LOGO_SIZE}px`} position="absolute" w="full">
              <RemoteSVG
                fallback={<ProductLogoSpot color="black" id={key} size={spotSize ?? LOGO_SIZE} />}
                style={{ flex: 1 }}
                uri={key === 'chicago-c3' ? '' : logoSpot?.url ?? ''}
              />
            </Flex>

            {showLabel && (
              <Heading
                color="white"
                fontSize="md"
                lineHeight="xs"
                mt="24"
                px="1.5"
                py="1"
                style={{
                  textShadowRadius: 5,
                  textShadowColor: '#000',
                }}
                textAlign="center"
              >
                {label({ cityName, name, type })}
              </Heading>
            )}
          </VStack>
        </Box>
      </ImageBackground>
    </Pressable>
  );
});
