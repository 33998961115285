/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
import { getStateFromPath, LinkingOptions } from '@react-navigation/native';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

import useStore from 'app/hooks/useStore';
import { RootStackParamList } from 'app/navigation/RootNavigator';
import Logger from 'app/services/Logger';

export const appURL = (path: string): string =>
  (__DEV__ || Platform.OS !== 'web' ? '' : '') + path;

const webPrefixes =
  Constants.expoConfig?.ios?.associatedDomains
    ?.filter((domain) => /^applinks:[a-z0-9.]+/.test(domain))
    .map((domain) => domain.replace(/^applinks:/, 'https://')) ?? [];

Logger.debug('PREFIXES', { webPrefixes });

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/'), ...webPrefixes],
  config: {
    screens: {
      AppInfo: appURL('/info'),
      Authentication: {
        screens: {
          AuthenticationStepEnterEmail: appURL('/welcome'),
          AuthenticationStepVerifyEmail: appURL('/auth/verify'),
          AuthenticationStepTicketLoader: appURL('/auth/loading'),
        },
      },
      Chat: appURL('/chat'),
      Destinations: appURL('/destinations'),
      Feedback: appURL('/feedback'),
      Language: appURL('/language'),
      NotFound: appURL('*'),
      Notifications: appURL('/notifications'),
      Tabs: {
        screens: {
          ProductStack: {
            screens: {
              // update routeParamUrlPatterns below if these change
              Product: appURL('/product/:productId'),
              ProductFAQ: appURL('/product/:productId/faq'),
              ProductOffers: appURL('/product/:productId/offers'),
            },
          },
          AttractionTicketsStack: {
            screens: {
              // update routeParamUrlPatterns below if these change
              AttractionTickets: appURL('/product/:productId/attractions'),
              Attraction: appURL('/product/:productId/attraction/:attractionId'),
            },
          },
          Menu: appURL('/menu'),
        },
      },
      Purchases: appURL('/purchases'),
      Profile: appURL('/profile'),
      ReservationFlow: appURL('/make-reservation'),
      TermsConditions: appURL('/terms-conditions'),
      LinkPasses: appURL('/auth/link'),
    },
  },
  getStateFromPath(path, config) {
    if (isGuideRoute(path)) {
      return getStateFromPath(`/welcome?${getQueryParams(path)}`, config);
    }
    if (isProductRoute(path)) {
      syncProductRoute(path);
    }
    return getStateFromPath(path, config);
  },
};

function isGuideRoute(path: string) {
  return path.startsWith('/guide/');
}

function isProductRoute(path: string) {
  return path.startsWith('/product/');
}

function getQueryParams(path: string = '') {
  return path.split('?')[1] ?? '';
}

function syncProductRoute(path: string) {
  const productIdMatch = path.match(/^\/product\/(.+?)($|\/)/);
  const routeProductId = productIdMatch && productIdMatch[1];

  if (routeProductId) {
    const storeProductId = useStore.getState().productId;
    if (routeProductId !== storeProductId) {
      useStore.setState({ productId: routeProductId });
    }
  }
}

export type RouteParam = 'productId' | 'attractionId';

export const routeParamUrlPatterns: Record<RouteParam, RegExp> = {
  productId: /\/product\/([\w-]+)/,
  attractionId: /product\/[\w-]+\/attraction\/([\w-]+)/,
};

export default linking;
