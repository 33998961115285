import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LocationTarget({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 24 24" width={size} {...props}>
      <Circle
        cx={12}
        cy={12}
        fill="none"
        r={7.5}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
      />
      <Path
        d="M12 0.75L12 4.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
      />
      <Path
        d="M0.75 12L4.5 12"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
      />
      <Path
        d="M12 23.25L12 19.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
      />
      <Path
        d="M23.25 12L19.5 12"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
      />
    </Svg>
  );
});
