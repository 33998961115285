import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { useToken } from 'native-base';

export default function useHeaderScreenOptions(): NativeStackNavigationOptions {
  return {
    headerBackTitle: '',
    headerBackTitleVisible: false,
    headerTintColor: useToken('colors', 'brand.secondary'),
    headerTitleStyle: { fontFamily: 'ProximaNova-Bold' },
  };
}
