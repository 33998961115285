/* eslint-disable no-underscore-dangle */
import { Box, Button, Heading, IBoxProps, Pressable, Text, useDisclose, VStack } from 'native-base';
import React, { ReactNode } from 'react';
import { Linking, Platform } from 'react-native';

import { CMSBlock, createBlock, getImageRatio } from 'app/components/ContentBlock/helpers';
import Hero from 'app/components/Hero';
import RedirectDialog from 'app/components/RedirectDialog';
import { DEFAULT_PADDING } from 'app/components/ResponsiveContainer';
import { ProductContent } from 'app/hooks/useProduct';
import useWindow from 'app/hooks/useWindow';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics, { Events } from 'app/services/Analytics';
import { tID } from 'app/services/TestHelper';
import { cdn } from 'app/utils/image';

export type ContentBlockProps = CMSBlock & {
  space: number;
  boxProps?: IBoxProps;
  children?: ReactNode;
  productContent?: ProductContent;
  testID?: string;
  onCTAPress?: () => void;
  precondition?: () => boolean;
};

/**
 * Renders a Block model from CMS.
 */
export default function ContentBlock({
  children,
  productContent,
  precondition,
  onCTAPress,
  space = 10,
  testID = tID('ContentBlock'),
  boxProps,
  ...block
}: ContentBlockProps) {
  const { locale } = useI18n();
  const { width } = useWindow();
  const { title, body, cta, url, image, ui, theme, hasContent, isEmpty } = createBlock(block, {
    productContent,
    locale,
  });
  const redirectDialog = useDisclose();

  const openURL = () => Linking.openURL(url);
  const handleCTAPress = () => {
    if (ui.analyticsEvent) {
      Analytics.trackEvent(ui.analyticsEvent as Events, { productKey: productContent?.key ?? '' });
    }
    if (onCTAPress) {
      onCTAPress();
      return;
    }
    if (!url) return;
    if (isExternalLink(url)) {
      redirectDialog.onOpen();
      return;
    }
    openURL();
  };

  if (isEmpty || (precondition && !precondition())) {
    return <Box mt={-space} testID={testID} />;
  }

  const content = hasContent ? (
    <VStack
      pb={image ? DEFAULT_PADDING : undefined}
      pt={image ? 24 : undefined}
      px={image ? DEFAULT_PADDING : 0}
      space="4"
      {...ui.props?._content}
    >
      {!!title && (
        <Heading fontSize="2xl" {...theme?.title} textAlign={ui.align} {...ui.props?._title}>
          {title}
        </Heading>
      )}
      {!!body && (
        <Text fontSize="md" {...theme?.body} textAlign={ui.align} {...ui.props?._body}>
          {body}
        </Text>
      )}
      {!!cta && (
        <Button variant="outline" onPress={handleCTAPress} {...ui.props?._button}>
          {cta}
        </Button>
      )}
      {children}
    </VStack>
  ) : (
    <Pressable h="full" w="full" onPress={handleCTAPress} />
  );

  return (
    <Box {...boxProps} mx={ui.fullWidth ? -DEFAULT_PADDING : 0} testID={testID}>
      {image ? (
        <Hero
          _gradient={{
            colors: ['rgba(0,0,0,.15)', 'rgba(0,0,0,.8)'],
            locations: [0, 1],
          }}
          aspectRatio={getImageRatio(block)}
          dynamicHeight={hasContent}
          hideGradient={!hasContent}
          url={cdn(image, [['resize', { width: width * 2 }]])}
          {...ui.props?._image}
        >
          {content}
        </Hero>
      ) : (
        content
      )}
      {/* <Dump data={{ rest, imageRatio: getImageRatio(props) }} /> */}

      {isExternalLink(url) && (
        <RedirectDialog
          isOpen={redirectDialog.isOpen}
          url={url}
          onClose={redirectDialog.onClose}
          onConfirm={openURL}
        />
      )}
    </Box>
  );
}

function isExternalLink(url: string) {
  if (Platform.OS === 'web') return !url.startsWith(window.location.origin);
  return url.startsWith('http');
}
