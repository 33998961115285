import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function PhoneHours({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 23.862 23.865" width={size} {...props}>
      <G fill="none" stroke={color} strokeLinejoin="round" strokeWidth={1.25}>
        <Path
          d="M6.874 9.24a1.967 1.967 0 000-2.782L4.787 4.373a1.967 1.967 0 00-2.783 0L.86 5.516a2.951 2.951 0 00-.372 3.71 45.776 45.776 0 0012.788 12.788 2.951 2.951 0 003.712-.373l1.145-1.144a1.967 1.967 0 000-2.783l-2.087-2.084a1.967 1.967 0 00-2.783 0l-.7.7a46.7 46.7 0 01-6.394-6.394zM10.696 5.902a5.9 5.9 0 105.9-5.9 5.9 5.9 0 00-5.9 5.9z"
          transform="translate(.737 .625)"
        />
        <Path d="M18.588 5.902h-1.967V2.951" transform="translate(.737 .625)" />
      </G>
    </Svg>
  );
});
