import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function HoursInfo({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 19.042 19.399" width={size} {...props}>
      <G stroke={color} strokeWidth={1.25} transform="translate(.625 .625)">
        <Path
          d="M0 0L17.395 0"
          fill="none"
          strokeLinejoin="round"
          transform="translate(.044 4.841)"
        />
        <Path
          d="M0 0L0 2.861"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(12.867)"
        />
        <Path
          d="M0 0L0 2.861"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(4.792)"
        />
        <G strokeLinecap="round" strokeLinejoin="round">
          <Path
            d="M0 5.116A5.107 5.107 0 105.107-.002 5.112 5.112 0 000 5.116z"
            fill="none"
            transform="translate(7.577 7.918)"
          />
          <Path d="M5.057 7.759v-2.8" fill="rgba(0,0,0,0)" transform="translate(7.577 7.918)" />
          <Path
            d="M4.991 2.557a.116.116 0 10.116.116.116.116 0 00-.116-.116"
            fill="rgba(0,0,0,0)"
            transform="translate(7.577 7.918)"
          />
        </G>
        <Path
          d="M17.226 6.279V3.074s-.1-1.383-1.307-1.383H.976s-.975-.07-.975 1.383v13.21a1.222 1.222 0 001.235 1.242h4.74"
          fill="none"
          strokeLinecap="round"
        />
      </G>
    </Svg>
  );
});
