import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function CityPassDotCom({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="-1.5 -1.25 20.75 19.25" width={size} {...props}>
      <G fill="none" stroke="#373737" strokeWidth={1}>
        <Path
          d="M14.609 0H1.391A1.548 1.548 0 000 1.667v11.666A1.548 1.548 0 001.391 15h13.218A1.548 1.548 0 0016 13.333V1.667A1.548 1.548 0 0014.609 0z"
          data-name="Path 155711"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(.5 .5)"
        />
        <Path
          d="M0 4.167h16"
          data-name="Path 155712"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(.5 .5)"
        />
        <Path
          d="M6.261 9.125a1.537 1.537 0 00-.984.342 1.081 1.081 0 00-.408.825v.583a1.081 1.081 0 00.408.825 1.537 1.537 0 00.984.342"
          data-name="Path 155713"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(.5 .5)"
        />
        <Path
          d="M8.352 9.125a.769.769 0 00-.492.171.54.54 0 00-.2.413v1.75a.54.54 0 00.2.413.793.793 0 00.984 0 .54.54 0 00.2-.413v-1.75a.54.54 0 00-.2-.413.769.769 0 00-.492-.171z"
          data-name="Path 155714"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(.5 .5)"
        />
        <Path
          d="M12.522 12.042V9.125l-1.043 1.459-1.044-1.459v2.917"
          data-name="Path 155715"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(.5 .5)"
        />
        <Path
          d="M2.435 2.292a.212.212 0 010-.417"
          data-name="Path 155716"
          transform="translate(.5 .5)"
        />
        <Path
          d="M2.435 2.292a.212.212 0 000-.417"
          data-name="Path 155717"
          transform="translate(.5 .5)"
        />
        <Path
          d="M3.13 11.459a.212.212 0 010-.417"
          data-name="Path 155718"
          transform="translate(.5 .5)"
        />
        <Path
          d="M3.13 11.459a.212.212 0 000-.417"
          data-name="Path 155719"
          transform="translate(.5 .5)"
        />
        <G data-name="Group 7487">
          <Path
            d="M.174.417a.212.212 0 010-.417"
            data-name="Path 155720"
            transform="translate(.5 .5) translate(4.348 1.875)"
          />
          <Path
            d="M.174.417a.212.212 0 000-.417"
            data-name="Path 155721"
            transform="translate(.5 .5) translate(4.348 1.875)"
          />
        </G>
        <G data-name="Group 7488">
          <Path
            d="M.174.417a.212.212 0 010-.417"
            data-name="Path 155722"
            transform="translate(.5 .5) translate(6.435 1.875)"
          />
          <Path
            d="M.174.417a.212.212 0 000-.417"
            data-name="Path 155723"
            transform="translate(.5 .5) translate(6.435 1.875)"
          />
        </G>
      </G>
    </Svg>
  );
});
