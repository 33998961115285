import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotSeattle({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M64.2 80.7c-.45-5.53-3.39-7.13-5.88-7.13-3.57 0-5.53 2.32-5.53 6.33 0 10.97 22.47 16.23 22.47 33.7 0 10.61-7.13 16.49-17.47 16.49s-16.14-8.2-16.58-17.83l10.52-1.52c.45 6.51 3.03 9.72 6.6 9.72 3.83 0 6.42-2.05 6.42-5.88 0-12.75-22.47-16.14-22.47-34.59 0-10.25 6.24-16.05 17.12-16.05 9 0 14.35 6.51 15.33 15.6L64.21 80.7Zm15.87 48.5V64.83h29.6v9.63H91.13v16.58h14.27v9.63H91.13v18.9h19.79v9.63H80.07Zm46.01 0h-10.7l11.86-64.37h13.73l11.86 64.37h-10.7l-1.96-14.26h-12.13l-1.96 14.26Zm3.57-23.89h8.92l-4.37-27.64h-.18l-4.37 27.64Z"
        fill="#000"
      />
    </Svg>
  );
});
