import { useQuery, UseQueryOptions } from 'react-query';

import { getConfiguration } from 'app/services/GuestCenterService';
import { ConfigurationResponse } from 'app/services/GuestCenterService.types';
import { QueryKeys } from 'app/services/QueryClient';

export const queryKey = QueryKeys.Configuration;
export const fetcher = getConfiguration;

type UseConfigurationQueryOptions<T> = UseQueryOptions<ConfigurationResponse, unknown, T>;

export default function useConfiguration<T = ConfigurationResponse>(
  opts: {
    select?: UseConfigurationQueryOptions<T>['select'];
    notifyOnChangeProps?: UseConfigurationQueryOptions<T>['notifyOnChangeProps'];
    staleTime?: UseConfigurationQueryOptions<T>['staleTime'];
    refetchOnMount?: boolean | 'always';
    refetchOnReconnect?: boolean | 'always';
  } = {}
) {
  return useQuery([queryKey], fetcher, opts);
}
