import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LiveChat({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 20.75 19.25" width={size} {...props}>
      <G fill="none" stroke="#373737" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1}>
        <Path
          d="M9.739 0H2.783A2.783 2.783 0 000 2.783v3.478a2.783 2.783 0 002.783 2.782h.7v2.783l3.13-2.783h3.13a2.783 2.783 0 002.783-2.783V2.783A2.783 2.783 0 009.739 0z"
          data-name="Path 155724"
          transform="translate(.5 .5)"
        />
        <Path
          d="M7.652 11.131v1.043a1.739 1.739 0 001.739 1.739h1.739L13.217 16v-2.087h1.043a1.739 1.739 0 001.739-1.739v-2.087a1.739 1.739 0 00-1.738-1.739h-.348"
          data-name="Path 155725"
          transform="translate(.5 .5)"
        />
      </G>
    </Svg>
  );
});
