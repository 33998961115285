export function isVersionOutdated(version: Maybe<string>, targetVersion: Maybe<string>) {
  if (!version || !targetVersion) return false;

  const current = version.split('.').map(Number);
  const required = targetVersion.split('.').map(Number);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.max(current.length, required.length); i++) {
    const curr = current[i] ?? 0;
    const req = required[i] ?? 0;

    if (curr < req) return true;
    if (curr > req) return false;
  }

  return false;
}
