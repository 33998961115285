import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function TicketExchange({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size} {...props} fill={color}>
      <Path d="M1.5 21.5C.673 21.5 0 20.827 0 20v-2.069a.988.988 0 01.748-.962 1 1 0 00-.001-1.939.987.987 0 01-.747-.963V12c0-.827.673-1.5 1.5-1.5h14c.827 0 1.5.673 1.5 1.5v2.069a.988.988 0 01-.748.962 1.001 1.001 0 00.001 1.939.987.987 0 01.747.963V20c0 .827-.673 1.5-1.5 1.5h-14zm0-10a.5.5 0 00-.5.5v2.069a1.998 1.998 0 01-.007 3.87L1 20a.5.5 0 00.5.5h14a.5.5 0 00.5-.5v-2.069a1.998 1.998 0 01.006-3.869L16 12a.5.5 0 00-.5-.5h-14z" />
      <Path d="M18.5 13.5a.5.5 0 010-1h4a.5.5 0 00.5-.5V9.931a1.992 1.992 0 01-1.44-2.422 1.998 1.998 0 011.447-1.447L23 4a.5.5 0 00-.5-.5h-14A.5.5 0 008 4v2.069c.511.124.946.45 1.219.909a1.99 1.99 0 01-.228 2.355.502.502 0 01-.706.04.5.5 0 01-.04-.706.995.995 0 00-.498-1.636A.988.988 0 017 6.067V4c0-.827.673-1.5 1.5-1.5h14c.827 0 1.5.673 1.5 1.5v2.069a.987.987 0 01-.748.962.997.997 0 00-.613 1.48c.137.23.354.392.613.458.443.112.75.508.748.964V12c0 .827-.673 1.5-1.5 1.5h-4zM.5 8a.5.5 0 01-.5-.5 4.505 4.505 0 014.297-4.495L3.146 1.854a.5.5 0 01.708-.708l2 2a.508.508 0 01-.001.708l-2 2a.5.5 0 11-.707-.707l1.14-1.14A3.504 3.504 0 001 7.5a.5.5 0 01-.5.5zM20.5 23a.5.5 0 01-.354-.146l-2-2a.496.496 0 01-.109-.545.477.477 0 01.109-.163l1.999-1.999a.5.5 0 11.709.707l-1.14 1.14A3.505 3.505 0 0023 16.5a.5.5 0 011 0 4.506 4.506 0 01-4.297 4.496l1.151 1.151A.5.5 0 0120.5 23z" />
      <Circle cx={4.75} cy={13.25} r={0.75} />
      <Circle cx={4.75} cy={15.75} r={0.75} />
      <Circle cx={4.75} cy={18.25} r={0.75} />
      <Circle cx={11.75} cy={5.25} r={0.75} />
      <Circle cx={11.75} cy={7.75} r={0.75} />
    </Svg>
  );
});
