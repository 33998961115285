import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function ExclamationCircle({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 24 24" width={size} {...props}>
      <Path d="M10.286 2.229q2.799 0 5.163 1.379t3.743 3.743 1.379 5.163-1.379 5.163-3.743 3.743-5.163 1.379-5.163-1.379-3.743-3.743-1.379-5.163T1.38 7.351t3.743-3.743 5.163-1.379zM12 18.929v-2.545q0-.188-.121-.315t-.295-.127H9.013q-.174 0-.308.134t-.134.308v2.545q0 .174.134.308t.308.134h2.571q.174 0 .295-.127t.121-.315zm-.027-4.607l.241-8.317q0-.161-.134-.241-.134-.107-.321-.107H8.813q-.188 0-.321.107-.134.08-.134.241l.228 8.317q0 .134.134.234t.321.1h2.478q.188 0 .315-.1t.141-.234z" />
    </Svg>
  );
});
