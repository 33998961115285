import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Guest({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 8.207 17.25" width={size} {...props}>
      <G fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25}>
        <Path
          d="M4.87 16l.348-4.174h1.739V9.391a3.478 3.478 0 10-6.957 0v2.435h1.74L2.088 16z"
          transform="translate(.625 .625)"
        />
        <Path
          d="M1.043 2.434A2.435 2.435 0 103.478-.001a2.435 2.435 0 00-2.435 2.435z"
          transform="translate(.625 .625)"
        />
      </G>
    </Svg>
  );
});
