import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotOrlando({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle fill="#b8b8" r="97.01" x="97.01" y="97.01" />
      <Path
        d="M39.14 80.23c0-9.2 5.41-16.69 17.14-16.69s17.14 7.49 17.14 16.69v33.56c0 9.2-5.41 16.69-17.14 16.69s-17.14-7.49-17.14-16.69V80.23Zm11.19 33.29c0 4.87 1.44 7.22 5.95 7.22s5.95-2.35 5.95-7.22V80.5c0-4.87-1.44-7.22-5.95-7.22s-5.95 2.35-5.95 7.22v33.02Zm43.75 16.06H82.89V64.45h15.7c12.54 0 18.94 5.32 18.94 19.21 0 10.46-4.06 14.7-7.85 16.42l9.47 29.5h-11.37l-7.94-26.97c-1.44.18-3.79.27-5.77.27v26.7Zm0-35.9h3.97c6.04 0 8.3-2.25 8.3-10.01s-2.25-10.01-8.3-10.01h-3.97v20.03Zm32.03 35.9V64.45h11.19v55.39h17.59v9.74h-28.78Z"
        fill="red"
      />
    </Svg>
  );
});
