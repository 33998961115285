import { useGetContentQuery } from 'app/generated/hygraph';
import { useI18n } from 'app/providers/I18nProvider';
import HygraphService from 'app/services/HygraphService';

const { client } = HygraphService();

type UseGetContentQueryOptions = Parameters<typeof useGetContentQuery>[2];

export default function useContent(options: UseGetContentQueryOptions) {
  const { locale } = useI18n();
  return useGetContentQuery(client, { locale }, options);
}
