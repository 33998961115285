import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Paste({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 20 20" width={size} {...props}>
      <Path d="M10.5 20H2a2 2 0 01-2-2V6c0-1.1.9-2 2-2h1V3l2.03-.4a3 3 0 015.94 0L13 3v1h1a2 2 0 012 2v1h-2V6h-1v1H3V6H2v12h5v2h3.5zM8 4a1 1 0 100-2 1 1 0 000 2zm2 4h8a2 2 0 012 2v8a2 2 0 01-2 2h-8a2 2 0 01-2-2v-8c0-1.1.9-2 2-2zm0 2v8h8v-8h-8z" />
    </Svg>
  );
});
