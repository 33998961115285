import { Pass } from 'app/services/GuestCenterService.types';

export function getAttractionTickets(passes: Maybe<Pass[]>) {
  const attractionKeyMap =
    passes?.reduce<{ [attractionKey: string]: boolean }>((acc, pass) => {
      pass.tickets.forEach((ticket) => {
        acc[ticket.attractionKey] = true;
        return acc;
      });
      return acc;
    }, {}) ?? {};

  return Object.keys(attractionKeyMap);
}
