import { Locale } from 'app/generated/hygraph';

export function webHome({ locale }: { locale: Locale }) {
  const subdomain = getSubdomainForLocale(locale);
  return `https://${subdomain}.citypass.com`;
}

// per Customer Care this should be english only for now
// leaving the locale in the signature for future use
export function webSupport(_props: { locale: Locale }) {
  return 'https://support.citypass.com/hc/en-us';
}

export function webReturnPolicy({ locale }: { locale: Locale }) {
  const baseUrl = webHome({ locale });
  return `${baseUrl}/return-policy`;
}

export function webReviews({ locale }: { locale: Locale }) {
  return `${webHome({ locale })}/reviews`;
}

export function webProduct({ locale, productSlug }: { locale: Locale; productSlug: string }) {
  return `${webHome({ locale })}/${productSlug}`;
}

export function broadway() {
  return 'https://broadway.citypass.com/new-york';
}

export function htmlLink({ url, title, label }: { url: string; label: string; title?: string }) {
  return `<a href="${url}" title="${title ?? label}">${label ?? url}</a>`;
}

export function getSubdomainForLocale(locale: Locale) {
  return locale === 'en' ? 'www' : locale;
}
