import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

const LOGO_RATIO = 0.202279;

export default withIconWrapper(function MyCitypassLogo({ color, size = 50, ...props }) {
  return (
    <Svg fill={color} height={size * LOGO_RATIO} viewBox="0 0 581.46 117.6" width={size} {...props}>
      <Path
        d="M459.66 94.9c-15.7 0-27.6-9-32.4-24.6l15-3c3.7 10 11.9 12.4 17.8 12.4 6.4 0 13.1-4.5 13.1-9.9 0-8.2-9-10.9-18.6-15.7-11.2-5.5-23.8-10.6-23.8-26.9 0-7.6 2.4-13.6 7.2-17.8 5-4.4 12.3-7.7 21.7-7.7 15 0 25.8 9.3 30.6 20.9l-14.9 4.1c-3.7-6.7-9.8-9.8-16.2-9.8-6.9 0-12.7 3.6-12.7 9.5 0 9 8.8 11.1 17.4 15.2 11.7 5.7 26.4 10.8 26.4 28.1.2 13-14.4 25.2-30.6 25.2zm70.4 0c-15.7 0-27.6-9-32.4-24.6l15-3c3.7 10 11.9 12.4 17.8 12.4 6.4 0 13.1-4.5 13.1-9.9 0-8.2-9-10.9-18.5-15.7-11.2-5.5-23.8-10.6-23.8-26.9 0-7.6 2.4-13.6 7.2-17.8 5-4.4 12.3-7.7 21.7-7.7 15 0 25.8 9.3 30.6 20.9l-14.9 4.1c-3.7-6.7-9.8-9.8-16.2-9.8-6.9 0-12.7 3.6-12.7 9.5 0 9 8.8 11.1 17.4 15.2 11.7 5.7 26.4 10.8 26.4 28.1 0 13-14.5 25.2-30.7 25.2z"
        fill="#e51937"
      />
      <Path
        d="M196.26 28.3V6.7h11.6c2.2 0 4 1.8 4 4v17.6h9.9c1.8 0 3.3 1.1 3.8 2.8l3 11.4h-16.7v35.2c0 3.5 1.8 4.5 5.7 4.5 2.4 0 4.9-.8 7.2-1.3v12.8c-4.3.5-8 1-12.3 1-11.6 0-16.1-5.8-16.1-15.1V42.5h-9.1V28.3h9zm36 75.9c2.4.5 4.3.5 6.5.5 5.4 0 8.4-1.5 10.3-5.1 1.5-3.1 2.5-5 2.5-7.7 0-2.4-1.3-5.1-2-7.8l-17.5-55.8h10.6c3 0 5.7 2 6.4 4.9l9.5 35.3h.2l11.3-40.2h15.6l-20.6 65.5c-6.9 18.7-12.4 23.8-25.2 23.8-3.8 0-5.6-.2-7.5-.5l-.1-12.9z"
        fill="#000001"
      />
      <Path
        d="M379.46 2.8h14.7c3.5 0 6.6 2.4 7.6 5.8l24.1 85.1h-16.8l-5-19.6h-28.6l-5.4 19.6h-16.8l26.2-90.9zm20.7 56l-10-38.5h-.2l-10.5 38.5h20.7zm-231-41c-4.9 0-8.9-4-8.9-8.9s4-8.9 8.9-8.9 8.9 4.1 8.9 9-4 8.8-8.9 8.8zm164.3-15h-36.8v90.8h15.9V56.9h20.8c14.9 0 27-12.1 27-27.1.1-14.9-12-27-26.9-27zm-1 39h-19.8V18h19.8c7.1 0 12.9 4.8 12.9 12s-5.8 11.8-12.9 11.8z"
        fill="#e51937"
      />
      <Path
        d="M123.36 79.6c-8.8 0-16.3-6.5-17.7-14.5-1.1-6.4-1-12-1-16.8s-.2-10.5 1-16.8c1.2-7.4 9-14.5 17.7-14.5 7.1 0 13.4 4.1 16.3 10.3l14.8-4.2c-4.8-12.5-16.9-21.4-31.1-21.4-17.2 0-31.8 13-33.6 29.7 0 0-.7 6.5-.7 17 0 10.6.7 16.7.7 16.7 1.8 16.7 16.4 29.7 33.6 29.7 14.4 0 26.6-9.1 31.3-21.9l-14.8-3.8c-2.9 6.2-9.2 10.5-16.5 10.5zm38 14V28.3h11.6c2.2 0 4 1.8 4 4v61.3h-15.6z"
        fill="#000001"
      />
      <Path
        d="M572.56 19.4c-5 0-8.8-4-8.8-8.8s3.9-8.9 8.9-8.9 8.8 4 8.8 8.8-4 8.9-8.9 8.9zm0-16.7c-4.5 0-7.9 3.5-7.9 7.9s3.4 7.9 7.8 7.9 7.9-3.6 7.9-7.9-3.3-7.9-7.8-7.9zm2.2 8.9l2.3 3.4h-2.4l-2-3h-1.6v3h-2.1V5.6h4.2c2.2 0 3.7 1.1 3.7 3.1-.1 1.5-.9 2.5-2.1 2.9zm-1.7-4.2h-2v2.8h2.1c1 0 1.6-.6 1.6-1.4-.1-.9-.7-1.4-1.7-1.4z"
        fill="#e51937"
      />
      <G fill="#000001">
        <Path d="M16.57 4.31c1.41.97 1.94 2.73 1.94 4.96 0 2.96-1.57 7.69-4.21 14.69C20.13 15.4 26.99 7.4 32.9 7.4c2.49 0 4.67 1.4 4.67 5.37 0 3.42-2.18 9.65-3.5 13.07 6.61-8.71 14.16-14.47 17.58-14.47 2.64 0 4.67 1.09 4.67 5.13 0 2.8-2.8 8.56-5.68 14.23-3.89 7.86-8.09 16.65-8.09 23.41 0 3.19 2.41 3.27 2.41 5.21 0 1.48-.62 2.64-2.26 2.64-1.87 0-4.12-2.26-4.12-8.01 0-7.86 4.43-17.03 8.24-24.66 2.41-4.82 5.06-10.42 5.06-12.45 0-.78-.39-1.4-1.09-1.4-1.79 0-10.11 6.53-16.57 15.95-4.2 6.22-5.29 8.4-7.23 13.22-.78 2.1-1.24 3.19-2.57 3.19-1.09 0-2.18-.62-2.18-2.72 0-.47.31-1.79.7-2.96 2.18-5.44 4.98-11.12 7.23-17.11 1.71-4.59 3.11-8.87 3.11-11.51 0-1.48-.62-2.1-1.32-2.1-4.98 0-18.59 19.06-22.56 25.9-.93 1.56-3.97 8.4-4.9 10.66-.7 1.94-1.24 3.11-2.49 3.11s-2.02-1.01-2.02-2.33c0-.86.39-1.87 1.17-3.42 1.4-2.8 3.97-9.49 7.16-17.42 3.19-8.17 5.83-15.79 5.83-18.51 0-1.56-.78-2.18-2.02-2.18-2.72 0-6.38 1.01-9.18 3.42-1.79 1.56-4.59-2.1-1.32-4.04 2.33-1.34 4.56-2.41 6.73-3.1 1.74-.56 5.49-1.09 8.21.79zM67.44 64.18c-2.57 13.77-10.81 33.14-21.39 32.51-3.19-.23-5.83-2.96-5.6-7.16.23-4.28 3.19-12.21 9.49-19.06 5.21-5.76 12.21-9.1 13.85-9.72 1.09-7.16 2.72-18.05 2.8-19.45-2.33 3.42-9.8 13.3-14.39 13.07-1.63-.08-3.11-1.63-2.96-4.36s3.27-10.81 4.59-13.53c1.4-3.11 2.72-4.74 4.28-4.67 1.4.08 2.1 1.09 2.02 2.1-.08.86-.78 1.87-1.94 3.58-1.87 2.8-4.59 10.35-4.67 11.2 0 .54 0 .93.47 1.01 2.02.16 8.4-8.4 10.19-11.05 1.4-1.94 2.8-4.28 3.27-4.67.78-.7 2.72-1.63 3.5-1.56s1.71.31 1.63 1.79c0 .54-.16 1.17-.47 1.79a22.97 22.97 0 00-1.56 6.14c-1.56 12.13-2.1 15.48-2.41 18.36.39-.08 1.01-.16 1.4-.08 4.04.23 8.17 2.57 7.86 7.08-.08 1.79-1.09 3.73-2.88 3.58-1.48-.08-2.02-1.09-1.94-2.41.08-.78.31-1.32.39-2.41.08-.86-1.48-2.02-3.73-2.1-.31-.16-1.24-.16-1.79 0zM51.81 74.6c-4.98 5.37-6.88 11.58-7.11 15.01-.08 1.63 1.13 2.78 2.38 2.86 7.09.36 14.53-18.38 16.17-27.2-1.63.78-6.77 4.12-11.43 9.33z" />
      </G>
    </Svg>
  );
});
