import { ChevronDownIcon, HStack, Pressable, Text, useToken } from 'native-base';
import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Globe from 'app/components/icons/Globe';
import useRootNavigation from 'app/hooks/useRootNavigation';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics, { Events } from 'app/services/Analytics';
import { tID } from 'app/services/TestHelper';

export type LanguageSelectProps = {
  disabled?: boolean;
};

export default function LanguageSelect({ disabled }: LanguageSelectProps) {
  const { locale, locales } = useI18n();
  const [iconColor] = useToken('colors', ['secondary.500']);
  const navigation = useRootNavigation();
  const label = locales.find((l) => l[0] === locale ?? '')?.[1].label ?? 'English';
  const { bottom } = useSafeAreaInsets();

  const onPressable = () => {
    if (disabled) return;

    navigation.navigate('Language');
    Analytics.trackEvent(Events.WelcomeChangeLanguage);
  };

  return (
    <Pressable testID={tID('LanguageSelect')} onPress={onPressable}>
      <HStack
        alignItems="center"
        background="white"
        borderColor="brand.border"
        borderTopWidth="1"
        bottom={bottom}
        justifyContent="center"
        justifyItems="center"
        p="4"
        space="3"
        w="full"
      >
        <Globe color={iconColor} />
        <Text>{label}</Text>
        <ChevronDownIcon color={iconColor} />
      </HStack>
    </Pressable>
  );
}
