import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Menu({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 20.75 19.25" width={size} {...props}>
      <G
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        transform="translate(-31.875 -2.927) translate(4.5)"
      >
        <Path d="M0 0L19.5 0" transform="translate(28 9.552)" />
        <Path d="M0 0L19.5 0" transform="translate(28 15.552)" />
        <Path d="M0 0L15.438 0" transform="translate(28 21.552)" />
        <Path d="M0 0L19.5 0" transform="translate(28 3.552)" />
      </G>
    </Svg>
  );
});
