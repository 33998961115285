import { Element } from 'react-native-render-html';

import { Locale } from 'app/generated/hygraph';

type HTMLElementProcessor = (el: Element, locale: Locale) => void;

const processors: Record<string, HTMLElementProcessor> = {
  a: processLink,
};

export function processElement(el: Element, locale: Locale): void {
  if (processors[el.tagName]) processors[el.tagName](el, locale);
}

function processLink(el: Element, locale: Locale) {
  convertRelativeLinkToAbsolute(el, locale);
}

function convertRelativeLinkToAbsolute(el: Element, locale: Locale) {
  const href = (el.attribs?.href && el.attribs?.href) ?? '';
  if (!href || href[0] !== '/') return;

  const subdomain = locale === Locale.En ? 'www' : locale;

  // eslint-disable-next-line no-param-reassign
  el.attribs.href = `https://${subdomain}.citypass.com${href}`;
}
