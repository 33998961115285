import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotChicagoC3({ color, size = 24 }) {
  return (
    <Svg height={size} id="Layer_1" viewBox="0 0 299.8 194.02" width={size * 1.545}>
      <Path
        d="M235.29 32.5c-20.57 0-38.91 9.69-50.73 24.73.2-.26.39-.52.6-.77C169.81 23.14 136.11 0 97.01 0 43.43 0 0 43.43 0 97.01s43.43 97.01 97.01 97.01c39.1 0 72.8-23.14 88.15-56.46-.22-.27-.43-.56-.65-.84 11.82 15.08 30.18 24.8 50.78 24.8 35.57 0 64.5-28.94 64.51-64.51 0-35.57-28.94-64.51-64.51-64.51Z"
        fill="#fff"
      />
      <Path
        d="M81.06 86.32H70v-5c0-5.44-2.5-7.76-5.35-7.76-4.46 0-5.88 2.32-5.88 7.13v32.63c0 4.81 1.43 7.13 5.88 7.13 4.01 0 5.35-2.32 5.35-6.51v-7.76h11.06v7.4c0 9.09-5.35 16.49-16.41 16.49s-16.94-7.4-16.94-16.49V80.43c0-9.09 5.35-16.49 16.94-16.49s16.41 8.02 16.41 17.65v4.73Zm41.54 42.88h-11.05v-28.53H99.78v28.53H88.73V64.83h11.05v26.21h11.77V64.83h11.05v64.37zm8.66-64.37h11.05v64.37h-11.05zm104.03 91.69c-32.81 0-59.51-26.7-59.51-59.51s26.7-59.51 59.51-59.51 59.5 26.7 59.51 59.51c0 32.81-26.7 59.51-59.51 59.51Z"
        fill="#000"
      />
      <Path
        d="M215.93 114.13c-4.8 0-8.82-3.58-9.65-7.94-.61-3.42-.55-6.51-.55-9.15s-.11-5.73.55-9.15c.61-3.97 4.85-7.94 9.65-7.94 3.86 0 7.28 2.26 8.93 5.57l8.05-2.26c-2.65-6.84-9.21-11.69-16.98-11.69-9.32 0-17.31 7.06-18.3 16.21 0 0-.39 3.53-.39 9.26s.39 9.1.39 9.1c.99 9.1 8.93 16.21 18.3 16.21 7.88 0 14.5-4.96 17.09-11.91l-8.1-2.15c-1.55 3.47-4.97 5.84-8.99 5.84Zm45.04-17.92c4.96-.77 9.54-4.63 9.54-11.19 0-5.35-3.09-9.32-8.16-11.41-2.48-1.16-5.18-1.93-8.05-1.93-7.72 0-14.39 4.85-16.98 11.69l8.05 2.21a9.914 9.914 0 0 1 8.93-5.57c2.09 0 4.13.72 5.79 1.98 1.21 1.1 1.98 2.59 1.98 4.36 0 4.47-4.3 6.45-10.09 6.45-1.93 0-4.74 0-5.57-.06v7.72c.72-.06 3.53-.11 5.57-.11 7 0 10.81 2.15 10.81 6.95 0 1.1-.22 2.15-.66 3.09-1.76 2.26-4.69 3.8-7.83 3.8-3.97 0-7.39-2.32-8.99-5.79l-8.1 2.15a18.16 18.16 0 0 0 17.09 11.91c2.59 0 5.13-.55 7.39-1.54 6.06-2.27 9.59-6.96 9.59-12.86 0-7.55-5.57-11.36-10.31-11.85Zm13.16 22.39c0-.55-.44-.94-1.1-.94h-1.27v2.87h.61v-.94h.5l.61.94h.72l-.66-1.05c.33-.11.6-.39.59-.88Zm-1.15.49h-.61v-.88h.61c.28 0 .5.17.5.44s-.17.44-.5.44Z"
        fill="#fff"
      />
      <Path
        d="M272.82 116.5c-1.54 0-2.7 1.21-2.7 2.7s1.16 2.7 2.7 2.7 2.7-1.21 2.7-2.7-1.16-2.7-2.7-2.7Zm0 5.01c-1.32 0-2.37-.99-2.37-2.37 0-1.27.99-2.37 2.37-2.37s2.43 1.05 2.37 2.37c0 1.27-1.05 2.37-2.37 2.37Z"
        fill="#fff"
      />
    </Svg>
  );
});
