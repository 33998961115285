import React from 'react';

import HTML from 'app/components/HTML';
import Screen from 'app/components/Screen';
import { RootScreenProps, RootStackParamList } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';

export type PrivacyPolicyScreenProps = RootScreenProps<RootStackParamList, 'PrivacyPolicy'>;

export default function PrivacyPolicyScreen({ navigation, route }: PrivacyPolicyScreenProps) {
  const { t } = useI18n();
  return (
    <Screen testID="PrivacyPolicyScreen">
      <HTML html={t<string>('gen_privacy_policy_description')} />
    </Screen>
  );
}
