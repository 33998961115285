import { IPressableProps, Pressable } from 'native-base';
import React from 'react';

import Blur from 'app/components/Blur';
import { tID } from 'app/services/TestHelper';

export type BlurOverlayProps = IPressableProps & {
  onPress?: () => void;
};

export default function BlurOverlay({ onPress, ...props }: BlurOverlayProps) {
  return (
    <Pressable
      {...props}
      h="full"
      left={0}
      position="absolute"
      role="presentation"
      testID={tID('BlurOverlay')}
      top={0}
      w="full"
      onPress={onPress}
    >
      <Blur
        role="presentation"
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </Pressable>
  );
}
