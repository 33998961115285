import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Warning({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 15 15" width={size} {...props}>
      <Path
        d="M14.978 12.614L8.746.714a1.333 1.333 0 00-2.362 0l-6.232 11.9a1.333 1.333 0 001.181 1.952H13.8a1.333 1.333 0 001.181-1.952zM6.9 4.9a.667.667 0 111.333 0v4a.667.667 0 01-1.333 0zm.7 7.673h-.02a1.019 1.019 0 01-1.015-.98.984.984 0 01.965-1.02h.019a1.018 1.018 0 011.016.978.983.983 0 01-.965 1.019z"
        fill="#810017"
        transform="translate(-.435 -.719) translate(.435 .719)"
      />
    </Svg>
  );
});
