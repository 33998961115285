import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Star({ size, color, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 18.536 23.625" width={size} {...props}>
      <G fill="none" stroke={color} strokeLinejoin="round" strokeWidth={1.25}>
        <Path
          d="M16.469 20.195a.952.952 0 01-.506.838.981.981 0 01-.989-.037l-6.74-4.3-6.74 4.294a.981.981 0 01-.988.037A.952.952 0 010 20.19V.951a.962.962 0 01.969-.954H15.5a.962.962 0 01.969.954z"
          transform="translate(.625 .625)"
        />
        <Path
          d="M8.804 4.168l1.242 2.337h2.422a.6.6 0 01.571.356.552.552 0 01-.154.635l-2.1 2.1 1.163 2.561a.589.589 0 01-.167.689.656.656 0 01-.735.069l-2.812-1.512-2.809 1.513a.656.656 0 01-.735-.069.589.589 0 01-.167-.689l1.161-2.562-2.1-2.1a.551.551 0 01-.156-.637.6.6 0 01.574-.354h2.422l1.241-2.337a.654.654 0 011.138 0z"
          transform="translate(.625 .625)"
        />
      </G>
    </Svg>
  );
});
