import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotSanDiego({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M82.35 80.5c-.45-5.59-3.43-7.22-5.95-7.22-3.61 0-5.59 2.35-5.59 6.4 0 11.1 22.73 16.42 22.73 34.1 0 10.73-7.22 16.69-17.68 16.69s-16.33-8.3-16.78-18.04l10.65-1.53c.45 6.58 3.07 9.83 6.67 9.83 3.88 0 6.5-2.08 6.5-5.95 0-12.9-22.73-16.33-22.73-35 0-10.37 6.31-16.24 17.32-16.24 9.11 0 14.52 6.59 15.52 15.79L82.36 80.5Zm18.32-16.05h16.6c11.55 0 17.68 6.31 17.68 19.67v25.8c0 13.35-6.13 19.67-17.68 19.67h-16.6V64.45Zm11.19 55.93h3.88c5.95 0 8.03-2.25 8.03-9.11V82.76c0-6.86-2.07-9.11-8.03-9.11h-3.88v46.73Z"
        fill="#000"
      />
    </Svg>
  );
});
