import { LinearGradient } from 'expo-linear-gradient';
import { Box, IBoxProps } from 'native-base';
import React, { ReactNode } from 'react';

import Hero from 'app/components/Hero';
import ResponsiveContainer, { DEFAULT_PADDING } from 'app/components/ResponsiveContainer';
import useWindow from 'app/hooks/useWindow';
import { useProductContext } from 'app/providers/ProductProvider';
import { getHero } from 'app/services/ProductHelper';
import { tID } from 'app/services/TestHelper';

export type ProductHeroProps = IBoxProps & {
  children?: ReactNode;
  beforeGradient?: ReactNode;
};

export default function ProductHero({ beforeGradient, children, ...props }: ProductHeroProps) {
  const { productContent } = useProductContext();
  const { width } = useWindow();

  return (
    <Box {...props} testID={tID('ProductHero')}>
      <Hero
        _gradient={{
          colors: ['rgba(0,0,0,.25)', 'rgba(0,0,0,.25)'],
          locations: [0, 1],
        }}
        dynamicHeight
        url={getHero(productContent).url}
      >
        {beforeGradient}

        <LinearGradient
          colors={['rgba(0,0,0,0)', 'rgba(0,0,0,.10)', 'rgba(0,0,0,.9)']}
          locations={[0, 0.1, 1]}
          style={{ width }}
        >
          <ResponsiveContainer pb={DEFAULT_PADDING + 2} pt="16">
            {children}
          </ResponsiveContainer>
        </LinearGradient>
      </Hero>
    </Box>
  );
}
