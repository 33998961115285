import { Box, IBoxProps } from 'native-base';
import React, { ReactNode } from 'react';

export const DEFAULT_PADDING = 4;

export type ResponsiveContainerProps = IBoxProps & {
  children?: ReactNode;
  padded?: boolean;
};

export default function ResponsiveContainer({
  children,
  padded = true,
  ...props
}: ResponsiveContainerProps) {
  return (
    <Box
      marginLeft="auto"
      marginRight="auto"
      maxWidth={600}
      pl={padded ? DEFAULT_PADDING : 0}
      pr={padded ? DEFAULT_PADDING : 0}
      testID="ResponsiveContainer"
      width="full"
      {...props}
    >
      {children}
    </Box>
  );
}
