import { GeneralReservation, GetContentQuery, Text } from 'app/generated/hygraph';

export function isObject(value: unknown): value is object {
  return !!value && typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function isKeyable(value: unknown, keys: string[]): value is Keyable {
  return isObject(value) && keys.every((key) => key in value);
}

export function isText(text: unknown): text is Text {
  return (
    isKeyable(text, ['key', 'value']) &&
    typeof text.value === 'string' &&
    typeof text.key === 'string'
  );
}

type LegacyGetContentQuery = GetContentQuery & {
  generalReservations: Partial<GeneralReservation>[];
};

export function isLegacyGetContentQuery(value: GetContentQuery): value is LegacyGetContentQuery {
  return !!(value as LegacyGetContentQuery).generalReservations;
}

export function isActualObject(value: unknown): value is object {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}
