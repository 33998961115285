import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Notifications({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="-2 0 20.75 21" width={size} {...props}>
      <G fill="none" stroke="#373737" strokeWidth={1}>
        <Path
          d="M11.022 0a4.886 4.886 0 00-4.868 4.978 4.877 4.877 0 00.641 2.62l-1.462 3.069 3.07-1.462A4.976 4.976 0 1011.022 0z"
          data-name="Path 155699"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(.5 .5)"
        />
        <Path
          d="M11.078 5.335V2.872"
          data-name="Path 155700"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(.5 .5)"
        />
        <Path
          d="M11.078 6.975a.205.205 0 010-.41"
          data-name="Path 155701"
          transform="translate(.5 .5)"
        />
        <Path
          d="M11.078 6.975a.205.205 0 000-.41"
          data-name="Path 155702"
          transform="translate(.5 .5)"
        />
        <Path
          d="M10.667 16.821H0"
          data-name="Path 155752"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(.5 .5)"
        />
        <Path
          d="M10.667 13.898v4.564a1.641 1.641 0 01-1.641 1.641H1.641A1.641 1.641 0 010 18.462V2.872a1.641 1.641 0 011.641-1.641h1.954"
          data-name="Path 155754"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(.5 .5)"
        />
      </G>
    </Svg>
  );
});
