import { Alert, Box, Heading, HStack, IAlertProps, Pressable, Text } from 'native-base';
import React, { ReactNode, useMemo } from 'react';
import Collapsible from 'react-native-collapsible';

import { ArrowDown, Error } from 'app/components/icons';
import Rotate from 'app/components/Rotate';
import useToggle from 'app/hooks/useToggle';
import { tID } from 'app/services/TestHelper';

const theme: Record<
  NonNullable<IAlertProps['status']>,
  { bg: string; title: string; icon: string }
> = {
  info: { bg: 'brand.info', icon: 'info.900', title: 'black' },
  success: { bg: 'brand.success', icon: 'success.900', title: 'black' },
  warning: { bg: 'brand.warning', icon: 'warning.900', title: 'black' },
  error: { bg: 'brand.error', icon: 'white', title: 'white' },
};

export type CPAlertProps = IAlertProps & {
  children?: ReactNode;
  title?: string;
  initialOpen?: boolean;
  collapsible?: boolean;
  onExpand?: () => void;
};

export default function CPAlert({
  children,
  title,
  status = 'info',
  initialOpen = true,
  collapsible = false,
  onExpand,
  ...props
}: CPAlertProps) {
  const [open, toggleOpen] = useToggle(collapsible ? initialOpen : true);
  const $children = useMemo(() => {
    return status === 'error'
      ? // have to do this to support ux color difference...
        React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { color: 'white' } as any);
          }
          if (typeof child === 'string') {
            return <Text color="white">{child}</Text>;
          }
          return child;
        })
      : children;
  }, [children, status]);

  const handleToggle = () => {
    if (!open && onExpand) onExpand();
    toggleOpen();
  };
  return (
    <Alert
      alignItems="flex-start"
      bg={theme[status].bg}
      status={status}
      testID={tID('Alert')}
      {...props}
    >
      {title ? (
        <Pressable w="full" onPress={collapsible ? handleToggle : undefined}>
          <HStack alignItems="center" justifyContent="space-between">
            <HStack alignItems="center" flexGrow={1} mx="0" space="2">
              {status === 'error' ? (
                <Error color="white" />
              ) : (
                <Alert.Icon color={theme[status].icon} mt="-0.5" name="info" />
              )}
              <Heading color={theme[status].title} size="sm">
                {title}
              </Heading>
            </HStack>
            {collapsible && (
              <Rotate end={180} rotated={open} start={0}>
                <ArrowDown size={16} />
              </Rotate>
            )}
          </HStack>
        </Pressable>
      ) : null}

      {$children &&
        title &&
        (collapsible ? (
          <Collapsible collapsed={!open} renderChildrenCollapsed>
            <Box pt="2" w="full">
              {$children}
            </Box>
          </Collapsible>
        ) : (
          <Box pt="2" w="full">
            {$children}
          </Box>
        ))}
      {$children && !title && (
        <HStack space="2">
          <Alert.Icon color={theme[status].icon} mt="0.5" />
          <Box flexShrink="1">{$children}</Box>
        </HStack>
      )}
    </Alert>
  );
}
