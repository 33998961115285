import React, { createContext, ReactNode, useContext } from 'react';

import Loading from 'app/components/Loading';
import useProduct, { UseProductResult } from 'app/hooks/useProduct';

type ProductContextProps = Omit<UseProductResult, 'productContent'> & {
  productContent: NonNullable<UseProductResult['productContent']>;
};
const ProductContext = createContext<ProductContextProps>({
  productContent: {},
} as ProductContextProps);

export type ProductProviderProps = {
  children?: ReactNode;
  productContentKey: string;
};

export default function ProductProvider({ children, productContentKey }: ProductProviderProps) {
  const value = useProduct({ productContentKey });
  if (!isProductContextProps(value)) {
    return <Loading />;
  }

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
}

export function useProductContext() {
  const context = useContext(ProductContext);
  if (!context) throw new Error('must be used inside a ProductProvider');
  return context;
}

function isProductContextProps(value: any): value is ProductContextProps {
  return typeof value === 'object' && value !== null && value.productContent !== 'undefined';
}
