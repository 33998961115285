import { Heading, IHeadingProps } from 'native-base';
import React, { ReactNode } from 'react';

export type DevToolsTitleProps = IHeadingProps & {
  children?: ReactNode;
};

export default function DevToolsTitle({ children, ...props }: DevToolsTitleProps) {
  return (
    <Heading
      bold
      color="gray.600"
      mb="3"
      size="xs"
      testID="DevToolsTitle"
      textAlign="center"
      textTransform="uppercase"
      {...props}
    >
      {children}
    </Heading>
  );
}
