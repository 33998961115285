import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function ArrowDown({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 22.998 18" width={size} {...props}>
      <G fill="none" transform="translate(-1455.001 -6868)">
        <Path
          d="M1477,6886h-21a1,1,0,0,1-1-1v-5a3,3,0,1,0,0-6v-5a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1v5a3,3,0,1,0,0,6v5A1,1,0,0,1,1477,6886Z"
          stroke="none"
        />
        <Path
          d="M 1476.7490234375 6884.75048828125 L 1476.7490234375 6881.07421875 C 1474.938232421875 6880.55126953125 1473.614135742188 6878.9228515625 1473.614135742188 6877 C 1473.614135742188 6875.078125 1474.938354492188 6873.44970703125 1476.7490234375 6872.92626953125 L 1476.7490234375 6869.25 L 1456.250732421875 6869.25 L 1456.250732421875 6872.92578125 C 1458.062377929688 6873.44873046875 1459.387329101562 6875.0771484375 1459.387329101562 6877 C 1459.387329101562 6878.92138671875 1458.062377929688 6880.5498046875 1456.250732421875 6881.07373046875 L 1456.250732421875 6884.75048828125 L 1476.7490234375 6884.75048828125 M 1477.000122070312 6886.00048828125 L 1455.999633789062 6886.00048828125 C 1455.448852539062 6886.00048828125 1455.000732421875 6885.55126953125 1455.000732421875 6884.99951171875 L 1455.000732421875 6880 C 1456.730712890625 6879.99853515625 1458.137329101562 6878.6533203125 1458.137329101562 6877 C 1458.137329101562 6875.345703125 1456.730224609375 6874 1455.000732421875 6874 L 1455.000732421875 6869.00048828125 C 1455.000732421875 6868.44873046875 1455.448852539062 6868 1455.999633789062 6868 L 1477.000122070312 6868 C 1477.550903320312 6868 1477.9990234375 6868.44873046875 1477.9990234375 6869.00048828125 L 1477.9990234375 6874 C 1476.269165039062 6874.0009765625 1474.864135742188 6875.34619140625 1474.864135742188 6877 C 1474.864135742188 6878.654296875 1476.2705078125 6880 1477.9990234375 6880 L 1477.9990234375 6884.99951171875 C 1477.9990234375 6885.55126953125 1477.550903320312 6886.00048828125 1477.000122070312 6886.00048828125 Z"
          fill={color}
          stroke="none"
        />
      </G>
      <Path
        d="M-7.139.074-1.1,5-4.676,8.812"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        transform="translate(15.942 13.118) rotate(90)"
      />
    </Svg>
  );
});
