import { useNetInfo } from '@react-native-community/netinfo';
import React, { ReactNode } from 'react';

import CPAlert from 'app/components/CPAlert';
import { useI18n } from 'app/providers/I18nProvider';
import { tID } from 'app/services/TestHelper';

export type OfflineProps = {
  children?: ReactNode;
};

export default function OfflineBanner({ children }: OfflineProps) {
  const { t } = useI18n();
  const { isInternetReachable } = useNetInfo();

  if (isInternetReachable !== false) return null;

  return (
    <CPAlert
      py="2"
      rounded="none"
      status="error"
      testID={tID('OfflineBanner')}
      title={t<string>('app_error_no_connection')}
      zIndex="1"
    />
  );
}
