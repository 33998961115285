/* eslint-disable no-console */
import { bugsnagLogger } from 'app/services/Bugsnag';
import { LogData, LogLevel, LogTransport, TLogger } from 'app/services/Logger.types';

const consoleLogger: LogTransport = ({ level, message, data, error }) => {
  if (!__DEV__ || process.env.NODE_ENV === 'test') return;
  const fn = level === 'debug' ? 'log' : level;
  const msg = `${level.toUpperCase()}: ${message}`;
  const fmtData = typeof window?.document === 'undefined' ? JSON.stringify(data, null, 2) : data;
  console[fn](...[msg, fmtData, error].filter(Boolean));
};

const transports: LogTransport[] = __DEV__ ? [consoleLogger] : [consoleLogger, bugsnagLogger];

const Logger: TLogger = {
  debug: LogFn('debug', transports),
  info: LogFn('info', transports),
  warn: LogFn('warn', transports),
  error: LogFn('error', transports),
};

export default Logger;

function LogFn(level: LogLevel, $transports: LogTransport[]) {
  return (message: string, $data: LogData = {}) => {
    const { error, ...rest } = $data;
    const data = Object.keys(rest).length > 0 ? rest : undefined;
    const time = new Date();
    $transports.forEach((handler) => handler({ time, level, message, error, data }));
  };
}
