import * as React from 'react';
import Svg, { ClipPath, Defs, G, Mask, Path, Rect } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Close({ size, color, ...props }) {
  return (
    <Svg fill="none" height={size} viewBox="0 0 18 18" width={size} {...props}>
      <G clipPath="url(#clip0_17_397)">
        <Path d="M29 -11H-11V29H29V-11Z" />
        <Path
          d="M14.5 3.99902L9.5 8.99902L4.5 3.99902"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.25}
        />
        <Path
          d="M4.5 13.999L9.5 8.99902L14.5 13.999"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.25}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_17_397">
          <Rect height={size} width={size} />
        </ClipPath>
      </Defs>
    </Svg>
  );
});
