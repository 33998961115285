import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function MapPin({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size} {...props}>
      <Circle cx="12" cy="8" fill="#fff" r="4" />
      <Path
        d="M11.9 1a8.6 8.6 0 00-8.6 8.6c0 4.35 7.2 12.05 8.42 13.33a.24.24 0 00.35 0c1.22-1.27 8.42-9 8.42-13.33A8.6 8.6 0 0011.9 1zm0 11.67A3.07 3.07 0 1115 9.6a3.07 3.07 0 01-3.1 3.07z"
        fill={color}
        stroke={color}
      />
    </Svg>
  );
});
