import Constants from 'expo-constants';
import { noop } from 'lodash';
import { Platform } from 'react-native';
import * as ZendeskNative from 'react-native-zendesk-messaging';

import { Locale } from 'app/generated/hygraph';
import Logger from 'app/services/Logger';

const MODULE = '[ZendeskMessagingService]';
const CHANNEL_KEY =
  Platform.OS === 'android'
    ? Constants.expoConfig?.extra?.zendesk.channelKeyAndroid
    : Constants.expoConfig?.extra?.zendesk.channelKeyIos;

export const ZendeskLanguage: Record<Locale, string> = {
  de: 'german',
  en: 'english',
  es: 'spanish',
  fr: 'french',
  it: 'italian',
  ja: 'japanese',
  pt: 'portuguese',
  zh: 'chinese',
};

export const ZendeskFieldId = {
  OrderNumber: '360026082432',
  Platform: '24971135883028',
  OS: '24971121235604',
  Device: '24971123357972',
  AppVersion: '24971103831572',
  Language: '4413098935956',
  TravelPartyCount: '24971175993876',
  VerificationState: '24971179583252',
  PurchasedProduct: '360026125991',
  Email: '25662805926292',
  SessionId: '25776472159124',
  UserId: '25776454184596',
} as const;

type ZendeskConversationFields = Parameters<typeof ZendeskNative.setConversationFields>[0];

type ConversationFields = Record<
  keyof typeof ZendeskFieldId,
  string | number | boolean | null | undefined
>;

/**
 * Universal implementation for interacting with the Zendesk Messaging SDK on native and web.
 *
 * see here for more info:
 *   - web https://developer.zendesk.com/api-reference/widget-messaging/web/core/
 *   - native https://github.com/leegeunhyeok/react-native-zendesk-messaging
 */
const ZendeskMessagingService = {
  init(): Promise<void> {
    if (Platform.OS === 'web') {
      return new Promise((resolve, reject) => {
        const checkWebSDK = setInterval(() => {
          if (typeof window === 'undefined') return;
          const { zE } = window as any;
          if (zE) {
            clearInterval(checkWebSDK);
            resolve();
            Logger.debug(`${MODULE} zendesk sdk initialized`);
          }
        }, 300);
      });
    }

    return ZendeskNative.initialize({ channelKey: CHANNEL_KEY })
      .then(() => {
        Logger.debug(`${MODULE} zendesk sdk initialized`);
      })
      .catch((error) => {
        Logger.error(`${MODULE} zendesk sdk initialization error`, {
          error,
        });
      });
  },

  openMessaging() {
    if (Platform.OS === 'web') {
      getZendeskWeb()('messenger', 'open');
    } else {
      ZendeskNative.openMessagingView();
    }
  },

  setConversationFields(fields: ConversationFields) {
    const conversationFields: ZendeskConversationFields = Object.entries(
      fields
    ).reduce<ZendeskConversationFields>((acc, [field, value]) => {
      if (typeof value !== 'undefined' && value !== null) acc[ZendeskFieldId[field]] = value;
      return acc;
    }, {});

    if (Platform.OS === 'web') {
      getZendeskWeb()(
        'messenger:set',
        'conversationFields',
        Object.entries(conversationFields).map(([id, value]) => ({
          id,
          value,
        }))
      );
    } else {
      ZendeskNative.setConversationFields(conversationFields);
    }
  },

  setConversationTags(tags: string[]) {
    if (Platform.OS === 'web') {
      getZendeskWeb()('messenger:set', 'conversationTags', tags);
    } else {
      ZendeskNative.setConversationTags(tags);
    }
  },

  onUnreadMessages(listener: (payload: { unreadCount: number }) => void) {
    if (Platform.OS === 'web') {
      getZendeskWeb()('messenger:on', 'unreadMessages', (unreadCount: number) => {
        listener({ unreadCount });
      });

      return {
        // the web sdk doesn't have a way to remove the listener so we pass a stub
        remove: () => {},
      };
    }

    return ZendeskNative.addEventListener('unreadMessageCountChanged', ({ unreadCount }) => {
      listener({ unreadCount });
    });
  },
};

export default ZendeskMessagingService;

function getZendeskWeb(): (action: string, key: string, ...args: any[]) => void {
  if (Platform.OS === 'web' && typeof window !== 'undefined') {
    const { zE } = window as any;
    if (zE) return zE;
    Logger.warn(`${MODULE} zendesk web sdk not found`);
  }
  return noop;
}
