import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function ArrowCircle({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 40 40" width={size} {...props}>
      <Path
        d="M20 0A20 20 0 110 20 20 20 0 0120 0z"
        fill="#fff"
        opacity={0.89}
        transform="rotate(180 20 20)"
      />
      <Path
        d="M17.911 12.847l5.586 6.79-5.586 6.789"
        fill="none"
        stroke="#373737"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        transform="rotate(180 20 20)"
      />
    </Svg>
  );
});
