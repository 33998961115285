import { createContext, ReactNode, useContext, useMemo } from 'react';

import useConfiguration from 'app/hooks/useConfiguration';
import useDestinations from 'app/hooks/useDestinations';
import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import useStore from 'app/hooks/useStore';
import { createGuest } from 'app/services/GuestCenterService';

export type GuestContextProps = ReturnType<typeof createGuest>;

export const GuestContext = createContext<GuestContextProps>(
  createGuest({
    email: null,
    activeEntitlementKey: null,
    guestDataResponse: undefined,
    configurationResponse: undefined,
    destinationsContent: undefined,
  })
);

export default function GuestProvider({ children }: { children: ReactNode }) {
  const activeEntitlementKey = useStore((s) => s.entitlementKey);
  const email = useStore((s) => s.email);
  const { data: guestDataResponse } = useGuestDataQuery({
    notifyOnChangeProps: ['data', 'status'],
  });
  const { data: configurationResponse } = useConfiguration({ notifyOnChangeProps: ['data'] });
  const { data: destinationsContent } = useDestinations({ notifyOnChangeProps: ['data'] });

  const guest = useMemo(() => {
    return createGuest({
      email,
      activeEntitlementKey,
      guestDataResponse,
      configurationResponse,
      destinationsContent,
    });
  }, [email, activeEntitlementKey, guestDataResponse, configurationResponse, destinationsContent]);

  return <GuestContext.Provider value={guest}>{children}</GuestContext.Provider>;
}

export const useGuestContext = () => useContext(GuestContext);
