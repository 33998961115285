import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function TicketAdd({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size} {...props} fill={color}>
      <Path d="M1.5 17C.673 17 0 16.327 0 15.5v-3.031a1 1 0 01.874-.996c.796-.099 1.505-.502 1.997-1.135s.708-1.419.609-2.214A2.999 2.999 0 00.875 5.519a1.001 1.001 0 01-.875-1V1.5C0 .673.673 0 1.5 0h21c.827 0 1.5.673 1.5 1.5v3.031a1 1 0 01-.874.996c-.796.1-1.504.504-1.996 1.136a2.982 2.982 0 00-.607 2.214l.01.072a.496.496 0 01-.092.369.496.496 0 01-.404.204.497.497 0 01-.493-.425l-.013-.089a3.978 3.978 0 01.809-2.958A3.973 3.973 0 0123 4.535V1.5a.5.5 0 00-.5-.5h-21a.5.5 0 00-.5.5v3.023a4.004 4.004 0 012.66 6.429 3.973 3.973 0 01-2.661 1.513L1 15.5a.5.5 0 00.5.5H9a.5.5 0 010 1H1.5z" />
      <Circle cx={16} cy={3} r={0.75} />
      <Circle cx={16} cy={6} r={0.75} />
      <Circle cx={16} cy={9} r={0.75} />
      <Path d="M17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.033 0-5.5 2.467-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.467 5.5-5.5-2.467-5.5-5.5-5.5z" />
      <Path d="M17.5 21a.5.5 0 01-.5-.5V18h-2.5a.5.5 0 010-1H17v-2.5a.5.5 0 011 0V17h2.5a.5.5 0 010 1H18v2.5a.5.5 0 01-.5.5z" />
    </Svg>
  );
});
