import { Box, Heading, Text } from 'native-base';
import React from 'react';
import { RefreshControl } from 'react-native';

import CPAlert from 'app/components/CPAlert';
import HTML from 'app/components/HTML';
import LinkTicketsPrompt from 'app/components/LinkTicketsPrompt';
import ResponsiveContainer, { DEFAULT_PADDING } from 'app/components/ResponsiveContainer';
import Screen from 'app/components/Screen';
import { NoticePlacements } from 'app/generated/hygraph';
import useDestinations, { Destination } from 'app/hooks/useDestinations';
import useGuest from 'app/hooks/useGuest';
import useNotices from 'app/hooks/useNotices';
import { RootScreenProps, RootStackParamList } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import {
  getDescription,
  getThemeAsAlertStatus,
  getTitle,
  groupByProducts,
} from 'app/services/NoticeHelper';

export type NotificationsScreenProps = RootScreenProps<RootStackParamList, 'Notifications'>;

export default function NotificationsScreen({ navigation, route }: NotificationsScreenProps) {
  const { t } = useI18n();
  const { data: products } = useDestinations();
  const { hasEntitlements, entitlements } = useGuest();
  const {
    data: notices,
    isError,
    isFetching,
    refetch,
  } = useNotices({
    placement: NoticePlacements.Notifications,
  });
  const productsWithEntitlements = entitlements.reduce<Destination[]>((acc, ent) => {
    const product = products.find((p) => p.key === ent.productContentKey);
    if (product) acc.push(product);
    return acc;
  }, []);
  const noticeGroups = groupByProducts(notices, productsWithEntitlements);

  return (
    <Screen
      after={<LinkTicketsPrompt safeInsetBottom />}
      refreshControl={<RefreshControl refreshing={isFetching} onRefresh={refetch} />}
      testID="NotificationsScreen"
    >
      {isError && !notices && <CPAlert status="error">{t('app_error_fetching_data')}</CPAlert>}
      {!hasEntitlements && <Text>{t('app_notifications_body_anonymous')}</Text>}
      {noticeGroups.map((group, idx) => (
        <Box
          key={group.product.key}
          borderTopColor="black"
          borderTopWidth={idx ? 2 : 0}
          mx={-DEFAULT_PADDING}
          py={DEFAULT_PADDING}
        >
          <ResponsiveContainer>
            <Heading fontSize="md" mb="2">
              {group.product.name}
            </Heading>

            {group.notices.length === 0 && <Text>{t('app_notifications_empty')}</Text>}
            {group.notices.map((notice, $idx) => (
              <Box key={notice.id} borderColor="brand.border" borderTopWidth={$idx ? 1 : 0}>
                <CPAlert
                  bg="transparent"
                  px="0"
                  status={getThemeAsAlertStatus(notice)}
                  title={getTitle(notice)}
                >
                  <HTML html={getDescription(notice)} />
                </CPAlert>
              </Box>
            ))}
          </ResponsiveContainer>
        </Box>
      ))}
    </Screen>
  );
}
