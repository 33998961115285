import { useCallback, useEffect, useMemo, useState } from 'react';
import { Animated } from 'react-native';

const DEFAULT_SEQUENCE: [toValue: number, duration: number][] = [
  [30, 3000],
  [60, 6000],
  [80, 10000],
  [90, 20000],
];

export default function useAnimatedProgress(
  initialValue = 0,
  // sequence needs to be a stable ref
  sequence: [toValue: number, duration: number][] = DEFAULT_SEQUENCE
): [progress: number, setProgress: (targetValue: number, cb?: () => void) => void] {
  const animatedProgress = useMemo(() => new Animated.Value(initialValue), [initialValue]);
  const [progress, setProgress] = useState(initialValue);

  const startSequence = useCallback(() => {
    const value = getValue(animatedProgress);

    Animated.sequence(
      sequence
        .filter(([toValue]) => toValue > value)
        .map(([toValue, duration]) => {
          return Animated.timing(animatedProgress, {
            toValue,
            duration,
            useNativeDriver: false,
          });
        })
    ).start();
  }, [animatedProgress, sequence]);

  useEffect(() => {
    const listenerId = animatedProgress.addListener(({ value }) => {
      setProgress(value);
    });

    startSequence();

    return () => {
      animatedProgress.removeListener(listenerId);
    };
  }, [animatedProgress, startSequence]);

  const setTargetProgress = useCallback(
    (targetValue: number, cb?: () => void) => {
      if (targetValue < getValue(animatedProgress)) return;

      animatedProgress.stopAnimation();

      Animated.timing(animatedProgress, {
        toValue: targetValue,
        duration: 1000,
        useNativeDriver: false,
      }).start(() => {
        startSequence();
        if (cb) cb();
      });
    },
    [animatedProgress, startSequence]
  );

  return [progress, setTargetProgress];
}

function getValue(value: Animated.Value): number {
  return Number(JSON.stringify(value));
}
