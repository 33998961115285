import React from 'react';
import { Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotNewYorkCAll({ color, size = 24 }) {
  return (
    <Svg height={size} id="Layer_1" viewBox="0 0 299.8 194.02" width={size * 1.545}>
      <Path
        d="M235.11 32.63c-20.14 0-38.14 9.3-49.95 23.82C169.8 23.14 136.11 0 97.01 0 43.43 0 0 43.43 0 97.01s43.43 97.01 97.01 97.01c39.1 0 72.79-23.14 88.15-56.45 11.81 14.52 29.82 23.82 49.95 23.82 35.5 0 64.37-28.88 64.38-64.38 0-35.5-28.88-64.38-64.38-64.38Z"
        fill="#fff"
      />
      <Path
        d="M235.29 156.52c-32.81 0-59.51-26.7-59.51-59.51s26.7-59.51 59.51-59.51 59.5 26.7 59.51 59.51c0 32.81-26.7 59.51-59.51 59.51Z"
        fill="#000"
      />
      <Path
        d="M279.89 111.55h.39v1.22h.18v-1.22h.39v-.17h-.96v.17zm-21.11-4.07v-26c0-.86-.68-1.55-1.51-1.55h-4.36v28.33c0 3.6 1.71 5.84 6.06 5.84 1.61 0 3.03-.19 4.64-.39v-4.97c-.85.19-1.8.49-2.7.49-1.47 0-2.13-.39-2.13-1.75Zm13.61 0v-26c0-.86-.68-1.55-1.51-1.55h-4.36v28.33c0 3.6 1.71 5.84 6.06 5.84 1.61 0 3.03-.19 4.64-.39v-4.97c-.85.19-1.8.49-2.7.49-1.47 0-2.13-.39-2.13-1.75Zm9.76 3.9-.4 1.01-.4-1.01h-.27v1.39h.17v-1.15l.47 1.15h.05l.47-1.15v1.15h.18v-1.39h-.27zm-44-31.49h-5.56l-9.85 34.25h6.32l2.04-7.39h10.8l1.89 7.39h6.32l-9.1-32.08a2.988 2.988 0 0 0-2.87-2.17Zm-5.55 21.1 3.94-14.52h.09l3.75 14.52h-7.79Zm-14.97-7.32c-1.84 0-3.34 1.5-3.34 3.34s1.5 3.34 3.34 3.34 3.34-1.5 3.34-3.34-1.5-3.34-3.34-3.34Zm-16.9 15.16c-3.31 0-6.14-2.45-6.69-5.48-.43-2.4-.38-4.52-.38-6.34s-.09-3.96.38-6.34c.44-2.77 3.38-5.48 6.69-5.48 2.69 0 5.04 1.56 6.15 3.88l5.58-1.55c-1.81-4.72-6.38-8.07-11.74-8.07-6.48 0-11.99 4.91-12.66 11.21 0 0-.27 2.44-.27 6.42s.27 6.29.27 6.29c.68 6.3 6.18 11.21 12.66 11.21 5.42 0 10.04-3.44 11.8-8.25l-5.6-1.48a6.822 6.822 0 0 1-6.2 3.98Z"
        fill="#fff"
      />
      <Path
        d="M74.37 129.2h-9.63L50.65 88.99h-.18v40.21h-9.98V64.83h10.25l13.46 36.82h.18V64.83h9.99v64.37zm29.78-26.75v26.75H93.09v-26.75L79.72 64.83h11.23l7.76 24.52h.18l7.4-24.52h11.23l-13.37 37.62zm49.39-16.13h-11.06v-5c0-5.44-2.5-7.76-5.35-7.76-4.46 0-5.88 2.32-5.88 7.13v32.63c0 4.81 1.43 7.13 5.88 7.13 4.01 0 5.35-2.32 5.35-6.51v-7.76h11.06v7.4c0 9.09-5.35 16.49-16.41 16.49s-16.94-7.4-16.94-16.49V80.43c0-9.09 5.35-16.49 16.94-16.49s16.41 8.02 16.41 17.65v4.73Z"
        fill="#000"
      />
    </Svg>
  );
});
