import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';

import { GetDestinationsQuery, Locale } from 'app/generated/hygraph';
import { Env } from 'app/hooks/useStore';
import { createEntitlement } from 'app/services/EntitlementHelper';
import { auth } from 'app/services/Firebase';
import GuestCenterClient, {
  setAuthorizationToken,
  setBaseURL,
  setLanguageHeader,
} from 'app/services/GuestCenterClient';
import {
  AttractionConfig,
  AuthCodeVerificationResponse,
  AuthTokenVerificationResponse,
  ConfigurationResponse,
  Entitlement,
  GuestData,
  GuestDataResponse,
  Pass,
  ProductLineup,
  ProductLineupResponse,
  Reservation,
  TicketCategory,
  TicketClass,
  UserVerificationResponse,
  ValidStates,
} from 'app/services/GuestCenterService.types';
import Logger from 'app/services/Logger';
import { createAppProductLineupStub } from 'app/services/ProductLineupHelper';

const MODULE = '[GuestCenterService]';
export const client = GuestCenterClient();

export const getBaseURL = () => client.defaults.baseURL;
export const setEnv = (env: Env) => {
  setBaseURL(client, env);
};

export enum VerifyEmailAction {
  Verify,
  SignIn,
  Cleanup,
}

export const signInWithEmail = async (email: string, locale: string) => {
  setLanguageHeader(client, locale);
  return client.post<UserVerificationResponse>(`/verify/email`, {
    email: `${email}`,
  });
};

export const getAuthCodeValidition = (payload: {
  email: string;
  code: string;
  codeToken: string;
}) => {
  return client.post<AuthCodeVerificationResponse>(`/verify/code`, payload);
};

export const getAuthTokenValidation = async (
  authToken: string,
  email: Maybe<string>,
  bearerToken: Maybe<string>
) => {
  Logger.info(`${MODULE} getAuthTokenValidation()`, { email });

  // this was implemented with the bearer token being passed in as there is a slight delay in the user
  // getting set when we refresh on web. means the user in the client was null when we go to set the token
  // and so we were not accessing the verify token call on the server as the signed in user
  if (bearerToken) {
    setAuthorizationToken(client, bearerToken);
  }
  if (authToken !== '') {
    const bodyData = {
      token: `${authToken}`,
      email: email ?? `${email}`,
    };
    return client.post<AuthTokenVerificationResponse>('/verify/token', bodyData);
  }
  return null;
};

// TODO: tidy up with promise response / consolidate call pattern
export const getGuestData = async (
  email: string | null | undefined
): Promise<GuestDataResponse | undefined> => {
  let payload = null;
  const { currentUser } = auth();

  const isEmailValid = (address: string | null | undefined) => {
    // TODO work out why we get 'null' as the value
    return address && address !== '' && address !== 'null';
  };

  try {
    if (
      currentUser &&
      isEmailValid(currentUser.email) &&
      currentUser.emailVerified &&
      isEmailValid(email)
    ) {
      const target = `/guest`;
      Logger.info(`${MODULE} get user - calling user api`, { target, email });
      const { data } = await client.post(
        target,
        { email: `${email}` },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      payload = data;
      Logger.info(`${MODULE} get user - api user response successful = ${payload?.success}`, {
        email: payload?.guestData?.email ?? 'no email returned!!',
      });
    } else {
      Logger.info(`${MODULE} get user - no user`);
    }
  } catch (error) {
    Logger.error(`${MODULE} get user - error`, { e: error });
    throw error;
  }
  return payload;
};

export type WalletPassURLProps = {
  email: string;
  walletType: 'apple' | 'google';
  attractionKey: string;
  attractionOrderNumber: string;
  locator: string;
  barcode: string;
  locale: Locale;
  categoryKey?: string;
  optionKey?: string;
};

export const getWalletPassUrl = ({
  email,
  walletType,
  attractionKey,
  attractionOrderNumber,
  locator,
  barcode,
  locale = Locale.En,
  categoryKey = '',
  optionKey = '',
}: WalletPassURLProps): string => {
  // going to go with convention for this without any security mechanism as the data here isn't available unless you can authenticate with the guest's email
  const categoryParam = categoryKey ? `&categoryKey=${categoryKey}` : '';
  const optionParam = optionKey ? `&optionKey=${optionKey}` : '';
  return `${getBaseURL()}/guest/wallet/${walletType}/email/${email}/order/${attractionOrderNumber}/party/${locator}/pass/${barcode}/attraction/${attractionKey}?lang=${locale}${categoryParam}${optionParam}`;
};

// TODO: implement Axios with request / response typing
export const getProductData = async (): Promise<ProductLineupResponse> => {
  let payload = null;
  try {
    const target = `/lineup`;
    Logger.info(`${MODULE} get product - calling product lineup api`, { target });
    const { data } = await client.get(target, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    payload = data;
    Logger.info(`${MODULE} get product - api lineup response successful = ${payload?.count > 0}`);
  } catch (error) {
    Logger.error(`${MODULE} get product - api lineup response error`, { e: error });
  }
  return payload;
};

export const getConfiguration = async () => {
  const { data } = await client.post<ConfigurationResponse>('/configuration');
  return data;
};

export const getEntitlements = ({
  guestDataResponse,
  productLineups,
  attractionConfigs,
  destinationsContent,
}: {
  guestDataResponse: GuestDataResponse | undefined;
  productLineups: ProductLineup[];
  attractionConfigs: AttractionConfig[];
  destinationsContent: GetDestinationsQuery['products'];
}): Entitlement[] => {
  const passes = guestDataResponse?.guestData?.passes;

  if (passes) {
    return uniqWith(
      passes.map((pass) => {
        const productLineup: ProductLineup =
          productLineups.find((pl) => pl.productLineupKey === pass.productLineupKey ?? '') ??
          createAppProductLineupStub();

        return {
          locator: pass.locator,
          productLineup,
        };
      }),
      isEqual
    ).map(({ locator, productLineup }) => {
      const travelParty = guestDataResponse?.guestData.travelParties?.find(
        (tp) => tp.locator === locator
      );
      const ent = createEntitlement({
        entitlementKey: locator,
        travelParty,
        passes: guestDataResponse?.guestData.passes ?? [],
        reservations: guestDataResponse?.guestData.reservations ?? [],
        attractionConfigs,
        productLineup,
        destinationsContent,
      });

      Logger.debug(`${MODULE} created entitlement`, {
        entitlementKey: ent.entitlementKey,
        productContentKey: ent.productContentKey,
        passesCount: ent.passes.length,
      });

      return ent;
    });
  }
  return [];
};

export function createGuest({
  email,
  activeEntitlementKey,
  guestDataResponse,
  configurationResponse,
  destinationsContent = [],
}: {
  email: Maybe<string>;
  activeEntitlementKey: Maybe<string>;
  guestDataResponse: GuestDataResponse | undefined;
  configurationResponse: ConfigurationResponse | undefined;
  destinationsContent: GetDestinationsQuery['products'] | undefined;
}) {
  const { passes = [], reservations = [] } = guestDataResponse?.guestData ?? {};
  const productLineups = configurationResponse?.productLineups.configuration ?? [];
  const attractionConfigs = configurationResponse?.attractions.configuration ?? [];
  const entitlements = getEntitlements({
    guestDataResponse,
    productLineups,
    attractionConfigs,
    destinationsContent: destinationsContent ?? [],
  });
  const activeEntitlement =
    entitlements.find((ent) => ent.isValid && ent.entitlementKey === activeEntitlementKey) ?? null;
  const entitlementReservations = entitlements.flatMap((ent) => ent.reservations);

  if (email && guestDataResponse?.guestData?.email && guestDataResponse.guestData.email !== email) {
    const error = `${MODULE} : Creating Guest Context : ${email} with data for ${guestDataResponse.guestData.email}`;
    Logger.warn(error);
  }

  const validEntitlements = entitlements.filter((ent) => ent.isValid);

  return {
    email: email ?? guestDataResponse?.guestData?.email ?? '',
    hasResponse: !!guestDataResponse,
    hasEntitlements: entitlements.length > 0,
    hasMultipleEntitlements: entitlements.length > 1,
    hasPasses: passes.length > 0,
    hasReservations: reservations.length > 0,
    entitlements: validEntitlements,
    allEntitlements: entitlements,
    activeEntitlement,
    reservations: entitlementReservations,
    passes: passes.filter((p) => p.status === ValidStates.Valid),
    allPasses: passes,
    entitlementsQtyCode: getEntitlementsQtyCode(validEntitlements),
    hasActiveEntitlementFor: (productContentKey: Maybe<string>) => {
      if (!productContentKey || !activeEntitlement) return false;
      return activeEntitlement.productContentKey === productContentKey;
    },
    hasEntitlementFor: (productContentKey: Maybe<string>) => {
      if (!productContentKey || !validEntitlements || validEntitlements.length === 0) return false;
      return validEntitlements.some((ent) => ent.productContentKey === productContentKey);
    },
  };
}

function getEntitlementsQtyCode(entitlements: Entitlement[]) {
  switch (entitlements.length) {
    case 0:
      return 'none';
    case 1:
      return 'single';
    default:
      return 'multi';
  }
}

export function getPasses(userDataResponse: GuestDataResponse, ticketClass?: TicketClass): Pass[] {
  if (userDataResponse?.guestData?.passes) {
    if (ticketClass) {
      return userDataResponse.guestData.passes.filter(
        (pass: Pass) => pass.ticketClass === ticketClass
      );
    }
    return userDataResponse.guestData.passes;
  }
  return [];
}

export const getPassCount = (userDataResponse: GuestDataResponse, ticketClass?: TicketClass) => {
  return getPasses(userDataResponse, ticketClass).length;
};

export function getReservations(guestData: GuestData): Reservation[] {
  if (guestData?.reservations) {
    return guestData.reservations;
  }
  return [];
}

export const getReservationsForAttraction = (
  reservations: Reservation[],
  attractionKey: string
): Reservation[] => {
  return reservations.filter(
    (reservation) => reservation.attractionKey.toLowerCase() === attractionKey.toLowerCase()
  );
};

export const getProductKeys = (passes: Pass[]) => {
  return uniq(passes.flatMap((pass) => pass.productLineupKey));
};

export const getLocators = (passes: Pass[]) => {
  return uniq(passes.flatMap((pass) => pass.locator));
};

export const getProductKeyCount = (passes: Pass[]) => {
  return getProductKeys(passes).length;
};

export const getProductLineupCount = (passes: Pass[]) => {
  return getProductKeys(passes).length;
};

export const getTravelPartyCount = (passes: Pass[]) => {
  return getLocators(passes).length;
};

export const findProductLineupForBarcode = (
  barcode: string,
  { productLineup, passes }: { productLineup: ProductLineup[]; passes: Pass[] }
): ProductLineup | undefined => {
  Logger.debug(`${MODULE} findProductLineupForBarcode`, {
    barcode,
    productLineup: productLineup.map((p) => ({
      contentKey: p.contentKey,
      productLineupKey: p.productLineupKey,
      year: p.year,
    })),
    passes: passes.map((p) => ({
      barcode: p.barcode,
      lineupKey: p.productLineupKey,
    })),
  });
  const pass = passes.find(($pass) => $pass.barcode === barcode);
  if (!pass) {
    Logger.warn(`${MODULE} no pass found for barcode`, { barcode });
    return undefined;
  }

  const lineup = productLineup.find(
    ($productLineup) => $productLineup.productLineupKey === pass?.productLineupKey
  );
  if (!lineup) {
    Logger.warn(`${MODULE} no product lineup found for pass`, { pass });
    return undefined;
  }

  return lineup;
};

export const getPassForBarcode = ({ barcode, passes }: { barcode: string; passes: Pass[] }) => {
  const pass = passes.find((p) => p.barcode === barcode);
  if (!pass) Logger.warn(`${MODULE} unable to find pass for barcode`, { barcode, passes });
  return pass;
};

export const getPassesForBarcodes = ({
  barcodes,
  passes,
}: {
  barcodes: string[];
  passes: Pass[];
}) => {
  return barcodes.reduce<Pass[]>((acc, barcode) => {
    const pass = getPassForBarcode({ barcode, passes });
    if (pass) acc.push(pass);
    return acc;
  }, []);
};

export const getReservation = ({
  reservationKey,
  userData,
}: {
  reservationKey: string;
  userData?: GuestDataResponse;
}) => {
  return userData?.guestData.reservations.find((res) => res.reservationKey === reservationKey);
};
