import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotDenver({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M40.45 64.45h16.6c11.55 0 17.68 6.31 17.68 19.67v25.8c0 13.35-6.13 19.67-17.68 19.67h-16.6V64.45Zm11.19 55.93h3.88c5.95 0 8.03-2.25 8.03-9.11V82.76c0-6.86-2.07-9.11-8.03-9.11h-3.88v46.73Zm30.76 9.2V64.45h29.95v9.74H93.59v16.78h14.43v9.74H93.59v19.12h20.03v9.74H82.41Zm36.89 0V64.45h10.38l13.62 37.26h.18V64.45h10.1v65.13h-9.74L129.58 88.9h-.18v40.68h-10.1Z"
        fill="#000"
      />
    </Svg>
  );
});
