import dayjs from 'dayjs';

import { NoticePlacements, useGetNoticesQuery } from 'app/generated/hygraph';
import { useI18n } from 'app/providers/I18nProvider';
import HygraphService from 'app/services/HygraphService';
import {
  getActive,
  hasAttraction,
  hasPlacement,
  hasProduct,
  Notice,
} from 'app/services/NoticeHelper';
import { toMs } from 'app/utils/time';

const { client } = HygraphService();

export type UseNoticesProps = {
  placement?: NoticePlacements;
  productContentKey?: string;
  attractionContentKey?: string;
};

export default function useNotices({
  placement,
  productContentKey,
  attractionContentKey,
}: UseNoticesProps = {}) {
  const { locale } = useI18n();
  return useGetNoticesQuery(
    client,
    { locale, today: dayjs().startOf('day').format('YYYY-MM-DD') },
    {
      staleTime: toMs(15, 'm'),
      select: (data) => {
        const filters = getFilters({ placement, productContentKey, attractionContentKey });
        return getActive(data.notices).filter((notice) => filters.every((f) => f(notice)));
      },
    }
  );
}

type GetFiltersProps = Pick<
  UseNoticesProps,
  'placement' | 'productContentKey' | 'attractionContentKey'
>;
type NoticeFilterPredicate = (notice: Notice) => boolean;

function getFilters({
  placement,
  productContentKey,
  attractionContentKey,
}: GetFiltersProps): NoticeFilterPredicate[] {
  const filters: ((n: Notice) => boolean)[] = [];

  if (placement) filters.push((n) => hasPlacement(n, placement));
  if (productContentKey) filters.push((n) => hasProduct(n, productContentKey));
  if (attractionContentKey) filters.push((n) => hasAttraction(n, attractionContentKey));

  return filters;
}
