import { Box, HStack, Text } from 'native-base';
import React, { ReactNode } from 'react';
import { shallow } from 'zustand/shallow';

import CPPopover from 'app/components/CPPopover/CPPopover';
import useStore from 'app/hooks/useStore';
import { useI18n } from 'app/providers/I18nProvider';
import { tID } from 'app/services/TestHelper';

export type TicketsInfoPopoverProps = {
  children?: ReactNode;
};

export default function TicketsInfoPopover({ children }: TicketsInfoPopoverProps) {
  const { t } = useI18n();
  const [hasCompletedTicketsInfo, setHasCompletedTicketsInfo] = useStore(
    (s) => [s.hasCompletedTicketsInfo, s.setHasCompletedTicketsInfo],
    shallow
  );

  if (hasCompletedTicketsInfo) return null;

  return (
    <Box
      bg="brand.primary"
      bottom="100%"
      ml="auto"
      mr="auto"
      position="absolute"
      px="3"
      py="2"
      rounded="md"
      shadow="7"
      style={{
        transform: [{ translateY: -26 }],
      }}
      testID={tID('TicketsInfoPopover')}
      zIndex="1000"
    >
      <HStack alignItems="flex-start">
        <Text color="white" w="245px">
          {t('app_product_tab_tickets_popover')}
        </Text>
        <CPPopover.CloseButton
          _icon={{ color: 'white' }}
          onPress={(e) => {
            e.preventDefault();
            setHasCompletedTicketsInfo(true);
          }}
        />
      </HStack>
      <Box
        _web={{
          ml: '-12px',
        }}
        borderLeftColor="transparent"
        borderLeftWidth="12px"
        borderRightColor="transparent"
        borderRightWidth="12px"
        borderTopColor="brand.primary"
        borderTopWidth="12px"
        bottom="-10px"
        h="0"
        left="50%"
        position="absolute"
        w="0"
        zIndex="-1"
      />
    </Box>
  );
}
