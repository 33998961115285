import { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import useStore from 'app/hooks/useStore';

export default function useReserveFor() {
  const { email, setEmail, setEntitlementKey, user } = useStore(
    (s) => ({
      email: s.email,
      setEmail: s.setEmail,
      setEntitlementKey: s.setEntitlementKey,
      user: s.user,
    }),
    shallow
  );
  const { data: userData, remove: clearUserData, isFetching } = useGuestDataQuery();

  const isReservingFor = useMemo(
    () =>
      Boolean(
        user?.emailVerified &&
          email &&
          user?.email !== email &&
          userData?.guestData?.email === email
      ),
    [user, email, userData]
  );

  const cityPassUserEmail = useMemo(() => {
    return user && user.email?.endsWith('@citypass.com') && user.emailVerified ? user?.email : null;
  }, [user]);

  const isCityPassUser = cityPassUserEmail !== null;

  const clearReservingFor = useCallback(async () => {
    if (user?.emailVerified && user?.email) {
      setEmail(user.email);
      setEntitlementKey(null);
      clearUserData();
    }
  }, [clearUserData, setEmail, setEntitlementKey, user]);

  return {
    isFetching,
    reservingForEmail: isReservingFor ? email : undefined,
    reservationLoaded: isCityPassUser && isReservingFor,
    cityPassUserEmail,
    isReservingFor,
    isCityPassUser,
    clearReservingFor,
  };
}
