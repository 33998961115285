import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

const RATIO = 5.61;

export default withIconWrapper(function Stars({ color, size, ...props }) {
  return (
    <Svg
      fill={color}
      height={size}
      viewBox="0 0 144.256 25.688"
      width={size ? size * RATIO : size}
      {...props}
    >
      <Path d="M5.871 25.689a1.349 1.349 0 01-.79-.26 1.378 1.378 0 01-.532-1.337l1.279-7.6-5.422-5.378a1.387 1.387 0 01-.341-1.4 1.352 1.352 0 011.085-.928l7.494-1.11L11.998.765a1.332 1.332 0 012.407 0l3.352 6.911 7.5 1.11a1.344 1.344 0 011.082.928 1.387 1.387 0 01-.34 1.4l-5.423 5.378 1.282 7.6a1.38 1.38 0 01-.535 1.337 1.32 1.32 0 01-1.415.1l-6.7-3.587-6.7 3.587a1.311 1.311 0 01-.625.157M49.997 25.689a1.32 1.32 0 01-.625-.157l-6.7-3.587-6.705 3.587a1.322 1.322 0 01-1.413-.1 1.376 1.376 0 01-.534-1.337l1.282-7.6-5.426-5.379a1.393 1.393 0 01-.34-1.4 1.352 1.352 0 011.085-.928l7.5-1.11L41.473.767a1.332 1.332 0 012.407 0l3.351 6.911 7.5 1.11a1.353 1.353 0 011.085.928 1.387 1.387 0 01-.341 1.4l-5.424 5.379 1.28 7.6a1.38 1.38 0 01-.532 1.337 1.342 1.342 0 01-.79.26M79.458 25.689a1.311 1.311 0 01-.625-.157l-6.7-3.587-6.7 3.587a1.325 1.325 0 01-1.415-.1 1.379 1.379 0 01-.534-1.337l1.282-7.6-5.423-5.379a1.384 1.384 0 01-.34-1.4 1.344 1.344 0 011.082-.928l7.5-1.11L70.937.767a1.332 1.332 0 012.407 0l3.354 6.911 7.492 1.11a1.353 1.353 0 011.086.928 1.387 1.387 0 01-.341 1.4l-5.423 5.379 1.28 7.6a1.38 1.38 0 01-.532 1.337 1.348 1.348 0 01-.79.26M94.265 25.689a1.341 1.341 0 01-.79-.26 1.38 1.38 0 01-.532-1.337l1.28-7.6-5.424-5.378a1.387 1.387 0 01-.341-1.4 1.353 1.353 0 011.085-.928l7.494-1.11 3.354-6.911a1.33 1.33 0 012.4 0l3.355 6.911 7.5 1.11a1.343 1.343 0 011.082.928 1.384 1.384 0 01-.34 1.4l-5.423 5.378 1.282 7.6a1.385 1.385 0 01-.534 1.337 1.321 1.321 0 01-1.416.1l-6.7-3.587-6.7 3.587a1.32 1.32 0 01-.625.157M123.728 25.689a1.341 1.341 0 01-.79-.26 1.38 1.38 0 01-.532-1.337l1.28-7.6-5.424-5.378a1.387 1.387 0 01-.341-1.4 1.353 1.353 0 011.085-.928l7.494-1.11 3.354-6.911a1.33 1.33 0 012.4 0l3.355 6.911 7.5 1.11a1.343 1.343 0 011.082.928 1.384 1.384 0 01-.34 1.4l-5.423 5.378 1.282 7.6a1.385 1.385 0 01-.534 1.337 1.321 1.321 0 01-1.416.1l-6.7-3.587-6.7 3.587a1.32 1.32 0 01-.625.157" />
    </Svg>
  );
});
