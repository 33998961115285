import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import useGuest from 'app/hooks/useGuest';
import useStore from 'app/hooks/useStore';
import Logger from 'app/services/Logger';

const MODULE = '[useAutoEntitlementSwitcher]';

export default function useAutoEntitlementSwitcher() {
  const { entitlements } = useGuest();
  const {
    entitlementKey = null,
    setEntitlementKey,
    productContentKey,
  } = useStore(
    (s) => ({
      entitlementKey: s.entitlementKey,
      setEntitlementKey: s.setEntitlementKey,
      productContentKey: s.productId,
    }),
    shallow
  );
  const [productEntitlements, setProductEntitlements] = useState<string[]>([]);

  useEffect(() => {
    const entitlement = entitlements.find((ent) => ent.entitlementKey === entitlementKey);
    const needsToBeSwitched =
      !entitlementKey || entitlement?.productContentKey !== productContentKey;

    if (needsToBeSwitched) {
      const $productEntitlements = entitlements
        .filter((e) => e.productContentKey === productContentKey)
        .map((ent) => ent.entitlementKey);

      if ($productEntitlements[0] !== productEntitlements[0]) {
        setProductEntitlements($productEntitlements);
      }

      if ($productEntitlements.length > 1) {
        // would occur be when we have 2 travel parties with the same lineup
        Logger.debug(
          `${MODULE} cannot automatically switch entitlement due to multiple product entitlements`,
          { $productEntitlements }
        );
        setEntitlementKey(null);
      } else {
        const [nextEntitlementKey = null] = $productEntitlements;
        // do not overwrite the entlement key unless we have something valid to overwrite with
        if (nextEntitlementKey && nextEntitlementKey !== entitlementKey) {
          Logger.debug(`${MODULE} automatically switching entitlement`, {
            entitlementKey,
            nextEntitlementKey,
          });
          setEntitlementKey(nextEntitlementKey);
        }
      }
    }
  }, [entitlementKey, entitlements, productContentKey, productEntitlements, setEntitlementKey]);

  return {
    productEntitlements,
    mustChooseEntitlement: !entitlementKey && productEntitlements.length > 1,
  };
}
