import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import useStore, { VerificationState } from 'app/hooks/useStore';
import { RootStackParamList } from 'app/navigation/RootNavigator';

/**
 * Determines where to send users when they open the app.
 */
export default function useAppInitialRouteName(): keyof RootStackParamList {
  const { productId, verificationState, hasCompletedWelcome } = useStore(
    (s) => ({
      productId: s.productId,
      hasCompletedWelcome: s.hasCompletedWelcome,
      verificationState: s.verificationState,
    }),
    shallow
  );

  const initialRouteName: keyof RootStackParamList = useMemo(() => {
    if (!hasCompletedWelcome || verificationState === VerificationState.Pending) {
      return 'Authentication';
    }
    if (productId) return 'Tabs';
    return 'Destinations';
  }, [hasCompletedWelcome, productId, verificationState]);

  return initialRouteName;
}
