import * as React from 'react';
import Svg, { Ellipse, G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function MapPinOutline({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 11.08 16" width={size} {...props}>
      <G fill="none">
        <G>
          <Path
            d="M5.538 0a5.538 5.538 0 015.538 5.538c.178 4.346-5.663 10.752-5.538 10.452C5.336 16.094 0 10.472 0 5.538A5.538 5.538 0 015.538 0z"
            transform="translate(-118.199) translate(118.199)"
          />
          <Path
            d="M5.538 1.25A4.293 4.293 0 001.25 5.538c0 2.13 1.199 4.437 2.204 5.999a22.157 22.157 0 002.026 2.68 30.214 30.214 0 002.104-2.853c1.504-2.325 2.3-4.376 2.243-5.775v-.051A4.293 4.293 0 005.537 1.25m0-1.25a5.538 5.538 0 015.538 5.538c.178 4.346-5.663 10.752-5.538 10.452C5.336 16.094 0 10.472 0 5.538A5.538 5.538 0 015.538 0z"
            fill={color}
            transform="translate(-118.199) translate(118.199)"
          />
        </G>
        <G
          stroke={color}
          strokeWidth={1.25}
          transform="translate(-118.199) translate(120.573 2.373)"
        >
          <Ellipse cx={3.165} cy={3.165} rx={3.165} ry={3.165} stroke="none" />
          <Ellipse cx={3.165} cy={3.165} rx={2.54} ry={2.54} />
        </G>
      </G>
    </Svg>
  );
});
