import Constants from 'expo-constants';
import { Platform } from 'react-native';

import { IHeapAnalytics } from 'app/services/HeapAnalytics.types';
import Logger from 'app/services/Logger';

const HEAP_APP_ID = Constants.expoConfig?.extra?.heapAppId;
const HeapAnalytics = HeapAnalyticsFactory();

export default HeapAnalytics;

function HeapAnalyticsFactory(): IHeapAnalytics {
  Logger.debug(`[HeapAnalytics] appId ${HEAP_APP_ID}`);
  if (Constants.appOwnership === 'expo') {
    Logger.debug('[HeapAnalytics] loading stubbed sdk for expo go');
    return HeapAnalyticsStub();
  }
  if (Platform.OS === 'web') return HeapAnalyticsWeb();
  return HeapAnalyticsReactNative();
}

function HeapAnalyticsReactNative(): IHeapAnalytics {
  Logger.debug('[HeapAnalytics] loading react native sdk');
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const { default: RNHeap } = require('@heap/react-native-heap');
  const rnheap: Omit<IHeapAnalytics, 'init'> = RNHeap;

  return {
    ...rnheap,
    init: () => rnheap.setAppId(HEAP_APP_ID),
  };
}

/**
 * This is script loader directly from Heap with the exception of the
 * onload event we provide to merge the web SDK with our local one.
 */
function HeapAnalyticsWeb(): IHeapAnalytics {
  Logger.debug('[HeapAnalytics] loading web sdk');
  const mergeWebToLocalSDK = () => Object.assign(HeapAnalytics, window.heap);

  /* eslint-disable */
  window.heap = window.heap || [];
  window.heap.load = function (e: any, t: any) {
    ((window as any).heap.appid = e), ((window as any).heap.config = t = t || {});
    const r = document.createElement('script');

    // START CITYPASS CODE
    r.onload = mergeWebToLocalSDK;
    // END CITYPASS CODE

    r.type = 'text/javascript';
    r.async = !0;
    r.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`;
    const a = document.getElementsByTagName('script')[0];
    a.parentNode?.insertBefore(r, a);
    for (
      let n = function (e: any) {
        return function () {
          (window.heap as any).push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      },
      p = [
        'addEventProperties',
        'addUserProperties',
        'clearEventProperties',
        'identify',
        'resetIdentity',
        'removeEventProperty',
        'setEventProperties',
        'track',
        'unsetEventProperty',
      ],
      o = 0;
      o < p.length;
      o++
    ) (window.heap as any)[p[o]] = n(p[o]);
  };
  window.heap.load(HEAP_APP_ID);
  /* eslint-enable */

  return {
    ...HeapAnalyticsStub(),
    ...window.heap,
    getUserId: () => Promise.resolve(window.heap.userId || 'N/A'),
  };
}

function HeapAnalyticsStub(): IHeapAnalytics {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const stub = (...args: unknown[]) => {};

  return {
    init: stub,
    addEventProperties: stub,
    addUserProperties: stub,
    clearEventProperties: stub,
    getUserId: () => Promise.resolve('N/A'),
    identify: stub,
    removeEventProperty: stub,
    resetIdentity: stub,
    setAppId: stub,
    track: stub,
    withReactNavigationAutotrack: (Component) => Component,
  };
}
