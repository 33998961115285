import { CommonActions, useRoute } from '@react-navigation/native';
import noop from 'lodash/noop';
import { Toast, useToast, useToken } from 'native-base';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Keyboard } from 'react-native';
import { shallow } from 'zustand/shallow';

import useAuth from 'app/hooks/useAuth';
import useGuest from 'app/hooks/useGuest';
import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import { routeLink } from 'app/hooks/useLinkHandling';
import useRootNavigation from 'app/hooks/useRootNavigation';
import useStore from 'app/hooks/useStore';
import { AuthenticationStackParamList } from 'app/navigation/AuthenticationStackNavigator/AuthenticationStackNavigator';
import { AuthContextProps } from 'app/providers/AuthProvider/AuthProvider.types';
import { GuestContextProps } from 'app/providers/GuestProvider';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics from 'app/services/Analytics';
import { Events } from 'app/services/Analytics.types';
import Logger from 'app/services/Logger';
import { isValidAuthLink } from 'app/utils/validation';

const MODULE = '[AuthenticationNavProvider]';
const ROUTE_NAMES = [
  'AuthenticationStepEnterEmail',
  'AuthenticationStepVerifyEmail',
  'AuthenticationStepTicketLoader',
];

export type AuthenticationNavContext = {
  email: AuthContextProps['email'];
  isUnverified: AuthContextProps['isUnverified'];
  isPending: AuthContextProps['isPending'];
  isProcessing: AuthContextProps['isProcessing'];
  isVerified: AuthContextProps['isVerified'];
  hasResponse: GuestContextProps['hasResponse'];
  completedLoadTicketsAnimation: boolean;
  authCode: AuthContextProps['authCode'];

  verifyEmail: AuthContextProps['verifyEmail'];
  verifyCode: AuthContextProps['verifyCode'];
  handleExploreApp: () => void;
  handleManualSignInLink: (value: string) => void;
  handleVerifyEmail: (email: string) => void;
  handleResendVericationEmail: () => void;
  setCompletedLoadTicketsAnimation: (value: boolean) => void;
  setAuthCode: AuthContextProps['setAuthCode'];
};

const AuthNavContext = createContext<AuthenticationNavContext>({
  email: null,
  isUnverified: false,
  isPending: false,
  isProcessing: false,
  isVerified: false,
  hasResponse: false,
  completedLoadTicketsAnimation: false,
  verifyEmail: noop as unknown as AuthContextProps['verifyEmail'],
  handleExploreApp: noop,
  handleManualSignInLink: noop,
  handleVerifyEmail: noop,
  setCompletedLoadTicketsAnimation: noop,
  handleResendVericationEmail: noop,
  authCode: '',
  setAuthCode: noop,
  verifyCode: noop as unknown as AuthContextProps['verifyCode'],
});

export function useAuthenticationNav() {
  return useContext(AuthNavContext);
}

export type AuthenicationNavProviderProps = {
  children: ReactNode;
};

type TargetScreen =
  | keyof AuthenticationStackParamList
  | 'Purchases'
  | {
      name: 'Tabs';
      params: {
        screen: 'ProductStack';
        params: {
          screen: 'Product';
          params: { productId: string };
        };
      };
    };

export default function AuthenticationNavProvider({ children }: AuthenicationNavProviderProps) {
  const navigation = useRootNavigation();
  const route = useRoute();
  const toast = useToast();
  const { t, locale, setLocale } = useI18n();
  const { hasResponse, entitlements } = useGuest();
  const { remove: clearGuestData, refetch: refetchGuestData } = useGuestDataQuery({
    notifyOnChangeProps: ['status', 'error', 'remove', 'refetch'],
  });
  const { setEntitlementKey, setProduct } = useStore(
    (s) => ({
      setEntitlementKey: s.setEntitlementKey,
      setProduct: s.setProduct,
    }),
    shallow
  );
  const {
    email,
    user,
    isVerified,
    isProcessing,
    isPending,
    isUnverified,
    resetVerificationState,
    verificationMessage: message,
    setVerificationMessage: setMessage,
    verifyEmail,
    authCode,
    setAuthCode,
    verifyCode,
  } = useAuth();
  const errorColor = useToken('colors', 'brand.error');

  // local state
  const [completedLoadTicketsAnimation, setCompletedLoadTicketsAnimation] = useState(false);
  const targetScreenName = useMemo((): TargetScreen => {
    if (isProcessing) return 'AuthenticationStepTicketLoader';
    if (isVerified) {
      if (hasResponse && completedLoadTicketsAnimation) {
        if (entitlements.length === 1) {
          const { productContentKey } = entitlements[0];
          setEntitlementKey(entitlements[0].entitlementKey);
          setProduct(productContentKey);

          return {
            name: 'Tabs',
            params: {
              screen: 'ProductStack',
              params: {
                screen: 'Product',
                params: { productId: productContentKey },
              },
            },
          };
        }
        return 'Purchases';
      }
      return 'AuthenticationStepTicketLoader';
    }
    if (isPending) return 'AuthenticationStepVerifyEmail';
    if (isUnverified) return 'AuthenticationStepEnterEmail';
    return 'AuthenticationStepEnterEmail';
  }, [
    completedLoadTicketsAnimation,
    entitlements,
    hasResponse,
    isPending,
    isProcessing,
    isUnverified,
    isVerified,
    setEntitlementKey,
    setProduct,
  ]);

  // actions and handlers
  const handleResendVericationEmail = useCallback(async () => {
    Analytics.trackEvent(Events.AuthResendVerifyEmail);
    Logger.info(`${MODULE} resending verify`, {
      email,
      uid: user?.uid,
    });
    Toast.closeAll();
    resetVerificationState(email ?? undefined);
  }, [email, resetVerificationState, user?.uid]);

  const handleManualSignInLink = useCallback(
    (value: string) => {
      if (isValidAuthLink(value)) {
        routeLink({
          link: value,
          suppressAlerts: true,
          goToAuthScreen: false,
          toast: Toast,
          raiseToast: () => null,
          clearUserData: clearGuestData,
          locale,
          setLocale,
          setMessage,
          userDataRefetch: refetchGuestData,
          t,
        });
      }
    },
    [clearGuestData, locale, setLocale, setMessage, t, refetchGuestData]
  );

  const handleExploreApp = useCallback(() => {
    navigation.navigate('Destinations');
    Analytics.trackEvent(Events.AuthExploreAppLink);
  }, [navigation]);

  const handleVerifyEmail = useCallback(
    ($email: string) => {
      if ($email) {
        Analytics.trackEvent(Events.AuthEmailSubmitted);
        Keyboard.dismiss();
        toast.closeAll();
        verifyEmail.mutate(
          { email: $email },
          {
            onError: (error) => {
              const description = t('app_error_fetching_data');

              if (!toast.isActive(description)) {
                toast.show({
                  id: description,
                  description,
                  duration: 10000,
                  style: {
                    backgroundColor: errorColor,
                  },
                });
              }
              Logger.error(`${MODULE} verifyEmail.mutate.onError`, { error });
            },
            onSuccess: (res) => {
              if (res.success) {
                useStore.setState({ authCodeToken: res.codeToken });
              } else {
                throw new Error('unable to verify email', { cause: res });
              }
            },
          }
        );
      }
    },
    [errorColor, t, toast, verifyEmail]
  );

  // effects
  useEffect(
    () => navigateUsingAuthenticationState({ targetScreen: targetScreenName, navigation }),
    [navigation, targetScreenName]
  );

  useEffect(() => {
    if (!message) return;

    const id = JSON.stringify(message);
    if (toast.isActive(id)) return;

    toast.show({
      id,
      title: message.title,
      description: message.description,
      duration: message.status === 'error' ? 15000 : 5000,
      style: message.status === 'error' ? { backgroundColor: errorColor } : undefined,
    });
    setMessage(null);
  }, [errorColor, message, setMessage, toast]);

  useEffect(
    function handleSmartBannerLinks() {
      if (route && route.params) {
        const params = route.params as any;
        const orderToken = params?.order_token || params?.params?.order_token;
        if (orderToken) {
          routeLink({
            // link host doesnt matter just need order_token param
            link: `https://my.citypass.com/?order_token=${orderToken}`,
            suppressAlerts: false,
            goToAuthScreen: true,
            toast: Toast,
            raiseToast: (props) => Toast.show(props),
            clearUserData: clearGuestData,
            locale,
            setLocale,
            setMessage,
            userDataRefetch: refetchGuestData,
            t,
          });
          navigation.setParams({ order_token: undefined });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [route.params]
  );

  const context = useMemo(() => {
    return {
      email,
      isUnverified,
      isPending,
      isProcessing,
      isVerified,
      hasResponse,
      completedLoadTicketsAnimation,
      handleExploreApp,
      handleManualSignInLink,
      handleVerifyEmail,
      handleResendVericationEmail,
      setCompletedLoadTicketsAnimation,
      verifyEmail,
      authCode,
      setAuthCode,
      verifyCode,
    };
  }, [
    email,
    isUnverified,
    isPending,
    isProcessing,
    isVerified,
    hasResponse,
    completedLoadTicketsAnimation,
    handleExploreApp,
    handleManualSignInLink,
    handleVerifyEmail,
    handleResendVericationEmail,
    verifyEmail,
    authCode,
    setAuthCode,
    verifyCode,
  ]);

  return <AuthNavContext.Provider value={context}>{children}</AuthNavContext.Provider>;
}

function navigateUsingAuthenticationState({
  targetScreen,
  navigation,
}: {
  targetScreen: TargetScreen;
  navigation: ReturnType<typeof useRootNavigation>;
}) {
  const state = navigation.getState();
  const currentRoute = state.routes[state.index];

  if (currentRoute.name !== 'Authentication') {
    return;
  }

  if (typeof targetScreen === 'object') {
    // ensures the back button takes us to the correct place with the correct transition
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [targetScreen],
      })
    );
    return;
  }

  if (targetScreen === 'Purchases') {
    // ensures the back button on Purchases takes us to the Menu with the correct transition
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [{ name: 'Menu' }, { name: 'Purchases' }],
      })
    );
    return;
  }

  const currentScreen = currentRoute.state?.routes[currentRoute.state.index ?? 0];
  if (currentScreen?.name !== targetScreen) {
    const index = ROUTE_NAMES.findIndex((screen) => screen === targetScreen);
    const routes = ROUTE_NAMES.slice(0, index + 1).map((name) => ({ name }));

    Logger.debug('[AuthenticationStackNavigator] navigateUsingAuthenticationState', {
      to: {
        name: targetScreen,
        index,
        routes,
      },
      from: currentScreen,
    });

    const isGoingBack = currentScreen?.name === ROUTE_NAMES[index + 1];
    if (isGoingBack) {
      // ensures we just go back to the previous screen with the correct animation
      navigation.navigate('Authentication', { screen: targetScreen });
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: 'Authentication',
              params: { screen: targetScreen },
              state: {
                index,
                routeNames: ROUTE_NAMES,
                routes,
              },
            },
          ],
        })
      );
    }
  }
}
