import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function DiscountCoupon({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 23.747 19.248" width={size} {...props} fill="none">
      <Path
        d="M22.497 12.598v3.6a1.67 1.67 0 01-1.5 1.8H1.5a1.67 1.67 0 01-1.5-1.8v-3.6c1.657 0 3-1.612 3-3.6s-1.343-3.6-3-3.6v-3.6a1.67 1.67 0 011.5-1.8H21a1.67 1.67 0 011.5 1.8v3.6c-1.657 0-3 1.612-3 3.6s1.341 3.6 2.997 3.6z"
        stroke={color}
        transform="translate(.625 .625)"
      />
      <Path
        d="M5.815 5.4a1.8 1.8 0 101.8-1.8 1.8 1.8 0 00-1.8 1.8zM13.81 12.598a1.8 1.8 0 101.8-1.8 1.8 1.8 0 00-1.8 1.8zM15.749 3.553l-9 10.892"
        stroke={color}
        transform="translate(.625 .625)"
      />
    </Svg>
  );
});
