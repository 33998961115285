import { IPressableProps, Pressable } from 'native-base';
import React from 'react';
import { Share, ShareContent, ShareOptions } from 'react-native';

import ShareIcon from 'app/components/icons/Share';
import Logger from 'app/services/Logger';
import { tID } from 'app/services/TestHelper';

const MODULE = '[ShareButton]';

export type ShareButtonProps = IPressableProps & {
  content: ShareContent;
  options?: ShareOptions;
};

export default function ShareButton({ content, options, ...props }: ShareButtonProps) {
  return (
    <Pressable
      _pressed={{ opacity: 0.6 }}
      p="3"
      testID={tID('ShareButton')}
      onPress={async () => {
        try {
          const result = await Share.share(content, options);
          Logger.info(`${MODULE} share action`, { result });
        } catch (err) {
          Logger.error(`${MODULE} share failed`, { err });
        }
      }}
      {...props}
    >
      <ShareIcon color="white" size={20} />
    </Pressable>
  );
}
