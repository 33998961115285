import { Box, Button, Heading, Text, VStack } from 'native-base';

import Screen from 'app/components/Screen';
import { RootScreenProps, RootStackParamList } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';

type NotFoundScreenProps = RootScreenProps<RootStackParamList, 'NotFound'>;

export default function NotFoundScreen({ navigation }: NotFoundScreenProps) {
  const { t } = useI18n();

  return (
    <Screen contentContainerStyle={{ flex: 1, alignSelf: 'stretch' }}>
      <VStack alignItems="center" h="full" justifyContent="center" justifyItems="center" space="5">
        <Heading>{t('app_404_title')}</Heading>

        <Text>{t('app_404_description')}</Text>

        <VStack space={2}>
          <Button onPress={() => navigation.replace('Destinations')}>
            {t('app_404_go_to_destinations')}
          </Button>
          {navigation.canGoBack() ? (
            <Button variant="link" onPress={() => navigation.goBack()}>
              {t('app_404_go_back')}
            </Button>
          ) : (
            <Box />
          )}
        </VStack>
      </VStack>
    </Screen>
  );
}
