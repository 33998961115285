/* eslint-disable @typescript-eslint/no-var-requires */
const ThumbAtlanta = require('app/assets/images/product/thumb-atlanta.jpg');
const ThumbBoston = require('app/assets/images/product/thumb-boston.jpg');
const ThumbChicago = require('app/assets/images/product/thumb-chicago.jpg');
const ThumbChicagoC3 = require('app/assets/images/product/thumb-chicago-c3.jpg');
const ThumbDallas = require('app/assets/images/product/thumb-dallas.jpg');
const ThumbDenver = require('app/assets/images/product/thumb-denver.jpg');
const ThumbHouston = require('app/assets/images/product/thumb-houston.jpg');
const ThumbNewYork = require('app/assets/images/product/thumb-new-york.jpg');
const ThumbNewYorkCAll = require('app/assets/images/product/thumb-new-york-c-all.jpg');
const ThumbNewYorkC3 = require('app/assets/images/product/thumb-new-york-c3.jpg');
const ThumbOrlando = require('app/assets/images/product/thumb-orlando.jpg');
const ThumbPhiladelphia = require('app/assets/images/product/thumb-philadelphia.jpg');
const ThumbSanDiego = require('app/assets/images/product/thumb-san-diego.jpg');
const ThumbSanFrancisco = require('app/assets/images/product/thumb-san-francisco.jpg');
const ThumbSanFranciscoC3 = require('app/assets/images/product/thumb-san-francisco-c3.jpg');
const ThumbSeattle = require('app/assets/images/product/thumb-seattle.jpg');
const ThumbSeattleC3 = require('app/assets/images/product/thumb-seattle-c3.jpg');
const ThumbSouthernCalifornia = require('app/assets/images/product/thumb-southern-california.jpg');
const ThumbTampa = require('app/assets/images/product/thumb-tampa.jpg');
const ThumbToronto = require('app/assets/images/product/thumb-toronto.jpg');

const productThumbs: Record<string, number> = {
  atlanta: ThumbAtlanta,
  boston: ThumbBoston,
  chicago: ThumbChicago,
  'chicago-c3': ThumbChicagoC3,
  dallas: ThumbDallas,
  denver: ThumbDenver,
  houston: ThumbHouston,
  'new-york': ThumbNewYork,
  'new-york-c-all': ThumbNewYorkCAll,
  'new-york-c3': ThumbNewYorkC3,
  orlando: ThumbOrlando,
  philadelphia: ThumbPhiladelphia,
  'san-diego': ThumbSanDiego,
  'san-francisco': ThumbSanFrancisco,
  'san-francisco-c3': ThumbSanFranciscoC3,
  seattle: ThumbSeattle,
  'seattle-c3': ThumbSeattleC3,
  'southern-california': ThumbSouthernCalifornia,
  tampa: ThumbTampa,
  toronto: ThumbToronto,
};

export default productThumbs;
