import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function RestaurantForkSpoon({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 24 24" width={size} {...props}>
      <Path d="M7.5 24a.5.5 0 01-.5-.5V8.961C5.3 8.716 4 7.238 4 5.5v-5a.5.5 0 011 0v5a2.52 2.52 0 002 2.448V.5a.5.5 0 011 0v7.448A2.52 2.52 0 0010 5.5v-5a.5.5 0 011 0v5c0 1.738-1.3 3.216-3 3.461V23.5a.5.5 0 01-.5.5zM16.5 24a.5.5 0 01-.5-.5V8.961c-1.7-.245-3-1.723-3-3.461v-2C13 1.57 14.57 0 16.5 0S20 1.57 20 3.5v2c0 1.738-1.3 3.216-3 3.461V23.5a.5.5 0 01-.5.5zm0-23A2.503 2.503 0 0014 3.5v2C14 6.879 15.122 8 16.5 8S19 6.879 19 5.5v-2C19 2.121 17.878 1 16.5 1z" />
    </Svg>
  );
});
