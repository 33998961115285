import Constants from 'expo-constants';
import get from 'lodash/get';
import { useTheme } from 'native-base';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { MixedStyleDeclaration, RenderHTMLProps } from 'react-native-render-html';

import { useI18n } from 'app/providers/I18nProvider';
import { processElement } from 'app/utils/html';

const HTMLRenderContext = createContext<Omit<RenderHTMLProps, 'source'>>({});

export const useRenderHTMLContext = () => useContext(HTMLRenderContext);

/**
 * Using the explicit architecture and sharing the render engine and config props
 * should significantly improve performance.
 *
 * https://meliorence.github.io/react-native-render-html/docs/flow/rendering#composite-rendering-architecture
 * https://meliorence.github.io/react-native-render-html/api/trenderengineprovider
 */
export default function RenderHTMLProvider({ children }: { children?: ReactNode }) {
  const theme = useTheme();
  const { locale } = useI18n();

  const textStyles = useMemo(() => theme.components.Text.baseStyle(), [theme]);

  const baseStyle = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    const color = get(theme.colors, textStyles.color);
    const fontWeight = textStyles.fontWeight.toString();
    const fontSize = Number(get(theme.fontSizes, textStyles.fontSize));
    const rawLineHeight = get(theme.lineHeights, textStyles.lineHeight as string);
    const lineHeight = fontSize * Number(`${rawLineHeight}`.replace(/[^\d.]/g, ''));
    const fontFamily = get(
      theme.fontConfig[(theme.fonts as any).body as string],
      fontWeight
    )?.normal;

    return {
      color,
      fontFamily,
      fontSize,
      lineHeight,
    };
  }, [textStyles, theme]);

  const tagsStyles = useMemo(() => {
    const bold: MixedStyleDeclaration = {
      fontWeight: 'normal',
      fontFamily: get(theme.fontConfig[(theme.fonts as any).body as string], '700').normal,
    };
    return {
      a: {
        ...bold,
        color: theme.colors.black,
        textDecorationColor: theme.colors.black,
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
      } as MixedStyleDeclaration,
      b: bold,
      strong: bold,
      p: {
        marginTop: 0,
        marginBottom: 10,
      },
    };
  }, [theme.colors.black, theme.fontConfig, theme.fonts]);

  const systemFonts = useMemo(
    () => [...Constants.systemFonts, 'ProximaNova', 'ProximaNova-Bold'],
    []
  );

  const domVisitors = useMemo(
    () => ({
      onElement: (el: any) => processElement(el, locale),
    }),
    [locale]
  );

  const value = useMemo(
    () => ({
      baseStyle,
      tagsStyles,
      systemFonts,
      domVisitors,
    }),
    [baseStyle, domVisitors, systemFonts, tagsStyles]
  );

  return <HTMLRenderContext.Provider value={value}>{children}</HTMLRenderContext.Provider>;
}
