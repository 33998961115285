import { Box, Button, Heading, Text, VStack } from 'native-base';
import React, { Children, isValidElement, ReactNode } from 'react';

import HTML from 'app/components/HTML';
import { tID } from 'app/services/TestHelper';
import { hasValidChildren } from 'app/utils/validation';

export type AlertActionProps = {
  children?: ReactNode;
};

function AlertAction({ children }: AlertActionProps) {
  if (!hasValidChildren(children, getValidChildren())) {
    throw new Error(
      'AlertAction component only accepts AlertAction.Title, AlertAction.Content, AlertAction.Actions, and AlertAction.Footer as children'
    );
  }

  return (
    <Box
      borderColor="brand.border"
      borderRadius="md"
      borderWidth="2"
      px="4"
      py="6"
      testID={tID('AlertAction')}
    >
      <VStack alignItems="center" space="4">
        {children}
      </VStack>
    </Box>
  );
}

AlertAction.Title = function AlertActionTitle({ children }: { children: string }) {
  return <Heading size="md">{children.toString()}</Heading>;
};

AlertAction.Body = function AlertActionContent({ children }: { children: ReactNode }) {
  if (!children) return null;
  if (Children.toArray(children).every((child) => typeof child === 'string')) {
    return <HTML html={children.toString()} />;
  }
  return isValidElement(children) ? children : null;
};

AlertAction.Actions = function AlertActionActions({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  if (!hasValidChildren(children, [Button])) {
    throw new Error('AlertAction.Actions only accepts Button as children');
  }

  return <Button.Group w="full">{children}</Button.Group>;
};

AlertAction.Footer = function AlertActionFooter({ children }: { children: ReactNode }) {
  return <Text fontSize="xs">{children}</Text>;
};

export default AlertAction;

function getValidChildren() {
  return [AlertAction.Title, AlertAction.Body, AlertAction.Actions, AlertAction.Footer];
}
