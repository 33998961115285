import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Camera({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 19.25 14.25" width={size} {...props}>
      <G fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25}>
        <Path
          d="M18 11.556A1.473 1.473 0 0116.5 13h-15A1.473 1.473 0 010 11.556V2.889a1.473 1.473 0 011.5-1.444h5.535a.754.754 0 00.669-.394c.324-.614.642-1.05 1.473-1.05h3.394c.833 0 1.149.433 1.475 1.05a.754.754 0 00.669.394H16.5A1.473 1.473 0 0118 2.889z"
          data-name="Path 138440"
          transform="translate(.625 .4)"
        />
        <Path
          d="M7.5 7.222a3 3 0 103-2.889 2.946 2.946 0 00-3 2.889z"
          data-name="Path 138441"
          transform="translate(.625 .4)"
        />
        <Path d="M4.5 0v1.444" data-name="Path 138442" transform="translate(.625 .4)" />
      </G>
    </Svg>
  );
});
