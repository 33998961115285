import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Reservation2({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 17.25 17.25" width={size} {...props}>
      <G fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25}>
        <Path d="M0 8a8 8 0 108-8 8 8 0 00-8 8z" transform="translate(.625 .625)" />
        <Path d="M11.626 11.554L8.842 8.841" transform="translate(.625 .625)" />
        <Path
          d="M7.157 8.002A.843.843 0 108 7.16a.842.842 0 00-.843.842z"
          transform="translate(.625 .625)"
        />
        <Path d="M8 3.878v3.133" transform="translate(.625 .625)" />
      </G>
    </Svg>
  );
});
