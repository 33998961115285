import { Box, Center, IBoxProps } from 'native-base';
import React, { ReactNode } from 'react';

import ContentBlock, { ContentBlockProps } from 'app/components/ContentBlock';
import LeftQuote from 'app/components/icons/LeftQuote';
import RightQuote from 'app/components/icons/RightQuote';
import Stars from 'app/components/icons/Stars';
import { tID } from 'app/services/TestHelper';

const COLOR = 'white';
const QUOTE_SIZE = 46;

export type ProductReviewProps = ContentBlockProps;

export default function ProductReview(props: ProductReviewProps) {
  return (
    <Box bg="brand.primary" mx="2" px="4" py="2" testID={tID('ProductReview')}>
      <IconWrap>
        <LeftQuote color={COLOR} size={QUOTE_SIZE} />
      </IconWrap>

      <Box px="4">
        <ContentBlock {...props} />
      </Box>

      <Center mt="4">
        <IconWrap>
          <Stars color={COLOR} size={24} />
        </IconWrap>
      </Center>

      <IconWrap alignSelf="flex-end">
        <RightQuote color={COLOR} size={QUOTE_SIZE} />
      </IconWrap>
    </Box>
  );
}

function IconWrap({ children, ...props }: IBoxProps & { children: ReactNode }) {
  return (
    <Box opacity={0.5} {...props}>
      {children}
    </Box>
  );
}
