import dayjs from 'dayjs';
import Constants from 'expo-constants';
import { Button, Text } from 'native-base';
import React, { ReactNode, useMemo, useRef } from 'react';
import { Linking, Platform } from 'react-native';
import { shallow } from 'zustand/shallow';

import CPAlertDialog from 'app/components/CPAlertDialog/CPAlertDialog';
import useConfiguration from 'app/hooks/useConfiguration';
import useStore from 'app/hooks/useStore';
import { useI18n } from 'app/providers/I18nProvider';
import { tID } from 'app/services/TestHelper';
import { isVersionOutdated } from 'app/utils/version';

const NEXT_UPDATE_RECOMMEND_WEEKS = 1;

const appVersion = Constants.expoConfig?.version;
const appStoreUrl = Constants.expoConfig?.extra?.install?.appStoreURL;
const playStoreUrl = Constants.expoConfig?.extra?.install?.playStoreURL;

export type AppUpdateDialogProps = {
  children?: ReactNode;
};

export default function AppUpdateDialog({ children }: AppUpdateDialogProps) {
  const { t } = useI18n();

  const [nextUpdateRecommendTime, setNextUpdateRecommendTime] = useStore(
    (s) => [s.nextUpdateRecommendTime, s.setNextUpdateRecommendTime],
    shallow
  );

  const dialogRef = useRef();
  const { data, refetch, isFetching } = useConfiguration({
    staleTime: 1000 * 60 * 5,
    refetchOnMount: true,
    refetchOnReconnect: true,
    select: ({ minAppVersion, currentAppVersion, install }) => ({
      minAppVersion,
      currentAppVersion,
      installConfig: install,
    }),

    notifyOnChangeProps: ['data', 'isRefetching'],
  });

  const updatedRecommended = useMemo(() => {
    if (data?.currentAppVersion) {
      const shouldDisplay: boolean =
        nextUpdateRecommendTime === null || new Date(nextUpdateRecommendTime) <= new Date();

      return shouldDisplay && isVersionOutdated(appVersion, data?.currentAppVersion);
    }

    return false;
  }, [data?.currentAppVersion, nextUpdateRecommendTime]);

  const updatedRequired = useMemo(() => {
    return isVersionOutdated(appVersion, data?.minAppVersion);
  }, [data?.minAppVersion]);

  const handleUpdate = () => {
    if (Platform.OS === 'web') {
      window.location.reload();
      return;
    }
    Linking.openURL(
      Platform.select({
        ios: data?.installConfig?.appStoreUrl ?? appStoreUrl,
        android: data?.installConfig?.playStoreUrl ?? playStoreUrl,
      })
    );
  };

  const handleNextUpdateRecommendTime = () =>
    setNextUpdateRecommendTime(dayjs().add(NEXT_UPDATE_RECOMMEND_WEEKS, 'weeks').toISOString());

  if (updatedRequired) {
    return (
      <CPAlertDialog isOpen leastDestructiveRef={dialogRef} testID={tID('ForcedUpgrade')}>
        <CPAlertDialog.Content>
          <CPAlertDialog.Header>{t('app_forced_upgrade_title')}</CPAlertDialog.Header>
          <CPAlertDialog.Body>
            <Text textAlign="center">{t('app_forced_upgrade_body')}</Text>
          </CPAlertDialog.Body>
          <CPAlertDialog.Footer>
            <Button.Group>
              <Button testID={tID('ForcedUpgradeCTA')} onPress={handleUpdate}>
                {t('app_forced_upgrade_cta')}
              </Button>
              <Button
                isLoading={isFetching}
                testID={tID('ForcedUpgradeCheckCTA')}
                variant="link"
                onPress={() => refetch()}
              >
                {t('app_forced_upgrade_check_cta')}
              </Button>
            </Button.Group>
          </CPAlertDialog.Footer>
        </CPAlertDialog.Content>
      </CPAlertDialog>
    );
  }

  if (updatedRecommended) {
    return (
      <CPAlertDialog isOpen leastDestructiveRef={dialogRef} testID={tID('RecommendedUpgrade')}>
        <CPAlertDialog.Content>
          <CPAlertDialog.Header>{t('app_recommended_upgrade_title')}</CPAlertDialog.Header>
          <CPAlertDialog.Body>
            <Text textAlign="center">{t('app_recommended_upgrade_body')}</Text>
          </CPAlertDialog.Body>
          <CPAlertDialog.Footer>
            <Button.Group>
              <Button testID={tID('RecommendedUpgradeCTA')} onPress={handleUpdate}>
                {t('app_recommended_upgrade_cta')}
              </Button>
              <Button
                testID={tID('RecommendedUpgradeDeclineCTA')}
                variant="outline"
                onPress={handleNextUpdateRecommendTime}
              >
                {t('app_recommended_upgrade_decline_cta')}
              </Button>
            </Button.Group>
          </CPAlertDialog.Footer>
        </CPAlertDialog.Content>
      </CPAlertDialog>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
