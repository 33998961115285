import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/remote-config';

import Constants from 'expo-constants';
import firebase from 'firebase/compat/app'; // eslint-disable-line simple-import-sort/imports

import type { FirebaseService } from 'app/services/Firebase.types';
import Logger from 'app/services/Logger';

type FirebaseRemoteConfig = ReturnType<FirebaseService['remoteConfig']>;

const config = Constants.expoConfig?.web?.config?.firebase;
if (!config) throw new Error('missing required firebase web config');
const app =
  process.env.NODE_ENV === 'test'
    ? firebase
    : (firebase.apps && firebase.apps[0]) || firebase.initializeApp(config);

const service: FirebaseService = {
  analytics: () => app.analytics(),
  auth: () => app.auth(),
  crashlytics: (() => {
    Logger.error('[Firebase] crashlytics not available on web');
    return {};
  }) as FirebaseService['crashlytics'],
  dynamicLinks: (() => {
    Logger.error('[Firebase] dynamicLinks not available on web');
    return {};
  }) as FirebaseService['dynamicLinks'],
  remoteConfig: () => {
    const remoteConfig = app.remoteConfig();

    // web adapter
    if (!('setDefaults' in remoteConfig)) {
      const setDefaults: FirebaseRemoteConfig['setDefaults'] = (defaults) => {
        remoteConfig.defaultConfig = defaults;
        return Promise.resolve(null);
      };
      Object.defineProperty(remoteConfig, 'setDefaults', { value: setDefaults });
    }

    // web adapter
    if (!('setConfigSettings' in remoteConfig)) {
      const setConfigSettings: FirebaseRemoteConfig['setConfigSettings'] = (settings) => {
        Object.assign(remoteConfig.settings, settings);
        return Promise.resolve();
      };
      Object.defineProperty(remoteConfig, 'setConfigSettings', { value: setConfigSettings });
    }

    return remoteConfig as unknown as FirebaseRemoteConfig;
  },
};

export const { analytics } = service;
export const { auth } = service;
export const { crashlytics } = service;
export const { dynamicLinks } = service;
export const { remoteConfig } = service;

export default service;
