import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function ShoppingBagTag({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 24 24" width={size} {...props}>
      <Path d="M2.377 24c-.827 0-1.5-.673-1.5-1.5v-12c0-.827.673-1.5 1.5-1.5h2a.5.5 0 010 1h-2a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-12a.5.5 0 00-.5-.5h-2a.5.5 0 010-1h2c.827 0 1.5.673 1.5 1.5v12c0 .827-.673 1.5-1.5 1.5h-17z" />
      <Path d="M15.377 11a.5.5 0 01-.5-.5V5c0-2.206-1.794-4-4-4s-4 1.794-4 4v5.5a.5.5 0 01-1 0V5c0-2.757 2.243-5 5-5s5 2.243 5 5v5.5a.5.5 0 01-.5.5z" />
      <Path d="M8.377 10a.5.5 0 010-1h5a.5.5 0 010 1h-5zM7.877 20c-.551 0-1-.448-1-1v-5c0-.552.449-1 1-1h6a1 1 0 011 1v5a1 1 0 01-1 1h-6zm0-1h6v-5h-6v5zM17.377 8a.5.5 0 01-.5-.5V5.793c0-.395.16-.781.439-1.061l2.537-2.536a1.489 1.489 0 011.06-.439c.401 0 .778.156 1.061.439l.708.708a1.503 1.503 0 010 2.121L20.146 7.56c-.284.284-.661.44-1.061.44h-1.708zm1.708-1a.497.497 0 00.353-.146l2.536-2.535a.5.5 0 000-.707l-.708-.708a.5.5 0 00-.707 0L18.023 5.44a.503.503 0 00-.146.353V7h1.208z" />
    </Svg>
  );
});
