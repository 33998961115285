import {
  Flex,
  Heading,
  HStack,
  IFlexProps,
  IHeadingProps,
  IStackProps,
  ITextProps,
  Text,
  VStack,
} from 'native-base';
import React, { ReactNode } from 'react';

export const VERTICAL_PADDING = '5';

export type IconTitleProps = IFlexProps & {
  title: ReactNode;
  subtitle?: ReactNode;
  statusNode?: ReactNode;
  icon?: JSX.Element;
  children?: ReactNode;
  padded?: boolean;
  _title?: IHeadingProps;
  _subtitle?: ITextProps;
  _hstack?: IStackProps;
};

export default function IconTitle({
  title,
  subtitle,
  statusNode,
  icon,
  children,
  padded,
  _title,
  _subtitle,
  _hstack,
  ...props
}: IconTitleProps) {
  const titleComponent =
    typeof title === 'string' ? (
      <Heading size="sm" {..._title}>
        {title}
      </Heading>
    ) : (
      title
    );

  const subtitleComponent =
    typeof subtitle === 'string' ? <Text {..._subtitle}>{subtitle}</Text> : subtitle;

  return (
    <Flex
      alignItems={subtitle ? 'top' : 'center'}
      flexDirection="row"
      justifyContent="space-between"
      py={padded ? VERTICAL_PADDING : undefined}
      testID="IconTitle"
      {...props}
    >
      <HStack space="2" {..._hstack}>
        {icon}
        <VStack space={1}>
          {titleComponent}
          {subtitle && subtitleComponent}
        </VStack>
        {statusNode}
      </HStack>
      {children}
    </Flex>
  );
}
