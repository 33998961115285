import React from 'react';
import { SvgProps } from 'react-native-svg';

const DEFAULT_COLOR = '#000';
const DEFAULT_SIZE = 20;

export type IconComponent = ReturnType<typeof withIconWrapper>;

export type IconProps = SvgProps & {
  size?: number;
  color?: string;
};

export default function withIconWrapper(WrappedComponent: (props: IconProps) => JSX.Element) {
  return function Icon({ color = DEFAULT_COLOR, size = DEFAULT_SIZE, ...props }) {
    return <WrappedComponent color={color} size={size} {...props} />;
  };
}
