import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function ArrowCircle({ color, size, ...props }) {
  return (
    <Svg fill="none" height={size} viewBox="0 0 20 20" width={size} {...props}>
      <Path
        clipRule="evenodd"
        d="M.667 10a9.333 9.333 0 1118.666 0A9.333 9.333 0 01.667 10zm2.067-1h2.988a13.75 13.75 0 012.002-5.973A7.342 7.342 0 002.734 9zM10 3.234A11.75 11.75 0 007.732 9h4.536A11.75 11.75 0 0010 3.234zM12.268 11A11.75 11.75 0 0110 16.766 11.75 11.75 0 017.732 11h4.536zm-6.546 0H2.734a7.342 7.342 0 004.99 5.973A13.75 13.75 0 015.722 11zm6.555 5.973a13.75 13.75 0 002-5.973h2.989a7.342 7.342 0 01-4.99 5.973zM17.266 9h-2.988a13.75 13.75 0 00-2.001-5.973A7.342 7.342 0 0117.266 9z"
        fill={color}
        fillRule="evenodd"
      />
    </Svg>
  );
});
