import React from 'react';

import ContentBlock, { ContentBlockProps } from 'app/components/ContentBlock';
import useRootNavigation from 'app/hooks/useRootNavigation';
import { hasOffers } from 'app/services/ProductHelper';
import { tID } from 'app/services/TestHelper';

export type ProductOffersProps = ContentBlockProps;

export default function ProductOffers(props: ProductOffersProps) {
  const navigation = useRootNavigation();

  return (
    <ContentBlock
      {...props}
      precondition={() => hasOffers(props.productContent)}
      testID={tID('ProductOffers')}
      onCTAPress={() => {
        const params = { productId: props.productContent?.key ?? '' };
        navigation.navigate('Tabs', {
          screen: 'ProductStack',
          params: { screen: 'ProductOffers', params },
        });
      }}
    />
  );
}
