import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LeftQuote({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 46 32.262" width={size} {...props}>
      <Path
        d="M11.472 0a9.882 9.882 0 100 19.764 9.217 9.217 0 002.23-.283.5.5 0 01.552.736A13.658 13.658 0 012.5 27.262a2.5 2.5 0 100 5 19.417 19.417 0 0018.853-19.9V9.881A9.893 9.893 0 0011.472 0z"
        transform="rotate(180 23 16.131) translate(24.641)"
      />
      <Path
        d="M11.47 0a9.882 9.882 0 100 19.764 9.2 9.2 0 002.23-.283.5.5 0 01.554.736A13.658 13.658 0 012.5 27.262a2.5 2.5 0 100 5 19.419 19.419 0 0018.853-19.9V9.881A9.893 9.893 0 0011.47 0z"
        transform="rotate(180 23 16.131)"
      />
    </Svg>
  );
});
