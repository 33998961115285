import { Box } from 'native-base';
import React from 'react';

import ContentBlock, { ContentBlockProps } from 'app/components/ContentBlock';
import { hasBroadway } from 'app/services/ProductHelper';
import { tID } from 'app/services/TestHelper';

export type ProductBroadwayPromoProps = ContentBlockProps;

export default function ProductBroadwayPromo(props: ProductBroadwayPromoProps) {
  if (!hasBroadway(props.productContent)) {
    return <Box mb={-props.space} testID={tID('ProductBroadwayPromo')} />;
  }

  return (
    <Box testID={tID('ProductBroadwayPromo')}>
      <ContentBlock {...props} />
    </Box>
  );
}
