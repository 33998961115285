import axios from 'axios';
import { Box } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import type { RemoteSVGProps } from 'app/components/RemoteSVG';
import Logger from 'app/services/Logger';

/**
 * See RemoteSVG for companion component.
 */
export default function RemoteSVGWeb({
  uri,
  _web,
  color = 'black',
  fallback,
  style,
}: RemoteSVGProps) {
  const [svg, setSvg] = useState('');

  useEffect(() => {
    if (Platform.OS !== 'web') return;

    axios
      .get(uri)
      .then(({ data }) => setSvg(data.replace('<svg ', '<svg style="width:100%;height:100%" ')))
      .catch((error) => {
        Logger.warn('[RemoteSVGWeb] failed to load svg', { uri, error });
      });
  }, [uri]);

  if (Platform.OS !== 'web') return <Box />;
  if (fallback) {
    if (!svg || !uri) return fallback;
  }

  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: svg }}
      data-testid="RemoteSVG"
      style={{ display: 'flex', color, position: 'relative', height: '100%', ..._web }}
    />
  );
}
