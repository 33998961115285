import { useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import useAuth from 'app/hooks/useAuth';
import useGuest from 'app/hooks/useGuest';
import useStore from 'app/hooks/useStore';
import Analytics from 'app/services/Analytics';
import { UserProps } from 'app/services/Analytics.types';

type PartialUserProps = Partial<Record<UserProps, string | number | undefined>>;
/**
 * This hook is responsible for setting user properties in the Analytics service.
 */
export default function useAnalytics() {
  const { verificationState, isVerified } = useAuth();
  const { entitlements, entitlementsQtyCode } = useGuest();
  const [locale, orderAuthToken] = useStore((s) => [s.locale, s.orderAuthToken], shallow);

  const userProps: PartialUserProps = useMemo(() => {
    return {
      [UserProps.AuthTokenExists]: orderAuthToken ? 'true' : 'false',
      [UserProps.EntitlementsQtyCode]: isVerified ? entitlementsQtyCode : undefined,
      [UserProps.Language]: locale,
      [UserProps.LinkedTickets]: entitlements.length > 0 ? 'true' : 'false',
      [UserProps.TravelParties]: isVerified ? entitlements.length : undefined,
      [UserProps.VerificationState]: verificationState,
    };
  }, [
    entitlements.length,
    entitlementsQtyCode,
    isVerified,
    locale,
    orderAuthToken,
    verificationState,
  ]);

  useEffect(() => Analytics.setUserProperties(userProps), [userProps]);
}
