import React from 'react';

import ContentBlock, { ContentBlockProps } from 'app/components/ContentBlock';
import { useMessaging } from 'app/providers/MessagingProvider';
import { tID } from 'app/services/TestHelper';

export type ProductHelpProps = ContentBlockProps;

export default function ProductHelp(props: ProductHelpProps) {
  const { open } = useMessaging();
  return (
    <ContentBlock
      {...props}
      testID={tID('ProductLiveChat')}
      onCTAPress={() => {
        open();
      }}
    />
  );
}
