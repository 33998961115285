import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotNewYork({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M40.49 129.2V64.83h10.25l13.46 36.82h.18V64.83h9.99v64.37h-9.63L50.65 88.99h-.18v40.21h-9.99Zm63.66 0H93.09v-26.75L79.72 64.83h11.23l7.76 24.52h.18l7.4-24.52h11.23l-13.37 37.62v26.75Zm38.33-42.89v-4.99c0-5.44-2.5-7.76-5.35-7.76-4.46 0-5.88 2.32-5.88 7.13v32.63c0 4.81 1.43 7.13 5.88 7.13 4.01 0 5.35-2.32 5.35-6.51v-7.76h11.06v7.4c0 9.09-5.35 16.49-16.41 16.49s-16.94-7.4-16.94-16.49V80.41c0-9.09 5.35-16.49 16.94-16.49s16.41 8.02 16.41 17.65v4.73h-11.06Z"
        fill="#000"
      />
    </Svg>
  );
});
