import React from 'react';

import LocaleRadio from 'app/components/LocaleRadio';
import Screen from 'app/components/Screen';
import { RootScreenProps, RootStackParamList } from 'app/navigation/RootNavigator';

export type LanguageScreenProps = RootScreenProps<RootStackParamList, 'Language'>;

export default function LanguageScreen({ navigation, route }: LanguageScreenProps) {
  return (
    <Screen testID="LanguageScreen">
      <LocaleRadio />
    </Screen>
  );
}
