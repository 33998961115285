import * as React from 'react';
import Svg, { Circle, G, Path, Rect } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function PrivacyPolicy({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="-2.25 0 20.75 19.25" width={size} {...props}>
      <G transform="translate(.5 .5)">
        <Path
          d="M14.207 1.575h-4.3a2.628 2.628 0 00-4.815 0h-4.3a.789.789 0 00-.789.789v15a.789.789 0 00.789.789h13.415a.789.789 0 00.789-.789v-15a.789.789 0 00-.789-.789z"
          data-name="Path 155743"
          fill="none"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
        />
        <Path
          d="M5.525 0L0 0"
          data-name="Line 614"
          fill="none"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          transform="translate(4.736 6.31)"
        />
        <G
          data-name="Group 7491"
          fill="none"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          transform="translate(6.056 8.479)"
        >
          <Rect
            data-name="Rectangle 2572"
            height={5.259}
            rx={1}
            transform="translate(0 2.07)"
            width={6.574}
          />
          <Circle
            cx={0.848}
            cy={0.848}
            data-name="Ellipse 404"
            r={0.848}
            transform="translate(2.439 3.852)"
          />
          <Path
            d="M4.872 1.294A1.536 1.536 0 003.181 0a1.536 1.536 0 00-1.7 1.294v.776h3.391z"
            data-name="Path 155758"
          />
        </G>
        <Path
          d="M12.631 7.244V4.003H2.588V15.44h1.5"
          data-name="Path 155759"
          fill="none"
          stroke="#373737"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
        />
      </G>
    </Svg>
  );
});
