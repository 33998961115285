import dayjs from 'dayjs';
import Constants from 'expo-constants';
import { useMemo } from 'react';
import { Platform } from 'react-native';
import { shallow } from 'zustand/shallow';

import useStore from 'app/hooks/useStore';

const REPROMPT_TIME_WEEKS = 2;

function checkCanPrompt() {
  const isAndroidWeb =
    Platform.OS === 'web' &&
    typeof window !== 'undefined' &&
    window.navigator.userAgent.toLowerCase().includes('android');
  const hasStoreUrl = !!Constants.expoConfig?.extra?.install?.playStoreURL;

  return isAndroidWeb && hasStoreUrl;
}

export default function usePromptAppDownload() {
  const [nextAppDownloadPrompt, setNextAppDownloadPrompt] = useStore(
    (s) => [s.nextAppDownloadPrompt, s.setNextAppDownloadPrompt],
    shallow
  );

  const canPrompt = useMemo(() => {
    const currentDate = new Date();
    const promptCheck = checkCanPrompt();

    return promptCheck && (!nextAppDownloadPrompt || new Date(nextAppDownloadPrompt) < currentDate);
  }, [nextAppDownloadPrompt]);

  const markPrompted = () => {
    if (!canPrompt) return;

    setNextAppDownloadPrompt(dayjs().add(REPROMPT_TIME_WEEKS, 'weeks').toISOString());
  };

  return { canPrompt, markPrompted };
}
