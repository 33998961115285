import * as React from 'react';
import Svg, { Ellipse, G, Path, Rect } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Validity({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 15.955 17.25" width={size} {...props}>
      <G fill="none" stroke={color} strokeWidth={1.25} transform="translate(.625 .625)">
        <Rect
          height={14.41}
          rx={2}
          strokeLinejoin="round"
          transform="translate(0 1.59)"
          width={14.705}
        />
        <Path d="M0 0L14.583 0" strokeLinejoin="round" transform="translate(.028 4.284)" />
        <Path
          d="M0 0L0 2.531"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(10.466)"
        />
        <Path
          d="M0 0L0 2.531"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(3.893)"
        />
        <Ellipse cx={0.515} cy={0.508} rx={0.515} ry={0.508} transform="translate(3.084 6.642)" />
        <Ellipse cx={0.515} cy={0.508} rx={0.515} ry={0.508} transform="translate(6.834 6.642)" />
        <Ellipse cx={0.515} cy={0.508} rx={0.515} ry={0.508} transform="translate(10.589 6.642)" />
        <Ellipse cx={0.515} cy={0.508} rx={0.515} ry={0.508} transform="translate(3.084 9.62)" />
        <Ellipse cx={0.515} cy={0.508} rx={0.515} ry={0.508} transform="translate(6.834 9.62)" />
        <Ellipse cx={0.515} cy={0.508} rx={0.515} ry={0.508} transform="translate(10.588 9.62)" />
        <Ellipse cx={0.515} cy={0.508} rx={0.515} ry={0.508} transform="translate(3.084 12.618)" />
        <Ellipse cx={0.515} cy={0.508} rx={0.515} ry={0.508} transform="translate(6.834 12.618)" />
        <Ellipse cx={0.515} cy={0.508} rx={0.515} ry={0.508} transform="translate(10.589 12.618)" />
      </G>
    </Svg>
  );
});
