import { useNavigation } from '@react-navigation/native';
import React, { ReactNode } from 'react';

import ContentBlock from 'app/components/ContentBlock';
import useGuest from 'app/hooks/useGuest';
import useReservationTextTranslationHelper from 'app/hooks/useReservationTextTranslationHelper';
import { useProductContext } from 'app/providers/ProductProvider';
import Analytics, { Events } from 'app/services/Analytics';
import { ReservationTextHelper } from 'app/services/I18n';
import { getCityName } from 'app/services/ProductHelper';
import { tID } from 'app/services/TestHelper';

export type ProductIntroProps = {
  children?: ReactNode;
};

export default function ProductIntro({ children }: ProductIntroProps) {
  const navigation = useNavigation();
  const { productContent, defaultProductLineup } = useProductContext();
  const { activeEntitlement, hasActiveEntitlementFor } = useGuest();
  const rt = useReservationTextTranslationHelper({ product: productContent });

  const hasActiveEntitlement = hasActiveEntitlementFor(productContent.key);
  const msgProps = {
    product_name: productContent.name ?? '',
    city_name: getCityName(productContent),
    num_attractions:
      (hasActiveEntitlement
        ? activeEntitlement?.productLineup.maxVisits ?? defaultProductLineup?.maxVisits
        : defaultProductLineup?.maxVisits) ?? 0,
  };
  const productContentKey = productContent.key ?? '';
  const title = createText(
    hasActiveEntitlement ? 'product_intro_title' : 'product_intro_title_anonymous',
    rt,
    msgProps
  );
  const body = createText(
    hasActiveEntitlement ? 'product_intro_body' : 'product_intro_body_anonymous',
    rt,
    msgProps
  );
  const btn = createText(
    hasActiveEntitlement ? 'product_intro_btn' : 'product_intro_btn_anonymous',
    rt,
    msgProps
  );

  return (
    <ContentBlock
      body={body}
      cta={btn}
      id=""
      image={null}
      space={0}
      testID={tID('ProductIntro')}
      title={title}
      ui={[
        {
          __typename: 'BlockUI',
          align: 'left',
          fullWidth: false,
          colorScheme: 'dark',
          component: null,
          analyticsEvent: null,
          props: {
            _title: {
              color: 'white',
              shadow: 9,
              _web: {
                shadow: 'none',
                style: {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore: textShadow works on web
                  textShadow: '0 2px 10px rgba(0,0,0,.6)',
                },
              },
            },
          },
        },
      ]}
      url={`/product/${productContentKey}/attractions`}
      onCTAPress={() => {
        Analytics.trackEvent(Events.ProductTickets, {
          productKey: productContentKey,
        });
        navigation.navigate('Tabs', {
          screen: 'AttractionTicketsStack',
          params: { screen: 'AttractionTickets', params: { productId: productContentKey } },
        });
      }}
    />
  );
}

function createText(
  key: Parameters<ReservationTextHelper>[0],
  rt: ReservationTextHelper,
  props: Parameters<ReservationTextHelper>[1]
): { key: string; value: string } {
  return {
    key,
    value: rt(key, props),
  };
}
