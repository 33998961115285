export const fonts = {
  heading: 'ProximaNova-Bold',
  body: 'ProximaNova',
  mono: 'monospace',
};

export const fontConfig = {
  ProximaNova: {
    100: { normal: 'ProximaNova' },
    200: { normal: 'ProximaNova' },
    300: { normal: 'ProximaNova' },
    400: { normal: 'ProximaNova' },
    500: { normal: 'ProximaNova' },
    600: { normal: 'ProximaNova' },
    700: { normal: 'ProximaNova-Bold' },
    800: { normal: 'ProximaNova-Bold' },
    900: { normal: 'ProximaNova-Bold' },
  },
};

export const fontSizes = {
  '2xs': 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  '2xl': 24,
  '3xl': 30,
  '4xl': 36,
  '5xl': 48,
  '6xl': 60,
  '7xl': 72,
  '8xl': 96,
  '9xl': 128,
};
