import { HeaderBackButton } from '@react-navigation/elements';
import { Button, HStack, VStack } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';

import ContentBlockRenderer from 'app/components/ContentBlockRenderer';
import LinkTicketsPrompt from 'app/components/LinkTicketsPrompt';
import Loading from 'app/components/Loading';
import Screen from 'app/components/Screen';
import ShareButton from 'app/components/ShareButton';
import { useGetProductScreenBlocksQuery } from 'app/generated/hygraph';
import { getRouteParam } from 'app/navigation/navigationHelper';
import { ProductStackParamList } from 'app/navigation/ProductStackNavigator';
import { RootScreenProps } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import ProductProvider, { useProductContext } from 'app/providers/ProductProvider';
import ProductHero from 'app/screens/ProductScreen/ProductHero';
import ProductIntro from 'app/screens/ProductScreen/ProductIntro';
import ProductLogo from 'app/screens/ProductScreen/ProductLogo';
import HygraphService from 'app/services/HygraphService';
import { getUrl } from 'app/services/ProductHelper';

const MODULE = '[ProductScreen]';
const { client } = HygraphService();
export const PRODUCT_BLOCK_SPACE = 10;

export type ProductScreenProps = RootScreenProps<ProductStackParamList, 'Product'>;

export default function ProductScreen({ navigation, route }: ProductScreenProps) {
  const productContentKey = getRouteParam(route, 'productId') ?? '';

  // TODO are there react-navigation guards?
  if (!productContentKey) {
    navigation.navigate('Destinations');
    return null;
  }

  return (
    <ProductProvider productContentKey={productContentKey}>
      <ProductScreenBody navigation={navigation} route={route} />
    </ProductProvider>
  );
}

function ProductScreenBody({ navigation, route }: ProductScreenProps) {
  const { productId: productContentKey } = route.params;
  const { productContent } = useProductContext();
  const { locale, t } = useI18n();
  const { data: blocks = [], refetch } = useGetProductScreenBlocksQuery(
    client,
    { locale },
    { select: (data) => data.blockGroup?.blocks }
  );

  if (!productContent) return <Loading />;

  return (
    <Screen
      after={<LinkTicketsPrompt productContentKey={productContentKey} />}
      beforeContainer={
        <ProductHero
          beforeGradient={
            <>
              <HStack
                alignItems="center"
                justifyContent="space-between"
                position="absolute"
                top="2"
                w="full"
              >
                <HeaderBackButton
                  style={{ height: 48, width: 48 }}
                  tintColor="white"
                  onPress={() => navigation.navigate('Destinations')}
                />

                {Platform.OS !== 'web' && (
                  <ShareButton
                    accessibilityLabel={`Share ${productContent.name}`}
                    content={{
                      url: getUrl(productContent, locale),
                      title: productContent.name ?? '',
                    }}
                  />
                )}
              </HStack>
              <ProductLogo mb="12" mt="20" />
            </>
          }
          mb="8"
        >
          <ProductIntro />
        </ProductHero>
      }
    >
      <VStack space={PRODUCT_BLOCK_SPACE}>
        {blocks.map((block) => (
          <ContentBlockRenderer key={block.id} space={PRODUCT_BLOCK_SPACE} {...block} />
        ))}
      </VStack>

      {__DEV__ && (
        <Button my="16" onPress={() => refetch()}>
          Refresh Content Blocks
        </Button>
      )}
    </Screen>
  );
}

ProductScreen.routeName = 'Home';
