import { createNavigationContainerRef } from '@react-navigation/native';
import { Platform } from 'react-native';

import useRootNavigation from 'app/hooks/useRootNavigation';
import { RouteParam, routeParamUrlPatterns } from 'app/navigation/linking';
import { RootStackParamList } from 'app/navigation/RootNavigator';
import { isObject } from 'app/utils/guards';

export const navigationRef = createNavigationContainerRef<RootStackParamList>();

export function navigate(name: keyof RootStackParamList, props?: Record<string, unknown>) {
  if (navigationRef.isReady()) {
    // TODO: how to get this massive union type instead of using any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigationRef.navigate(name as any, props);
  }
}

export function getCurrentRoute(navigation: ReturnType<typeof useRootNavigation>) {
  const state = navigation.getState();
  return state.routes[state.routes.length - 1];
}

export function getCurrentRouteParams(navigation: ReturnType<typeof useRootNavigation>) {
  return getCurrentRoute(navigation).params as unknown as Maybe<Record<string, Maybe<string>>>;
}

type RouteLike = {
  params?: RouteLike;
  [key: string]: unknown;
};

function isRouteLike(route: unknown): route is RouteLike {
  return isObject(route);
}

export function getRouteParam(route: unknown, param: RouteParam): string | undefined {
  if (!isRouteLike(route)) return undefined;
  let ctx = route.params ?? undefined;

  while (isObject(ctx)) {
    if (ctx[param] && typeof ctx[param] === 'string') return ctx[param] as string;
    ctx = ctx.params;
  }

  // on web route params may or may not exist so we attempt to extract from the url
  if (Platform.OS === 'web' && typeof window !== 'undefined') {
    const url = window.location.href;
    const paramPattern = routeParamUrlPatterns[param];

    if (paramPattern) {
      const pathMatch = url.match(paramPattern);
      if (pathMatch) return pathMatch[1];
    }

    const queryMatch = url.match(new RegExp(`[?&]${param}=([\\w-]+)`));
    if (queryMatch) return queryMatch[1];
  }

  return undefined;
}
