import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function PhoneQR({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 14.616 23.693" width={size} {...props}>
      <G fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <Rect height={22.693} rx={0.875} width={13.616} x={0.5} y={0.5} />
        <Path d="M.5 19.033h13.616M3.526 6.174V4.661h1.513M8.065 4.66h3.026v3.026H8.065zM3.526 12.225h3.026v3.026H3.526zM3.526 9.199h3.026M8.821 10.712h2.269M11.091 13.738v1.513H8.822" />
      </G>
    </Svg>
  );
});
