import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function MyPurchases({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="-1.75 -2.75 20.75 19.25" width={size} {...props}>
      <G data-name="Subtraction 18" fill="none">
        <Path d="M16 13.307H1a1 1 0 01-1-1V8.87a2.361 2.361 0 001.639-.651 2.154 2.154 0 00.68-1.567A2.272 2.272 0 000 4.435V1a1 1 0 011-1h15a1 1 0 011 1v3.435a2.272 2.272 0 00-2.318 2.218A2.272 2.272 0 0017 8.87v3.436a1 1 0 01-1 1.001z" />
        <Path
          d="M16 12.307V9.72c-1.343-.412-2.318-1.632-2.318-3.068 0-1.436.976-2.655 2.318-3.068V1H1v2.584c1.343.413 2.318 1.632 2.318 3.069 0 .866-.35 1.68-.988 2.289a3.33 3.33 0 01-1.33.78v2.585h15m0 1H1c-.551 0-1-.449-1-1V8.87c.619 0 1.2-.231 1.639-.65.438-.42.68-.976.68-1.567 0-1.223-1.04-2.218-2.319-2.218V1c0-.551.449-1 1-1h15c.552 0 1 .449 1 1v3.435c-1.28.001-2.318.996-2.318 2.218 0 1.223 1.04 2.217 2.318 2.217v3.437c0 .551-.448 1-1 1z"
          fill="#373737"
        />
      </G>
    </Svg>
  );
});
