import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

const RATIO = 1.829;

export default withIconWrapper(function Ticket({ color, size = 24, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 74.663 40.835" width={size * RATIO} {...props}>
      <Path d="M73.663 40.835H1a1 1 0 01-1-1V27.223a7.238 7.238 0 005.029-2 6.613 6.613 0 002.086-4.809A6.974 6.974 0 000 13.612V1a1 1 0 011-1h72.663a1 1 0 011 1v12.612a6.973 6.973 0 00-7.115 6.806 6.973 6.973 0 007.115 6.806v12.611a1 1 0 01-1 1z" />
    </Svg>
  );
});
