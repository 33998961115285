import { Button, HStack, Spinner, Text, VStack } from 'native-base';
import React, { ReactNode } from 'react';
import { Platform } from 'react-native';

import useReserveFor from 'app/hooks/useReserveFor';

export type ReserveForGlobalHeaderProps = {
  children?: ReactNode;
};

export default function ReserveForGlobalHeader({ children }: ReserveForGlobalHeaderProps) {
  const {
    reservingForEmail,
    cityPassUserEmail,
    isReservingFor,
    isCityPassUser,
    clearReservingFor,
    isFetching,
    reservationLoaded,
  } = useReserveFor();

  if (Platform.OS !== 'web') return null;
  if (!isCityPassUser && !isReservingFor) return null;

  return (
    <VStack
      alignItems="center"
      bg="purple.600"
      justifyContent="center"
      p="1"
      space="1"
      testID="ReserveForGlobalHeader"
    >
      <Text bold color="white" fontSize="md" mb={0}>
        Logged in as {cityPassUserEmail}
      </Text>
      {reservationLoaded && (
        <HStack alignItems="center" fontSize="md" justifyContent="center" p="1" space="2">
          <Text bold color="white" fontSize="md">
            Reserving for {reservingForEmail}
          </Text>
          <Button fontSize="lg" px="3" py="0.5" variant="solidInverted" onPress={clearReservingFor}>
            Clear
          </Button>
        </HStack>
      )}
      {isFetching && (
        <HStack space="2">
          <Spinner color="white" />
          <Text bold color="white" fontSize="md">
            Retrieving user data...
          </Text>
        </HStack>
      )}
    </VStack>
  );
}
