import { Box, Button, Heading, Progress, Spinner, Text, VStack } from 'native-base';
import React, { useEffect } from 'react';

import { MyCitypassLogo } from 'app/components/icons';
import { DEFAULT_PADDING } from 'app/components/ResponsiveContainer';
import useAnimatedProgress from 'app/hooks/useAnimatedProgress';
import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import { AuthenticationStackParamList } from 'app/navigation/AuthenticationStackNavigator';
import { useAuthenticationNav } from 'app/navigation/AuthenticationStackNavigator/AuthenticationNavProvider';
import { RootScreenProps } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';

export type AuthenticationTicketLoaderScreenProps = RootScreenProps<
  AuthenticationStackParamList,
  'AuthenticationStepTicketLoader'
>;

export default function AuthenticationTicketLoaderScreen({
  navigation,
  route,
}: AuthenticationTicketLoaderScreenProps) {
  const { t } = useI18n();
  const {
    email,
    hasResponse,
    isVerified,
    completedLoadTicketsAnimation,
    setCompletedLoadTicketsAnimation,
  } = useAuthenticationNav();
  const { isError, isFetching, refetch } = useGuestDataQuery();
  const [progress, setTargetProgress] = useAnimatedProgress();
  const showError = isError && !isFetching;

  useEffect(() => {
    if (hasResponse) {
      setTargetProgress(100, () => {
        if (!completedLoadTicketsAnimation) setCompletedLoadTicketsAnimation(true);
      });
    } else if (isVerified) {
      setTargetProgress(50);
    }
  }, [
    completedLoadTicketsAnimation,
    hasResponse,
    isVerified,
    setCompletedLoadTicketsAnimation,
    setTargetProgress,
  ]);

  return (
    <VStack flexGrow="1" testID="AuthenticationTicketLoaderScreen">
      <Box alignItems="center" position="absolute" pt="16" w="full">
        <MyCitypassLogo size={260} />
      </Box>

      <VStack
        alignItems="center"
        flexGrow="1"
        justifyContent="center"
        mt="-12"
        p={DEFAULT_PADDING}
        space="2"
      >
        {showError ? (
          <Box w="full">
            <VStack
              alignItems="center"
              borderColor="brand.border"
              borderWidth="2"
              justifyContent="center"
              p="6"
              rounded="sm"
              space="3"
              width="full"
            >
              <Heading>{t('app_error_oops')}</Heading>
              <Text>{t('app_error_retrieving_tickets')}</Text>
              <Button onPress={() => refetch()}>{t('gen_try_again')}</Button>
            </VStack>
          </Box>
        ) : (
          <>
            <Spinner mb="6" size="lg" />

            {!!email && (
              <Text bold fontSize="lg" textAlign="center">
                {t('app_sign_in_signing_in_as')}
                {'\n'}
                {email}...
              </Text>
            )}

            <Box w="140px">
              <Progress colorScheme="secondary" mt="4" size="sm" value={progress} />
            </Box>
          </>
        )}
      </VStack>
    </VStack>
  );
}
