import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function ExternalLink({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="-4 -1 20.75 19.25" width={size} {...props}>
      <G
        data-name="external link"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      >
        <Path
          d="M3.731 1.24H.95a.9.9 0 00-.671.315 1.153 1.153 0 00-.278.761v8.608a1.153 1.153 0 00.278.761A.9.9 0 00.95 12h9.021a.9.9 0 00.672-.315 1.153 1.153 0 00.278-.761V8.467"
          data-name="Path 155761"
          transform="translate(.5 .663)"
        />
        <Path d="M12 .041l-5.952 5.98" data-name="Path 155762" transform="translate(.5 .663)" />
        <Path d="M12 4.958V-.004H6.811" data-name="Path 155763" transform="translate(.5 .663)" />
      </G>
    </Svg>
  );
});
