import { useToast } from 'native-base';
import React, { ReactNode, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { shallow } from 'zustand/shallow';

import BlurOverlay from 'app/components/BlurOverlay';
import Loading from 'app/components/Loading';
import { useGetContentQuery } from 'app/generated/hygraph';
import usePrevious from 'app/hooks/usePrevious';
import useStore from 'app/hooks/useStore';
import { useI18n } from 'app/providers/I18nProvider';
import HygraphService from 'app/services/HygraphService';
import { sleep } from 'app/utils/time';

const { client } = HygraphService();

export type LocaleChangeOverlayProps = {
  children: ReactNode;
};

export default function LocaleChangeOverlay({ children }: LocaleChangeOverlayProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { t } = useI18n();
  const [locale, setLocale] = useStore((s) => [s.locale, s.setLocale], shallow);
  const [prevLocale, setPrevLocale] = usePrevious(locale);
  const [changingLocale, setChangingLocale] = useState(false);

  // show overlay on locale change, if it fails then revert to previous locale
  useEffect(() => {
    if (prevLocale && locale !== prevLocale) {
      console.log('changing locale', locale, prevLocale);
      setChangingLocale(true);
      queryClient
        .fetchQuery({
          queryKey: useGetContentQuery.getKey({ locale }),
          queryFn: useGetContentQuery.fetcher(client, { locale }),
        })
        .then(() => sleep(500))
        .then(() => {
          setChangingLocale(false);
        })
        .catch(() => {
          toast.show({
            title: t('gen_error'),
            description: t('app_error_changing_language'),
          });
          setChangingLocale(false);
          setPrevLocale(prevLocale);
          setLocale(prevLocale);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <>
      {changingLocale && (
        <>
          <BlurOverlay zIndex={1} />
          <Loading
            bg="white"
            bottom="0"
            left="0"
            opacity={0.5}
            position="absolute"
            right="0"
            top="0"
            w="full"
            zIndex={1}
          />
        </>
      )}
      {children}
    </>
  );
}
