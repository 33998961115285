import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Minus({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 24 24" width={size} {...props}>
      <Path d="M1.5 13.5h21a1.5 1.5 0 000-3h-21a1.5 1.5 0 000 3z" />
    </Svg>
  );
});
