import { Platform, useWindowDimensions } from 'react-native';

import { MAX_WIDTH_WEB } from 'app/theme/breakpoints';

export default function useWindow() {
  const { width, height } = useWindowDimensions();

  return {
    width: Platform.OS === 'web' ? Math.min(MAX_WIDTH_WEB, width) : width,
    rawWidth: width,
    height,
  };
}
