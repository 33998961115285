import memoize from 'lodash/memoize';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import React from 'react';

import TextLink from 'app/components/TextLink';
import { AllProductPropsFragment, Locale, ReservationTextType } from 'app/generated/hygraph';
import { ProductContent } from 'app/hooks/useProduct';
import {
  AttractionGroup,
  Entitlement,
  EntitlementReservation,
  GroupType,
  LineupAttraction,
  Pass,
  ProductFamily,
  ProductLineup,
} from 'app/services/GuestCenterService.types';
import { ReservationTextHelper, TranslationHelper } from 'app/services/I18n';
import Logger from 'app/services/Logger';
import { getAttractionTickets } from 'app/services/PassHelper';
import { getCityName, getUrl } from 'app/services/ProductHelper';
import { hasUsage } from 'app/services/ReservationHelper';
import { listFormat } from 'app/utils/string';

const MODULE = '[ProductLineupHelper]';

type ProductContentAttractionLineup = AllProductPropsFragment['attractionLineups'][0];

type PassesReservations = { passes: Pass[]; reservations: EntitlementReservation[] };

export type AppProductLineup = Omit<ProductLineup, 'attractionGroups'> & {
  attractionGroups: AppAttractionGroup[];
  maxVisits: number;
  remainingVisits: number;
  attractionHasRemainingVisit(attractionKey: string): boolean;
};

export function createAppProductLineup({
  productLineup,
  productContentLineups,
  entitlement,
}: {
  productLineup: ProductLineup;
  productContentLineups: Maybe<ProductContentAttractionLineup[]>;
  entitlement: Maybe<PassesReservations>;
}): AppProductLineup {
  const productCode = getProductCode(entitlement);
  const sortedAttractionGroups = productContentLineups
    ? sortAttractionGroups({
        lineup: productLineup,
        contentLineup: productContentLineups[0],
      })
    : productLineup.attractionGroups;
  const attractionGroups = isAlacarte(productLineup)
    ? createAlacarteAttractionGroupDisplays({
        groups: sortedAttractionGroups,
        productLineup,
        entitlement,
      })
    : sortedAttractionGroups
        .filter((group) => groupHasEntitlementForProductCode(group, productCode))
        .map((group, index) =>
          createAppAttractionGroup({
            group,
            productLineup,
            entitlement,
            index,
          })
        );
  const summary = attractionGroups.reduce(
    (acc, group) => {
      acc.maxVisits += group.maxVisits;
      acc.remainingVisits += group.remainingVisits;
      return acc;
    },
    {
      remainingVisits: 0,
      maxVisits: 0,
    }
  );

  return {
    ...productLineup,
    ...summary,
    attractionGroups,

    attractionHasRemainingVisit(attractionKey: string) {
      if (summary.remainingVisits === 0) return false;

      return attractionGroups.some((group) =>
        group.cards.some((card) =>
          card.attractions.some((attraction) => {
            if (attraction.attractionKey === attractionKey) {
              return group.remainingVisits > 0 && !card.hasVisit && !attraction.hasVisit;
            }
            return false;
          })
        )
      );
    },
  };
}

export type AppAttractionGroup = AttractionGroup & {
  key: string;
  getTitle: (p: {
    rt: ReservationTextHelper;
    productContent: ProductContent;
  }) => string | undefined;
  getTitleSuffix: (p: {
    t: TranslationHelper;
    locale: Locale;
    productContent: ProductContent;
  }) => JSX.Element | undefined;
  cards: AppLineupAttractionCard[];
  maxVisits: number;
  remainingVisits: number;
};

function createAppAttractionGroup({
  group,
  productLineup,
  entitlement,
  index,
}: {
  group: AttractionGroup;
  productLineup: ProductLineup;
  entitlement: Maybe<PassesReservations>;
  index: number;
}): AppAttractionGroup {
  const cardAttractions: LineupAttraction[][] = showOneCardPerAttraction(group)
    ? group.attractions.map((attraction) => [attraction])
    : [group.attractions];
  const cards = cardAttractions.map((attractions) =>
    createAppLineupAttractionCard({
      attractions,
      entitlement,
    })
  );

  const productCode = entitlement?.passes?.[0]?.productCode;
  const groupEntitlement = group.entitlements.find((e) => e.productCode === productCode);
  const maxVisits =
    groupEntitlement?.maxVisits ?? Math.max(...group.entitlements.flatMap((ent) => ent.maxVisits));
  const remainingVisits = maxVisits - cards.reduce((acc, card) => acc + (card.hasVisit ? 1 : 0), 0);

  const appAttractionGroup = {
    ...group,
    key: JSON.stringify(group.attractions.map((a) => a.attractionKey)),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTitle: (props: {
      rt: ReservationTextHelper;
      productContent: ProductContent;
    }): string | undefined => undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTitleSuffix: (props: {
      t: TranslationHelper;
      locale: Locale;
      productContent: ProductContent;
    }): JSX.Element | undefined => undefined,
    cards,
    maxVisits,
    remainingVisits,
  };

  appAttractionGroup.getTitle = (props) =>
    getGroupTitle(appAttractionGroup, {
      rt: props.rt,
      lineup: productLineup,
      entitlement,
      isFirst: index === 0,
    });

  return appAttractionGroup;
}

export type AppLineupAttractionCard = ReturnType<typeof createAppLineupAttractionCard>;

function createAppLineupAttractionCard({
  attractions,
  entitlement,
}: {
  attractions: LineupAttraction[];
  entitlement: Maybe<PassesReservations>;
}) {
  const lineupAttractions = attractions.map((attraction) =>
    createAppLineupAttraction({
      attraction,
      entitlement,
    })
  );

  return {
    key: JSON.stringify(lineupAttractions.flatMap((a) => a.key)),
    hasReservation: lineupAttractions.some((a) => a.hasReservation),
    hasPastReservation: lineupAttractions.some((a) => a.hasPastReservation),
    hasVisit: lineupAttractions.some((a) => a.hasVisit),
    attractions: lineupAttractions,
  };
}

export type AppLineupAttraction = ReturnType<typeof createAppLineupAttraction>;

function createAppLineupAttraction({
  attraction,
  entitlement,
}: {
  attraction: LineupAttraction;
  entitlement: Maybe<PassesReservations>;
}) {
  const barcodes = getBarcodesFromPasses(entitlement?.passes);
  const reservations = getReservationsByAttractionKey(entitlement, attraction.attractionKey);
  const reservationBarcodes = getBarcodesFromReservations(reservations);
  const hasReservationForAllBarcodes =
    entitlement && barcodes.length && reservationBarcodes.length
      ? barcodes.sort().join(',') === reservationBarcodes.sort().join(',')
      : false;

  return {
    key: attraction.attractionKey,
    ...attraction,
    reservations,
    hasReservation: !!reservations && reservations.length > 0,
    hasPastReservation: !!reservations && reservations.length > 0 && hasUsage(reservations[0]),
    hasVisit: hasReservationForAllBarcodes,
  };
}

function getBarcodesFromPasses(passes: Maybe<Pass[]>) {
  return uniq(passes?.map((p) => p.barcode));
}

function getBarcodesFromReservations(reservations: Maybe<PassesReservations['reservations']>) {
  return uniq(reservations?.flatMap((r) => r.barcodes));
}

function getReservationsByAttractionKey(
  entitlement: Maybe<PassesReservations>,
  attractionKey: string
) {
  return entitlement?.reservations.filter((r) => r.attractionKey === attractionKey);
}

export function createAppProductLineupStub(props: Partial<ProductLineup> = {}): ProductLineup {
  return {
    productLineupKey: props.productLineupKey ?? 'not_found',
    attractionGroups: props.attractionGroups ?? [],
    cityCode: props.cityCode ?? 'not_found',
    familyCode: props.familyCode ?? ProductFamily.Core,
    contentKey: props.contentKey ?? 'not_found',
    isCurrent: props.isCurrent ?? false,
    productCodes: props.productCodes ?? [],
    year: props.year ?? 0,
    usageWindowDays: props.usageWindowDays ?? 0,
  };
}

function createAppAttractionGroupStub(props: Partial<AppAttractionGroup>): AppAttractionGroup {
  return {
    key: props.key ?? JSON.stringify(props.attractions?.flatMap((a) => a.attractionKey)),
    getTitle: props.getTitle ?? (() => undefined),
    getTitleSuffix: props.getTitleSuffix ?? (() => undefined),
    cards: props.cards ?? [],
    attractions: props.attractions ?? [],
    type: props.type ?? GroupType.Single,
    entitlements: props.entitlements ?? [],
    maxVisits: props.maxVisits ?? 0,
    remainingVisits: props.remainingVisits ?? 0,
  };
}

/**
 * Maps alacarte attraction lineup groups to purchased and unpurchased groups.
 */
function createAlacarteAttractionGroupDisplays({
  groups,
  productLineup,
  entitlement,
}: {
  groups: AttractionGroup[];
  productLineup: ProductLineup;
  entitlement: Maybe<PassesReservations>;
}) {
  const purchasedAttractionsKeys = getAttractionTickets(entitlement?.passes);
  const { purchased, unpurchased } = groups.reduce<
    Record<'purchased' | 'unpurchased', AttractionGroup[]>
  >(
    (acc, group) => {
      const wasPurchased = group.attractions.some((a) =>
        purchasedAttractionsKeys.includes(a.attractionKey)
      );

      if (wasPurchased) {
        acc.purchased.push(group);
      } else {
        acc.unpurchased.push(group);
      }

      return acc;
    },
    {
      purchased: [],
      unpurchased: [],
    }
  );

  return [
    ...purchased.map((group) =>
      createAppAttractionGroupStub({
        maxVisits: 1,
        remainingVisits: 1,
        attractions: group.attractions,
        cards: [
          createAppLineupAttractionCard({
            attractions: group.attractions,
            entitlement,
          }),
        ],
      })
    ),
    {
      ...createAppAttractionGroupStub({
        maxVisits: unpurchased.length,
        remainingVisits: unpurchased.length,
        getTitle({
          rt,
          productContent,
        }: {
          rt: ReservationTextHelper;
          productContent: ProductContent;
        }) {
          if (!entitlement) return '';
          return getText('lineup_details_more_alacarte', {
            rt,
            lineup: productLineup,
            hasEntitlement: !!entitlement,
            locals: {
              city_name: getCityName(productContent),
              product_url: getUrl(productContent),
            },
          });
        },
        attractions: unpurchased.flatMap((group) => group.attractions),
        cards: unpurchased.flatMap((group) =>
          group.attractions.map((attraction) =>
            createAppLineupAttractionCard({
              attractions: [attraction],
              entitlement: null,
            })
          )
        ),
      }),
      getTitleSuffix({
        t,
        locale,
        productContent,
      }: {
        t: TranslationHelper;
        locale: Locale;
        productContent: ProductContent;
      }) {
        return (
          <TextLink color="white" url={getUrl(productContent, locale)}>
            {t('gen_buy_now')}
          </TextLink>
        );
      },
    },
  ];
}

export function getFamilyCode(lineup: Maybe<ProductLineup>) {
  return lineup?.familyCode;
}

export function showValidity(lineup: Maybe<ProductLineup>) {
  return lineup?.familyCode !== ProductFamily.Alacarte;
}

function getProductCode(entitlement: Maybe<{ passes: Pass[] }>) {
  return entitlement?.passes[0]?.productCode;
}

function groupHasEntitlementForProductCode(group: AttractionGroup, productCode: Maybe<string>) {
  if (!productCode) return true;
  return group.entitlements.some((ent) => ent.productCode === productCode);
}

function getAttractionCountLabel(
  lineup: Maybe<AppProductLineup>,
  { productCode, locale }: { productCode?: Maybe<string>; locale: Locale }
) {
  if (!lineup) return 0;

  const shouldUseProductCodeEntitlementRange =
    lineup.familyCode === ProductFamily.CTicket && !productCode && lineup.productCodes?.length;

  return shouldUseProductCodeEntitlementRange
    ? listFormat(getMaxVisitsForEachEntitlementProductCode(lineup), {
        locale,
        type: 'or',
      })
    : lineup.maxVisits;
}

function getMaxVisitsForEachEntitlementProductCode(lineup: Maybe<AppProductLineup>) {
  if (!lineup) return [];

  const entMaxVisits = lineup?.attractionGroups.reduce<Record<string, number>>((acc, group) => {
    group.entitlements.forEach((ent) => {
      if (ent.productCode) {
        acc[ent.productCode] = acc[ent.productCode] ?? 0;
        acc[ent.productCode] += ent.maxVisits ?? 0;
      }
    });
    return acc;
  }, {});

  return uniq(Object.values(entMaxVisits))
    .sort()
    .map((v) => `${v}`);
}

export function isAlacarte(lineup: Maybe<ProductLineup>) {
  return lineup?.familyCode === ProductFamily.Alacarte;
}

export function isCAll(productCode?: Maybe<string>) {
  return /^c-all$/i.test(productCode ?? '');
}

function showOneCardPerAttraction(group: AttractionGroup) {
  return [GroupType.Choice, GroupType.Cticket].includes(group.type as GroupType);
}

export function getTitle(
  lineup: AppProductLineup,
  {
    product,
    locale,
    ...props
  }: {
    rt: ReservationTextHelper;
    entitlement: Maybe<Entitlement>;
    product: ProductContent;
    locale: Locale;
  }
) {
  return getText('lineup_title', {
    lineup,
    ...props,
    hasEntitlement: !!props.entitlement,
    locals: {
      product_name: product.name,
      city_name: product.cityName?.value,
      count: getAttractionCountLabel(lineup, {
        productCode: getProductCode(props.entitlement),
        locale,
      }),
    },
  });
}

export function getValidity(
  lineup: ProductLineup,
  props: {
    rt: ReservationTextHelper;
    product: ProductContent;
    entitlement: Maybe<Entitlement>;
  }
) {
  return getText('lineup_validity', {
    lineup,
    ...props,
    hasEntitlement: !!props.entitlement,
    locals: {
      valid_days: props.product.validDays,
    },
  });
}

export function getValidityLong(
  lineup: ProductLineup,
  props: {
    rt: ReservationTextHelper;
    product: ProductContent;
    entitlement: Maybe<Entitlement>;
  }
) {
  return getText('lineup_validity_long', {
    lineup,
    ...props,
    hasEntitlement: !!props.entitlement,
    locals: {
      valid_days: props.product.validDays,
    },
  });
}

export function getInstructions(
  lineup: AppProductLineup,
  props: {
    rt: ReservationTextHelper;
    product: ProductContent;
    entitlement: Maybe<Entitlement>;
  }
) {
  return getText('lineup_instructions', {
    lineup,
    ...props,
    hasEntitlement: !!props.entitlement,
    locals: {},
  });
}

export function getDetailsTitle(
  lineup: AppProductLineup,
  {
    rt,
    entitlement,
    locale,
  }: {
    rt: ReservationTextHelper;
    entitlement: Maybe<Entitlement>;
    locale: Locale;
  }
): string | undefined {
  // ex: core single/split products only
  if (
    lineup.familyCode === ProductFamily.Core &&
    lineup.attractionGroups.every((group) =>
      [GroupType.Single, GroupType.Split].includes(group.type as GroupType)
    )
  ) {
    return undefined;
  }

  const count = getAttractionCountLabel(lineup, {
    productCode: getProductCode(entitlement),
    locale,
  });
  // ex: new-york-c-all
  const forceCoreMessage =
    lineup.familyCode === ProductFamily.CTicket &&
    Number(count) === lineup.attractionGroups[0]?.attractions.length;

  return getText('lineup_details', {
    rt,
    lineup: {
      ...lineup,
      familyCode: forceCoreMessage ? ProductFamily.Core : lineup.familyCode,
    },
    hasEntitlement: !!entitlement,
    locals: { count },
  });
}

function getGroupTitle(
  group: AppAttractionGroup,
  {
    rt,
    lineup,
    entitlement,
    isFirst,
  }: {
    rt: ReservationTextHelper;
    lineup: ProductLineup;
    entitlement: Maybe<PassesReservations>;
    isFirst: boolean;
  }
) {
  if (!isFirst && [GroupType.Choice, GroupType.Cticket].includes(group.type as GroupType)) {
    return getText(`lineup_details_more_${group.type}`, {
      rt,
      lineup,
      hasEntitlement: !!entitlement,
      locals: {
        count: group.maxVisits,
      },
    });
  }
  return undefined;
}

function sortAttractionGroups({
  lineup,
  contentLineup,
}: {
  lineup: Maybe<ProductLineup>;
  contentLineup: ProductContentAttractionLineup | undefined;
}) {
  const attractionSortOrder = getAttractionSortOrder(contentLineup);
  const groupsWithSortedAttractions = lineup?.attractionGroups.map((group) => ({
    ...group,
    attractions: sortBy(group.attractions, (attr) => indexOf(attr.contentKey, attractionSortOrder)),
  }));
  const sortedGroups = sortBy(groupsWithSortedAttractions, (group) =>
    indexOf(group.attractions?.[0]?.contentKey, attractionSortOrder)
  );

  return sortedGroups;
}

function getAttractionSortOrder(lineup?: ProductContentAttractionLineup): string[] {
  if (!lineup) return [];
  return lineup.attractionGroups.flatMap((group) => group.attractions.map((a) => a.key));
}

function indexOf<T>(item: T, list: T[]) {
  const idx = list.indexOf(item);
  return idx === -1 ? Infinity : idx;
}

export const getDefaultProductLineup = memoize(
  ({
    productLineups = [],
    productContentKey,
  }: {
    productLineups: Maybe<ProductLineup[]>;
    productContentKey: string;
  }) => {
    if (!productLineups) return createAppProductLineupStub();
    const cityLineups = productLineups.filter((pl) => pl.contentKey === productContentKey);
    const currentLineup = cityLineups.find((pl) => pl.isCurrent);
    if (currentLineup) return currentLineup;

    Logger.warn(`${MODULE} no current lineup found for product`, {
      productContentKey,
      productLineupKeys: cityLineups.map((pl) => pl.productLineupKey),
    });

    const newestLineup = orderBy(cityLineups, ['year', 'productLineupKey'], ['desc'])[0];
    if (newestLineup) return newestLineup;

    Logger.error(`${MODULE} no lineup found for product`, { productContentKey });
    return createAppProductLineupStub();
  },
  (...args) => JSON.stringify(args)
);

/**
 * Get text with fallback: product > product family > general and anonymous > auth.
 * @param key
 * @param param1
 * @returns
 */
function getText(
  key: string,
  {
    rt,
    lineup,
    hasEntitlement = false,
    locals,
  }: {
    rt: ReservationTextHelper;
    lineup: ProductLineup;
    hasEntitlement: boolean;
    locals: { [k: string]: Maybe<string | number> };
  }
): string {
  const suffix = hasEntitlement ? '' : '_anonymous';
  const msg =
    // product level override without fallback to general (ex: lineup_title on San Diego)
    rt(`${key}${suffix}` as ReservationTextType, { fallback: false, ...locals }) ||
    // general level by product family (ex: lineup_title_cticket)
    rt(`${key}_${lineup?.familyCode}${suffix}` as ReservationTextType, locals) ||
    // general level (ex: lineup_title)
    rt(`${key}${suffix}` as ReservationTextType, locals);

  if (!msg && !hasEntitlement) {
    return getText(key, { rt, lineup, hasEntitlement: true, locals });
  }

  return msg;
}
