import { AlertDialog } from 'native-base';
import React from 'react';

import BlurOverlay from 'app/components/BlurOverlay';
import { tID } from 'app/services/TestHelper';

export type CPAlertDialogProps = Parameters<typeof AlertDialog>[0];

function CPAlertDialog({ children, ...props }: CPAlertDialogProps) {
  return (
    <AlertDialog testID={tID('CPAlertDialog')} {...props}>
      <BlurOverlay onPress={props.closeOnOverlayClick ? props.onClose : undefined} />
      {children}
    </AlertDialog>
  );
}

CPAlertDialog.Body = AlertDialog.Body;
CPAlertDialog.CloseButton = AlertDialog.CloseButton;
CPAlertDialog.Content = AlertDialog.Content;
CPAlertDialog.Footer = AlertDialog.Footer;
CPAlertDialog.Header = AlertDialog.Header;

export default CPAlertDialog;
