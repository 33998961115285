import Constants from 'expo-constants';
import * as Device from 'expo-device';
import { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Platform } from 'react-native';

import useAuth from 'app/hooks/useAuth';
import useGuest from 'app/hooks/useGuest';
import { useI18n } from 'app/providers/I18nProvider';
import Logger from 'app/services/Logger';
import ZendeskMessagingService, { ZendeskLanguage } from 'app/services/ZendeskMessagingService';

const MODULE = '[useMessaging]';

const MessagingContext = createContext({
  ready: false,
  unreadCount: 0,
  open: () => {},
});

export const useMessaging = () => useContext(MessagingContext);

/**
 * There is logic for web that show/hides the zendesk chat widget laucher button. See web/index.html for more details.
 */
export default function MessagingProvider({ children }: { children: ReactNode }) {
  const { verificationState, user } = useAuth();
  const { activeEntitlement, entitlements, email } = useGuest();
  const { locale } = useI18n();

  const [{ ready, unreadCount }, setState] = useState({
    ready: false,
    unreadCount: 0,
  });

  const unreadSubscription = useRef<ReturnType<typeof ZendeskMessagingService.onUnreadMessages>>();

  const context = useMemo(() => {
    return {
      ready,
      unreadCount,
      open() {
        if (!ready) return;
        setState((s) => ({ ...s, unreadCount: 0 }));

        ZendeskMessagingService.openMessaging();
        ZendeskMessagingService.setConversationTags(['mycitypass']);
        ZendeskMessagingService.setConversationFields({
          // user
          Email: email,
          UserId: user?.uid,
          VerificationState: verificationState,
          OrderNumber: activeEntitlement?.travelParty?.orderNumber,
          TravelPartyCount: entitlements.length.toString(),
          PurchasedProduct: activeEntitlement?.zendeskProductKey,
          Language: ZendeskLanguage[locale] ?? ZendeskLanguage.en,

          // device
          AppVersion: Constants.expoConfig?.version ?? 'UNKNOWN',
          SessionId: Constants.sessionId,
          Platform: Platform.OS,
          Device: Device.DeviceType[Device.deviceType ?? 0] ?? 'UNKNOWN',
          OS: `${Device.osName} ${Device.osVersion}`,
        });
      },
      createUnread() {
        setState((s) => ({ ...s, unreadCount: s.unreadCount + 1 }));
      },
    };
  }, [
    activeEntitlement?.travelParty?.orderNumber,
    activeEntitlement?.zendeskProductKey,
    email,
    entitlements.length,
    locale,
    ready,
    unreadCount,
    user?.uid,
    verificationState,
  ]);

  useEffect(() => {
    ZendeskMessagingService.init().then(() => {
      setState((s) => ({ ...s, ready: true }));

      unreadSubscription.current = ZendeskMessagingService.onUnreadMessages((payload) => {
        setState((s) => ({ ...s, unreadCount: payload.unreadCount }));
      });

      Logger.debug(`${MODULE} zendesk sdk initialized`);
    });

    return () => {
      unreadSubscription.current?.remove();
    };
  }, []);

  return <MessagingContext.Provider value={context}>{children}</MessagingContext.Provider>;
}
