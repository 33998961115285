import { Box, Heading, Text, VStack } from 'native-base';
import React, { useMemo, useState } from 'react';

import EntitlementCard from 'app/components/EntitlementsList/EntitlementCard';
import TextLink from 'app/components/TextLink';
import useGuest from 'app/hooks/useGuest';
import useStore from 'app/hooks/useStore';
import { useI18n } from 'app/providers/I18nProvider';
import { Entitlement } from 'app/services/GuestCenterService.types';
import { sleep } from 'app/utils/time';

export type EntitlementsListProps = {
  onChange?: (entitlementKey: string) => void;
  filter?: (entitlement: Entitlement) => boolean;
  showOnlyValid?: boolean;
  showOrderDetails?: boolean;
  onLinkTickets?: () => void;
};

export default function EntitlementsList({
  onChange,
  filter,
  showOnlyValid = false,
  showOrderDetails = true,
  onLinkTickets,
}: EntitlementsListProps) {
  const { t } = useI18n();
  const { entitlements, allEntitlements, activeEntitlement } = useGuest();
  const { setEntitlementKey, setProduct } = useStore((s) => ({
    entitlementKey: s.entitlementKey,
    productId: s.productId,
    setEntitlementKey: s.setEntitlementKey,
    setProduct: s.setProduct,
  }));
  // for optimistic UI updates
  const [uiActiveEntitlementKey, setUiActiveEntitlementKey] = useState(
    activeEntitlement?.entitlementKey
  );

  const { validEntitlements, invalidEntitlements } = useMemo(() => {
    return allEntitlements.filter(filter ?? Boolean).reduce<{
      validEntitlements: Entitlement[];
      invalidEntitlements: Entitlement[];
    }>(
      (acc, ent) => {
        if (ent.isValid) {
          acc.validEntitlements.push(ent);
        } else {
          acc.invalidEntitlements.push(ent);
        }
        return acc;
      },
      { validEntitlements: [], invalidEntitlements: [] }
    );
  }, [allEntitlements, filter]);
  const hasInvalidEntitlements = invalidEntitlements.length > 0;

  const handleSelect = async (newEntitlementKey: string) => {
    setUiActiveEntitlementKey(newEntitlementKey);

    // let the ui refresh
    await sleep(1);

    const newEntitlement = entitlements.find((ent) => ent.entitlementKey === newEntitlementKey);
    setProduct(newEntitlement?.productContentKey ?? null);
    setEntitlementKey(newEntitlementKey);

    if (onChange) onChange(newEntitlementKey);
  };
  const handleLinkTickets = () => {
    if (onLinkTickets) onLinkTickets();
  };

  return (
    <VStack
      space="3"
      testID={`EntitlementsListWith${validEntitlements.length}Entitlements`}
      w="full"
    >
      <Text fontSize="md" mt="3">
        {t('app_tickets_my_purchases')}
      </Text>

      {validEntitlements.map((ent) => (
        <EntitlementCard
          key={ent.entitlementKey}
          active={uiActiveEntitlementKey === ent.entitlementKey}
          entitlement={ent}
          showOrderDetails={showOrderDetails}
          onSelect={handleSelect}
        />
      ))}

      {!showOnlyValid && (
        <>
          <Text fontSize="sm" mt="3">
            <TextLink onPress={handleLinkTickets}>{t('app_tickets_manually_add')}</TextLink>
          </Text>

          {hasInvalidEntitlements && (
            <Box mt="8">
              <Heading fontSize="lg" mb="4">
                {t('app_tickets_invalid_title')}
              </Heading>

              <VStack space="3">
                {invalidEntitlements.map((ent) => (
                  <Box key={ent.entitlementKey} opacity={0.7}>
                    <EntitlementCard
                      key={ent.entitlementKey}
                      active={uiActiveEntitlementKey === ent.entitlementKey}
                      entitlement={ent}
                      showOrderDetails={showOrderDetails}
                    />
                  </Box>
                ))}
              </VStack>
            </Box>
          )}
        </>
      )}
    </VStack>
  );
}
