import * as React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function UpArrowCircle({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 29 29" width={size} {...props}>
      <G transform="translate(-291 -565)">
        <Circle cx={14.5} cy={14.5} fill={color} r={14.5} transform="translate(291 565)" />
        <G>
          <Path
            d="M12.155 0L6.078 6.548 0 0"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            transform="translate(299.877 573.194) rotate(180 6.077 3.274)"
          />
          <Path
            d="M6.078 1.35v11.763"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth={2}
            transform="translate(299.877 573.194)"
          />
        </G>
      </G>
    </Svg>
  );
});
