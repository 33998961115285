import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Language({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="-3 -3 24 24" width={size} {...props}>
      <G
        fill="none"
        stroke="#373737"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
        transform="translate(.5 .5)"
      >
        <Path d="M0 0L5.543 0" data-name="Line 617" transform="translate(1.413 2.782)" />
        <Path d="M0 0L0 1.391" data-name="Line 618" transform="translate(4.174 1.391)" />
        <Path d="M5.913 2.784a5.991 5.991 0 01-4.174 4.174" data-name="Path 155744" />
        <Path d="M2.435 2.784a5.991 5.991 0 004.174 4.174" data-name="Path 155745" />
        <Path d="M9.738 14.609l1.761-4.7a.348.348 0 01.651 0l1.761 4.7" data-name="Path 155746" />
        <Path d="M0 0L3.139 0" data-name="Line 619" transform="translate(10.26 13.216)" />
        <Rect data-name="Rectangle 2352" height={8.347} rx={1} width={8.347} />
        <Path
          d="M10.086 7.653h5.217a.7.7 0 01.7.7v6.956a.7.7 0 01-.7.7H8.351a.7.7 0 01-.7-.7v-5.221"
          data-name="Path 155747"
        />
        <Path d="M3.826 15.651l1.391-1.391-1.391-1.389" data-name="Path 155748" />
        <Path d="M1.043 10.783a3.478 3.478 0 003.478 3.478h.7" data-name="Path 155749" />
        <Path d="M12.172.35l-1.391 1.389 1.391 1.391" data-name="Path 155750" />
        <Path d="M14.955 5.218a3.478 3.478 0 00-3.474-3.478h-.7" data-name="Path 155751" />
      </G>
    </Svg>
  );
});
