import { useNavigation } from '@react-navigation/native';
import React from 'react';

import ContentBlock, { ContentBlockProps } from 'app/components/ContentBlock';
import { hasFAQs } from 'app/services/ProductHelper';
import { tID } from 'app/services/TestHelper';

export type ProductFAQProps = ContentBlockProps;

export default function ProductFAQ(props: ProductFAQProps) {
  const navigation = useNavigation();
  return (
    <ContentBlock
      {...props}
      precondition={() => hasFAQs(props.productContent)}
      testID={tID('ProductFAQ')}
      onCTAPress={() => {
        navigation.navigate('Tabs', {
          screen: 'ProductStack',
          params: {
            screen: 'ProductFAQ',
            params: { productId: props.productContent?.key ?? '' },
          },
        });
      }}
    />
  );
}
