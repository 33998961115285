import { Box, Center, Input, Pressable } from 'native-base';
import React, { ReactNode, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import useStore from 'app/hooks/useStore';
import { tID } from 'app/services/TestHelper';

export const CLICK_COUNT = 5;

export type SecretDevToolsTriggerProps = {
  children?: ReactNode;
};

export default function SecretDevToolsTrigger({ children }: SecretDevToolsTriggerProps) {
  const [presses, setPresses] = useState(0);
  const [showInput, setShowInput] = useState(false);
  const [devTools, setDevTools] = useStore((s) => [s.devTools, s.setDevTools], shallow);

  const handleTicketPress = () => {
    setPresses(($presses) => $presses + 1);
  };
  const handleChangeText = (value: string) => {
    if (value === 'DEVTOOLS' && !devTools) setDevTools(true);
  };

  useEffect(() => {
    if (!showInput && presses >= CLICK_COUNT) {
      setShowInput(true);
    }
  }, [presses, showInput]);

  return (
    <Box testID={tID('SecretDevToolsTrigger')}>
      <Pressable testID={tID('SecretDevToolsTrigger-pressable')} onPress={handleTicketPress}>
        {children}
      </Pressable>

      {showInput && (
        <Center>
          <Box mt="2" w="32">
            <Input
              testID={tID('SecretDevToolsTrigger-input')}
              textAlign="center"
              type="text"
              onChangeText={handleChangeText}
            />
          </Box>
        </Center>
      )}
    </Box>
  );
}
