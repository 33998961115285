import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function QRTicket({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 26.416 21" width={size} {...props} fill="black">
      <G
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        transform="translate(.001 .002) translate(1.149 .449)"
      >
        <Path d="M0 0H2.7V2.7H0z" transform="translate(5.351 3.263)" />
        <Path d="M0 0H2.7V2.7H0z" transform="translate(16.059 3.263)" />
        <Path d="M0 0H2.7V2.7H0z" transform="translate(5.351 14.138)" />
        <Path d="M16.059 14.137v2.7h2.7v-2.7h-.9" />
        <Path d="M10.705 5.495v-2.25h2.7" />
        <Path d="M0 0L0 3.15" transform="translate(13.628 5.468)" />
        <Path d="M5.445 11.445h4.5v-2.25" />
        <Path d="M10.705 14.137v2.7h2.7" />
        <Path d="M0 0L0.9 0" transform="translate(12.66 13.753)" />
        <Path d="M0 0L2.7 0" transform="translate(5.351 8.463)" />
        <Path d="M18.759 8.533h-2.7v.9" />
        <Path d="M0 0L5.4 0" transform="translate(13.208 11.637)" />
        <G>
          <Path
            d="M25.166 19.75v-4.66c-2.072-.541-3.602-2.394-3.602-4.59 0-2.195 1.53-4.048 3.602-4.59V1.25H1.25v4.66c2.071.541 3.602 2.394 3.602 4.59 0 1.273-.51 2.47-1.435 3.369A4.858 4.858 0 011.25 15.09v4.66h23.916m.25 1.25H1c-.551 0-1-.449-1-1v-6c.961-.001 1.865-.366 2.546-1.028A3.426 3.426 0 003.602 10.5C3.602 8.57 1.986 7 0 7V1c0-.551.449-1 1-1h24.416c.551 0 1 .449 1 1v6c-1.987.001-3.602 1.57-3.602 3.5S24.43 14 26.416 14v6c0 .551-.449 1-1 1z"
            fill={color}
            stroke="none"
            transform="translate(-1.15 -.451)"
          />
        </G>
      </G>
    </Svg>
  );
});
