import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function RatingStarRibbon({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 24 24" width={size} {...props}>
      <Path d="M19.499 23.578c-.289 0-.571-.083-.813-.24L12 19.013l-6.686 4.324A1.497 1.497 0 013 22.08V1.917c0-.827.673-1.5 1.5-1.5h15c.827 0 1.5.673 1.5 1.5v20.162c0 .827-.673 1.499-1.5 1.499h-.001zM12 17.917c.096 0 .19.028.271.08l6.957 4.501a.5.5 0 00.771-.42V1.917a.5.5 0 00-.5-.5h-15a.5.5 0 00-.5.5v20.162a.501.501 0 00.771.419l6.957-4.5a.506.506 0 01.273-.081z" />
      <Path d="M8.775 15.419a1.15 1.15 0 01-1.001-.577 1.16 1.16 0 01-.066-1.022l1.07-2.463-1.939-2.022a1.08 1.08 0 01-.343-.738 1.109 1.109 0 01.279-.804c.211-.238.513-.374.828-.374l.051.001h2.171l1.142-2.247a1.159 1.159 0 012.063-.005l1.146 2.252 2.225-.001c.276 0 .542.107.749.3a1.11 1.11 0 01-.017 1.642l-1.909 1.996 1.068 2.456a1.15 1.15 0 01-.618 1.516 1.143 1.143 0 01-1.022-.066L12 13.774l-2.655 1.493c-.172.099-.37.152-.57.152zM7.615 8.42a.118.118 0 00-.092.037.11.11 0 00-.028.081.111.111 0 00.037.077l2.198 2.291a.5.5 0 01.098.545l-1.2 2.761a.158.158 0 00.012.13.152.152 0 00.134.077.154.154 0 00.077-.021l2.905-1.634a.497.497 0 01.489-.001l2.904 1.631a.16.16 0 00.142.011.153.153 0 00.083-.2l-1.197-2.755a.5.5 0 01.097-.545l2.166-2.263c.061-.056.066-.092.065-.115a.112.112 0 00-.036-.078.106.106 0 00-.073-.03h-2.524a.5.5 0 01-.446-.273l-1.282-2.52a.16.16 0 00-.141-.086.163.163 0 00-.144.087l-1.28 2.517a.498.498 0 01-.446.273l-2.518.003z" />
    </Svg>
  );
});
