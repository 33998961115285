const breakpoints = {
  base: 0,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1280,
};

export const MAX_WIDTH_WEB = 600;

export default breakpoints;
