import { useIsFocused } from '@react-navigation/native';
import { Box, HStack, ScrollView } from 'native-base';
import React, { ReactNode } from 'react';

import { ContentBlockProps } from 'app/components/ContentBlock';
import PartnerOffer from 'app/components/PartnerOffers/PartnerOffer';
import { DEFAULT_PADDING } from 'app/components/ResponsiveContainer';
import { isActive } from 'app/services/TargetingHelper';
import { tID } from 'app/services/TestHelper';

export type PartnerOffersProps = Partial<ContentBlockProps> & {
  children?: ReactNode;
  empty?: JSX.Element;
  placement?: string;
};

export default function PartnerOffers({ productContent, empty, placement }: PartnerOffersProps) {
  const offers = (productContent?.partnerOffers ?? []).filter(isActive);
  const isFocused = useIsFocused();

  if (offers.length === 0) return empty ?? null;

  return (
    <Box mx={-DEFAULT_PADDING} testID={tID('PartnerOffers')}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <HStack alignItems="center">
          {/* IMPORTANT: impressions are tracked on initial render only, so we use
          isFocused to trigger re-render and impression event any time a screen
          navigation occurs */}
          {isFocused &&
            offers.map((offer) => (
              <PartnerOffer
                key={offer.id}
                adPlacement={placement}
                {...offer}
                productContentKey={productContent?.key ?? ''}
              />
            ))}
        </HStack>
      </ScrollView>
    </Box>
  );
}
