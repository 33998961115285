import { useMemo } from 'react';

import { AttractionContent } from 'app/hooks/useAttraction';
import { ProductContent } from 'app/hooks/useProduct';
import { useI18n } from 'app/providers/I18nProvider';
import { createReservationTextTranslationHelper } from 'app/services/I18n';

export default function useReservationTextTranslationHelper({
  attraction,
  product,
}: {
  attraction?: AttractionContent;
  product?: ProductContent;
}) {
  const { t } = useI18n();
  return useMemo(
    () => createReservationTextTranslationHelper(t, { attraction, product }),
    [t, attraction, product]
  );
}
