import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotBoston({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M39.95 64.83h16.4c11.86 0 17.12 6.06 17.12 17.03 0 5.53-2.32 11.32-7.67 13.46 5.62 2.5 8.92 6.95 8.92 15.25 0 10.61-4.73 18.63-17.3 18.63H39.95V64.83ZM51 91.22h4.01c4.19 0 7.4-2.85 7.4-8.2 0-5.97-2.32-9.09-7.22-9.09H51v17.3Zm0 28.89h4.64c5.88 0 8.02-3.39 8.02-9.9s-2.5-9.9-9.09-9.9H51v19.79Zm29.96-39.68c0-9.09 5.35-16.49 16.94-16.49s16.94 7.4 16.94 16.49v33.17c0 9.09-5.35 16.49-16.94 16.49s-16.94-7.4-16.94-16.49V80.43Zm11.06 32.9c0 4.81 1.43 7.13 5.88 7.13s5.88-2.32 5.88-7.13V80.7c0-4.81-1.43-7.13-5.88-7.13s-5.88 2.32-5.88 7.13v32.63Zm51-32.63c-.45-5.53-3.39-7.13-5.88-7.13-3.57 0-5.53 2.32-5.53 6.33 0 10.97 22.47 16.23 22.47 33.7 0 10.61-7.13 16.49-17.47 16.49s-16.14-8.2-16.58-17.83l10.52-1.52c.45 6.51 3.03 9.72 6.6 9.72 3.83 0 6.42-2.05 6.42-5.88 0-12.75-22.47-16.14-22.47-34.59 0-10.25 6.24-16.05 17.12-16.05 9 0 14.35 6.51 15.33 15.6l-10.52 1.16Z"
        fill="#000"
      />
    </Svg>
  );
});
