import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function TicketSelection({ color, size, ...props }) {
  return (
    <Svg
      height={size}
      strokeWidth="1.75"
      width={size}
      {...props}
      fill={color}
      viewBox="0 0 22.134 22.134"
    >
      <G transform="translate(0.625 0.625)">
        <Path
          d="M10.634,17.444H3.825a.908.908,0,0,1-.908-.908V13.784a.454.454,0,0,1,.4-.454,3.178,3.178,0,0,0,0-6.307.454.454,0,0,1-.4-.454V3.825a.908.908,0,0,1,.908-.908H22.892a.908.908,0,0,1,.908.908V6.577a.454.454,0,0,1-.4.454,3.178,3.178,0,0,0-2.747,3.624"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          transform="translate(-2.917 -2.917)"
        />
        <Path
          d="M67.083,72.531a5.448,5.448,0,1,0,5.448-5.448A5.448,5.448,0,0,0,67.083,72.531Z"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          transform="translate(-57.095 -57.095)"
        />
        <Path
          d="M90.875,91.9,88,95.744a.744.744,0,0,1-1.121.079l-1.487-1.487"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          transform="translate(-72.508 -78.536)"
        />
      </G>
    </Svg>
  );
});
