import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function HelpCenter({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="-1.7 -1 20.75 19.25" width={size} {...props}>
      <G data-name="help center" fill="none" stroke="#373737" strokeWidth={1}>
        <Path
          d="M5.392 6.43a2.61 2.61 0 113.174 2.545.7.7 0 00-.565.678v.777"
          data-name="Path 155738"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(.5 .5)"
        />
        <Path
          d="M8 15.999a8 8 0 10-8-8 8 8 0 008 8z"
          data-name="Path 155739"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(.5 .5)"
        />
        <Path
          d="M8.001 12.516a.174.174 0 110-.348"
          data-name="Path 155740"
          transform="translate(.5 .5)"
        />
        <Path
          d="M7.999 12.516a.174.174 0 100-.348"
          data-name="Path 155741"
          transform="translate(.5 .5)"
        />
      </G>
    </Svg>
  );
});
