import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotAtlanta({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="m58.25 129.98-10.7.21L58.13 65.6l13.73-.27 13.13 64.12-10.7.21-2.24-14.22-12.12.24-1.68 14.3Zm3.09-23.96 8.91-.18-4.92-27.55h-.18l-3.82 27.72Zm30.05-31.46-10.7.21-.19-9.63 32.45-.64.19 9.63-10.7.21 1.09 54.73-11.05.22-1.09-54.73Zm27.92 54.2-1.28-64.36 11.05-.22 1.09 54.73 17.38-.35.19 9.63-28.44.57Z"
        fill="#000"
      />
    </Svg>
  );
});
