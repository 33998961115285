import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function TermsAndConditions({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="-2.5 0 20.75 19.25" width={size} {...props}>
      <G
        fill="none"
        stroke="#373737"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
        transform="translate(.5 .5)"
      >
        <Path
          d="M12.252 13.466a1.579 1.579 0 00.38-1.023v-8.5H2.369v11.842h7.168a1.579 1.579 0 001.2-.553z"
          data-name="Path 155742"
        />
        <Path
          d="M14.211 1.575h-4.3a2.628 2.628 0 00-4.816 0h-4.3a.789.789 0 00-.789.789v15a.789.789 0 00.789.789h13.416a.789.789 0 00.789-.789v-15a.789.789 0 00-.789-.789z"
          data-name="Path 155743"
        />
        <Path d="M5.526 0L0 0" data-name="Line 614" transform="translate(4.737 6.312)" />
        <Path d="M5.526 0L0 0" data-name="Line 615" transform="translate(4.737 9.47)" />
        <Path d="M3.553 0L0 0" data-name="Line 616" transform="translate(4.737 12.627)" />
      </G>
    </Svg>
  );
});
