import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotChicago({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M70 86.31v-4.99c0-5.44-2.5-7.76-5.35-7.76-4.46 0-5.88 2.32-5.88 7.13v32.63c0 4.81 1.43 7.13 5.88 7.13 4.01 0 5.35-2.32 5.35-6.51v-7.76h11.06v7.4c0 9.09-5.35 16.49-16.41 16.49s-16.94-7.4-16.94-16.49V80.41c0-9.09 5.35-16.49 16.94-16.49s16.41 8.02 16.41 17.65v4.73H70Zm18.73 42.89V64.83h11.05v26.21h11.77V64.83h11.05v64.37h-11.05v-28.53H99.78v28.53H88.73Zm42.53 0V64.83h11.05v64.37h-11.05Z"
        fill="#000"
      />
    </Svg>
  );
});
