import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function ProgrammingBrowser({ color, size, ...props }) {
  return (
    <Svg height={size} stroke={color} viewBox="0 0 24 24" width={size} {...props}>
      <Path d="M15.75 12L18.75 15 15.75 18" />
      <Path d="M8.25 12L5.25 15 8.25 18" />
      <Path d="M13.5 12L10.5 18" />
      <Rect height={18} rx={2} ry={2} width={23} x={0.5} y={3} />
      <Path d="M0.5 8L23.5 8" />
      <Path d="M4 5.25a.25.25 0 11-.25.25.25.25 0 01.25-.25M7 5.25a.25.25 0 11-.25.25.25.25 0 01.25-.25M10 5.25a.25.25 0 11-.25.25.25.25 0 01.25-.25" />
    </Svg>
  );
});
