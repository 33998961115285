import noop from 'lodash/noop';
import { Actionsheet, Box, IActionsheetProps, Pressable } from 'native-base';
import { ReactNode } from 'react';

import { Cross } from 'app/components/icons';

export type CPActionSheetProps = IActionsheetProps & {
  children?: ReactNode;
  isOpen: boolean;
  showHeader?: boolean;
  closeOnOverlayPress?: boolean;
  onClose?: () => void;
};

export default function CPActionSheet({
  children,
  isOpen,
  onClose,
  showHeader = true,
  closeOnOverlayPress = true,
  ...props
}: CPActionSheetProps) {
  return (
    <Actionsheet
      _backdrop={closeOnOverlayPress ? undefined : { onPress: noop }}
      hideDragIndicator
      isOpen={isOpen}
      onClose={onClose}
      {...props}
    >
      <Actionsheet.Content px={0}>
        {showHeader === true && (
          <Box
            alignItems="flex-end"
            borderBottomColor="gray.300"
            borderBottomStyle="solid"
            borderBottomWidth={1}
            display="flex"
            px={3}
            py={2}
            width="100%"
          >
            <Pressable p="1" onPress={onClose}>
              <Cross />
            </Pressable>
          </Box>
        )}
        {children}
      </Actionsheet.Content>
    </Actionsheet>
  );
}
