export const DATE_FORMAT_YMD = 'YYYY-MM-DD';
export const DATE_FORMAT_ISO = 'YYYY-MM-DDTHH:mm:ss';

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const nextTick = () => sleep(0);

type TimeInterval = 's' | 'm' | 'h' | 'd';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const timeIntervals: Record<TimeInterval, number> = {
  s: SECOND,
  m: MINUTE,
  h: HOUR,
  d: DAY,
};

export const toMs = (unit: number, interval: TimeInterval): number => {
  const ms = timeIntervals[interval];
  if (!interval) throw new Error(`unsupported interval: ${interval}`);
  return unit * ms;
};

export const toYMD = (isoDate = '') => isoDate.substring(0, 10);

export const isOlderThan = (timestamp: number, durationMs: number): boolean => {
  return timestamp < Date.now() - durationMs;
};

type Unsubscribe = () => void;
export const onTimeout = (callback: () => void, ms: number): Unsubscribe => {
  const t = setTimeout(callback, ms);
  return () => clearTimeout(t);
};
