import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotPhiladelphia({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M43.98 129.2V64.83h15.87c13.64 0 19.97 5.26 19.97 18.99s-6.33 18.99-19.97 18.99h-4.81v26.39H43.98Zm11.06-35.48h4.28c7.22 0 9.45-2.23 9.45-9.9s-2.23-9.9-9.45-9.9h-4.28v19.79ZM85 129.2V64.83h11.06v26.21h11.77V64.83h11.06v64.37h-11.06v-28.53H96.06v28.53H85Zm40.48 0V64.83h11.06v54.74h17.39v9.63h-28.44Z"
        fill="#000"
      />
    </Svg>
  );
});
