import { useHeaderHeight } from '@react-navigation/elements';
import { useFocusEffect } from '@react-navigation/native';
import { Box, Button, FormControl, Input, KeyboardAvoidingView, Text, VStack } from 'native-base';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Keyboard, Platform } from 'react-native';

import AuthTitle from 'app/components/AuthTitle';
import { MyCitypassLogo } from 'app/components/icons';
import Screen from 'app/components/Screen';
import TextLink from 'app/components/TextLink';
import useStore from 'app/hooks/useStore';
import { AuthenticationStackParamList } from 'app/navigation/AuthenticationStackNavigator';
import { useAuthenticationNav } from 'app/navigation/AuthenticationStackNavigator/AuthenticationNavProvider';
import { RootScreenProps } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import LanguageSelect from 'app/screens/Authentication/LanguageSelect';
import { tID } from 'app/services/TestHelper';
import { isEmail } from 'app/utils/validation';

const MODULE = '[AuthenticationEnterEmailScreen]';

export type AuthenticationStepEnterEmailScreenProps = RootScreenProps<
  AuthenticationStackParamList,
  'AuthenticationStepEnterEmail'
>;

export default function AuthenticationEnterEmailScreen({
  navigation,
  route,
}: AuthenticationStepEnterEmailScreenProps) {
  const { t } = useI18n();
  const completeWelcome = useStore((s) => s.completeWelcome);
  const {
    email: storedEmail,
    handleVerifyEmail,
    handleExploreApp,
    verifyEmail,
  } = useAuthenticationNav();
  const headerHeight = useHeaderHeight();
  const emailRef = useRef<HTMLInputElement | null>(null);

  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [showLanguage, setShowLanguage] = useState(true);

  const handleSubmit = () => {
    if (typeof email === 'string' && isEmail(email)) {
      completeWelcome();
      handleVerifyEmail(email);
    } else {
      emailRef.current?.focus();
    }
  };

  const handleEmailChange = (value: string) => {
    const sanitizedValue = value.trim().toLowerCase();
    setIsValid(isEmail(sanitizedValue));
    setEmail(sanitizedValue);
  };

  useFocusEffect(
    useCallback(() => {
      if (storedEmail && !email) setEmail(storedEmail);
    }, [email, storedEmail])
  );

  useEffect(() => {
    const subscriptions = [
      Keyboard.addListener('keyboardWillShow', () => setShowLanguage(false)),
      Keyboard.addListener('keyboardWillHide', () => setShowLanguage(true)),
    ];

    return () => {
      subscriptions.forEach((sub) => sub.remove());
    };
  }, []);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      keyboardVerticalOffset={headerHeight + 20}
      style={{ flex: 1 }}
    >
      <Screen
        after={showLanguage ? <LanguageSelect disabled={verifyEmail.isLoading} /> : null}
        flexGrow="1"
        keyboardShouldPersistTaps="handled"
        testID={tID('AuthenticationEnterEmailScreen')}
      >
        <VStack space="6">
          <Box alignItems="center" pb="7" pt="16">
            <MyCitypassLogo size={260} />
          </Box>

          <Box alignItems="center">
            <AuthTitle>{t('app_auth_email_title')}</AuthTitle>
            <Text textAlign="center">{t('app_auth_email_description')}</Text>
          </Box>

          <FormControl isInvalid={!isValid} isRequired>
            <Input
              ref={emailRef}
              _focus={{ borderColor: 'black' }}
              autoComplete="email"
              clearButtonMode="while-editing"
              isReadOnly={verifyEmail.isLoading}
              isRequired
              keyboardType="email-address"
              placeholder={t<string>('app_auth_email_input_placeholder')}
              px="0"
              py="3"
              size="lg"
              testID={tID('txt-email')}
              textContentType="emailAddress"
              type="text"
              value={email}
              variant="underlined"
              onChangeText={handleEmailChange}
              onSubmitEditing={handleSubmit}
            />
          </FormControl>

          <Button
            colorScheme="primary"
            isDisabled={!isValid}
            isLoading={verifyEmail.isLoading}
            onPress={handleSubmit}
          >
            {t('gen_continue')}
          </Button>

          <Text fontSize="xs" mt="5" textAlign="center">
            {t('app_welcome_explore_label')}{' '}
            <TextLink disabled={verifyEmail.isLoading} onPress={handleExploreApp}>
              {t('app_welcome_explore_cta')}
            </TextLink>
          </Text>
        </VStack>
      </Screen>
    </KeyboardAvoidingView>
  );
}
