import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path, Use } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

const LOGO_RATIO = 0.318697;

export default withIconWrapper(function CitypassTicketLogo({ color, size = 50, ...props }) {
  return (
    <Svg height={size * LOGO_RATIO} viewBox="0 0 576 269.7" width={size} x="0px" y="0px" {...props}>
      <Defs>
        <Path d="M0 0H576V269.7H0z" id="SVGID_1_" />
      </Defs>
      <ClipPath id="SVGID_00000028320359847977139530000013081212734561722286_">
        <Use
          // overflow="visible"
          xlinkHref="#SVGID_1_"
        />
      </ClipPath>
      <Path
        clipPath="url(#SVGID_00000028320359847977139530000013081212734561722286_)"
        d="M534.4 134.9c0-23.5 19-42.5 42.5-42.5V0H-.8v92.4c23.5 0 42.5 19 42.5 42.5s-19 42.5-42.5 42.5v92.4h577.7v-92.4c-23.5-.1-42.5-19.1-42.5-42.5z"
        fill="#000000"
      />
      <G>
        <Path
          // className="st1"
          d="M408.8 175.7c-14.3 0-25.1-8.2-29.4-22.4l13.7-2.7c3.4 9.1 10.8 11.2 16.1 11.2 5.8 0 11.9-4.1 11.9-9 0-7.5-8.2-9.9-16.9-14.2-10.1-5-21.6-9.6-21.6-24.4 0-6.9 2.2-12.3 6.6-16.2 4.5-4 11.2-7 19.7-7 13.7 0 23.5 8.5 27.8 19l-13.5 3.8c-3.4-6.1-8.9-8.9-14.7-8.9-6.2 0-11.5 3.3-11.5 8.6 0 8.2 8 10.1 15.8 13.9 10.7 5.2 23.9 9.8 23.9 25.5.1 11.7-13.1 22.8-27.9 22.8zM472.8 175.7c-14.3 0-25.1-8.2-29.4-22.4l13.7-2.7c3.4 9.1 10.8 11.2 16.1 11.2 5.8 0 11.9-4.1 11.9-9 0-7.5-8.2-9.9-16.9-14.2-10.1-5-21.6-9.6-21.6-24.4 0-6.9 2.2-12.3 6.6-16.2 4.5-4 11.2-7 19.7-7 13.7 0 23.5 8.5 27.8 19l-13.5 3.8c-3.4-6.1-8.9-8.9-14.7-8.9-6.2 0-11.5 3.3-11.5 8.6 0 8.2 8 10.1 15.8 13.9 10.7 5.2 23.9 9.8 23.9 25.5 0 11.7-13.2 22.8-27.9 22.8z"
          fill="#E31838"
        />
        <Path
          d="M512.1 90.8c-4.5 0-8.1 3.6-8.1 8.1 0 4.4 3.5 8 8 8s8.1-3.6 8.1-8.1c0-4.4-3.5-8-8-8zM512 106c-4 0-7.1-3.2-7.1-7.2s3.1-7.2 7.2-7.2 7.1 3.2 7.1 7.2c0 3.9-3.1 7.2-7.2 7.2z"
          // className="st2"
          fill="#E11D3A"
        />
        <Path
          d="M514 99.7c1.1-.4 1.8-1.3 1.9-2.6 0-1.8-1.4-2.8-3.4-2.8h-3.8v8.5h1.9v-2.7h1.5l1.8 2.7h2.2l-2.1-3.1zm-1.5-1.2h-1.9V96h1.8c.9 0 1.5.5 1.5 1.3.1.6-.4 1.2-1.4 1.2z"
          // className="st2"
          fill="#E11D3A"
        />
        <Path
          // className="st3"
          d="M169.5 115.1V95.5H180c2 0 3.6 1.6 3.6 3.6v16h9c1.6 0 3 1 3.5 2.6l2.7 10.4h-15.2v32c0 3.2 1.6 4.1 5.1 4.1 2.2 0 4.5-.7 6.5-1.1v11.7c-3.9.5-7.3.9-11.2.9-10.5 0-14.6-5.3-14.6-13.7v-33.9h-8.2v-13h8.3zM202.2 184.1c2.2.5 3.9.5 5.9.5 4.9 0 7.6-1.4 9.4-4.7 1.4-2.9 2.3-4.6 2.3-7 0-2.2-1.1-4.7-1.8-7.1l-15.9-50.7h9.6c2.7 0 5.2 1.8 5.9 4.5l8.6 32.1h.2l10.2-36.6h14.2L232 174.6c-6.3 17-11.3 21.6-22.9 21.6-3.4 0-5.1-.2-6.8-.5v-11.6h-.1z"
          fill="#FFFFFF"
        />
        <Path
          // className="st1"
          d="M336 91.9h13.4c3.2 0 6 2.1 6.9 5.2l21.9 77.4H363l-4.6-17.8h-26l-4.9 17.8h-15.2L336 91.9zm18.8 50.9l-9-35h-.2l-9.5 35h18.7zM144.9 105.6c-4.4 0-8.1-3.6-8.1-8.1 0-4.4 3.6-8.1 8.1-8.1 4.4 0 8.1 3.6 8.1 8.1s-3.6 8.1-8.1 8.1zM294.2 92h-33.4v82.6h14.5v-33.4h18.9c13.6 0 24.6-11 24.6-24.6S307.7 92 294.2 92zm-.9 35.4h-18v-21.6h18c6.5 0 11.8 4.4 11.8 10.9-.1 6.4-5.3 10.7-11.8 10.7z"
          fill="#E31838"
        />
        <Path
          // className="st3"
          d="M103.3 161.8c-8 0-14.8-5.9-16.1-13.2-1-5.8-.9-10.9-.9-15.3s-.2-9.6.9-15.3c1.1-6.7 8.1-13.2 16.1-13.2 6.5 0 12.1 3.8 14.8 9.3l13.5-3.7c-4.4-11.4-15.4-19.5-28.3-19.5-15.6 0-28.9 11.8-30.5 27 0 0-.7 5.9-.7 15.5s.7 15.2.7 15.2c1.6 15.2 14.9 27 30.5 27 13.1 0 24.2-8.3 28.4-19.9l-13.5-3.6c-2.5 5.8-8.2 9.7-14.9 9.7zM137.8 174.5v-59.4h10.5c2 0 3.6 1.6 3.6 3.6v55.8h-14.1z"
          fill="#FFFFFF"
        />
      </G>
    </Svg>
  );
});
