export const unslugify = (value: string) =>
  value.replace(/-/g, ' ').replace(/(^[a-z]| [a-z])/g, (match = '') => match.toUpperCase());

const listFormatContent: Record<ListFormatType, { [locale: string]: string }> = {
  or: {
    en: 'or',
    de: 'oder',
    es: 'o',
    fr: 'ou',
    ja: 'または',
    zh: '或',
    it: 'o',
    pt: 'ou',
  },
  and: {
    en: 'and',
    de: 'und',
    es: 'y',
    fr: 'et',
    ja: 'そして',
    zh: '和',
    it: 'e',
    pt: 'e',
  },
};

type ListFormatType = 'and' | 'or';

export const listFormat = (
  list: string[],
  { locale, type }: { locale: string; type: ListFormatType }
) => {
  if (list.length < 2) return list.toString();
  const separator = ', ';
  const lastSeparator = listFormatContent[type]?.[locale] ?? listFormatContent[type]?.en ?? '';
  const out = list.join(separator);
  const idx = out.lastIndexOf(separator);

  if (lastSeparator) {
    const last = (list.length === 2 ? ' ' : separator) + lastSeparator;
    return `${out.slice(0, idx)}${last} ${out.slice(idx + separator.length)}`;
  }
  return out;
};
