import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Error({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 61 58" width={size} {...props}>
      <Path
        d="M9.667 9.667h38.666a4.848 4.848 0 014.834 4.833v29a4.848 4.848 0 01-4.834 4.833H9.667A4.848 4.848 0 014.833 43.5v-29a4.848 4.848 0 014.834-4.833z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <Path
        d="M53.167 14.5L29 31.417 4.833 14.5"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <Circle cx={53} cy={12} fill="#EE1433" r={8} />
    </Svg>
  );
});
