import noop from 'lodash/noop';
import {
  Box,
  Button,
  Heading,
  HStack,
  Pressable,
  ScrollView,
  Text,
  useDisclose,
  VStack,
} from 'native-base';
import React, { memo, ReactNode } from 'react';

import CPActionSheet from 'app/components/CPActionSheet/CPActionSheet';
import TextLink from 'app/components/TextLink';
import useDestinations from 'app/hooks/useDestinations/useDestinations';
import { useI18n } from 'app/providers/I18nProvider';
import DestinationCard from 'app/screens/DestinationsScreen/DestinationCard/DestinationCard';
import { Entitlement, TicketClass } from 'app/services/GuestCenterService.types';
import { isAlacarte } from 'app/services/ProductLineupHelper';
import { date } from 'app/services/ReservationHelper';
import { tID } from 'app/services/TestHelper';
import { unslugify } from 'app/utils/string';

export type EntitlementCardProps = {
  entitlement: Entitlement;
  active: boolean;
  showOrderDetails?: boolean;
  onSelect?: (entitlementKey: string) => void;
};

/**
 * Memoizing helps with the accordion animation when there are more than 2 entitlements.
 */
export default memo(
  function EntitlementCard({
    entitlement,
    active,
    showOrderDetails = true,
    onSelect,
  }: EntitlementCardProps) {
    const { t } = useI18n();
    const { isOpen, onOpen, onClose } = useDisclose();
    const { data: destinations = [] } = useDestinations();
    const product = destinations.find((d) => d.key === entitlement.productLineup?.contentKey);

    const isALaCarte = isAlacarte(entitlement.productLineup);
    const showOrderNumber = entitlement.travelParty && entitlement.travelParty.orderNumber;
    // hide expiration when invalid, a la carte or card travel parties with no order number
    const showExpiration = !isALaCarte && showOrderNumber;
    // hide validity when invalid, a la carte or no usage window provided
    const showValidity = !isALaCarte && entitlement.productLineup.usageWindowDays > 0;
    const entitlementName =
      entitlement.name || unslugify(entitlement.productLineup?.contentKey ?? '');
    const ticketClassSummary = t('app_ticket_class_summary', {
      adult: entitlement.quantity[TicketClass.Adult],
      child: entitlement.quantity[TicketClass.Child],
    });

    const handleSelect = () =>
      entitlement.isValid && onSelect ? onSelect(entitlement.entitlementKey) : noop;

    return (
      <Box testID="EntitlementCard" w="full">
        <HStack alignItems="center" flexShrink="0" justifyContent="space-between">
          <Pressable flexShrink="1" testID={tID('SelectTrigger')} onPress={handleSelect}>
            <HStack alignItems="center" space="3">
              <Box borderColor="brand.primary" borderWidth={active ? 4 : 0} rounded="12px" w="16">
                {!!product && (
                  <DestinationCard
                    product={product}
                    showLabel={false}
                    spotSize={20}
                    onPress={handleSelect}
                  />
                )}
              </Box>

              <Box flexShrink="1">
                <Heading fontSize="sm">{entitlementName}</Heading>
                <Text lineHeight="sm">{ticketClassSummary}</Text>
                {showOrderNumber && (
                  <Text ellipsizeMode="tail" numberOfLines={1}>
                    {t('app_order_num_title')} {entitlement.travelParty?.orderNumber}
                  </Text>
                )}
              </Box>
            </HStack>
          </Pressable>

          <Box>
            {showOrderDetails && (
              <TextLink
                bold={false}
                mr="-4"
                p="4"
                testID={tID('OrderDetailsTrigger')}
                onPress={onOpen}
              >
                {t('app_order_details_title')}
              </TextLink>
            )}
          </Box>
        </HStack>

        <CPActionSheet isOpen={isOpen} onClose={onClose}>
          <ScrollView p="6" testID={tID('OrderDetails')}>
            <VStack mb="3" space="3">
              <Heading>{entitlementName}</Heading>

              {showOrderNumber && (
                <OrderDetailsField title={t('app_order_num_title')}>
                  {entitlement.travelParty?.orderNumber}
                </OrderDetailsField>
              )}

              <OrderDetailsField title={t('app_group_title')}>
                {ticketClassSummary}
              </OrderDetailsField>

              {entitlement.travelParty && showExpiration && (
                <OrderDetailsField title={t('app_expiration_title')}>
                  {t('app_expiration_detail', {
                    date: date(entitlement.travelParty.firstUsageBy).format('MMM D, YYYY'),
                  })}
                </OrderDetailsField>
              )}

              {showValidity && (
                <OrderDetailsField title={t('app_validity_title')}>
                  {t('app_validity_detail', {
                    days: entitlement.productLineup.usageWindowDays.toString(),
                  })}
                </OrderDetailsField>
              )}

              {entitlement.passes.map((pass) => (
                <Box key={pass.barcode}>
                  <Text color="gray.500" numberOfLines={1}>
                    {pass.name ? `${pass.name} (${pass.ticketClass})` : pass.ticketClass}
                  </Text>
                  <Text bold={false} color="gray.500" selectable>
                    {pass.barcode}
                  </Text>
                </Box>
              ))}
            </VStack>
          </ScrollView>

          {entitlement.isValid && (
            <Box borderTopColor="brand.border" borderTopWidth="1" p="4" w="full">
              <Button w="full" onPress={handleSelect}>
                {t('app_auth_tickets_start_planning')}
              </Button>
            </Box>
          )}
        </CPActionSheet>
      </Box>
    );
  },
  (prev, next) => {
    return (
      prev.active === next.active &&
      prev.entitlement.entitlementKey === next.entitlement.entitlementKey &&
      prev.entitlement.name === next.entitlement.name &&
      prev.entitlement.isValid === next.entitlement.isValid
    );
  }
);

function OrderDetailsField({ title, children }: { title: string; children?: ReactNode }) {
  return (
    <Box>
      <Heading fontSize="sm">{title}</Heading>
      <Text>{children}</Text>
    </Box>
  );
}
