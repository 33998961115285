/* eslint-disable no-param-reassign */
import Bugsnag from '@bugsnag/expo';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Constants from 'expo-constants';
import isObject from 'lodash/isObject';
import React, { ReactNode } from 'react';
import { Platform } from 'react-native';

import { LogTransport } from 'app/services/Logger.types';

Bugsnag.start({
  apiKey: Constants.expoConfig?.extra?.bugsnag.apiKey,
  plugins: [new BugsnagPluginReact()],
  appType: Platform.OS,
  appVersion: Constants.expoConfig?.version,
  releaseStage: __DEV__ ? 'development' : Constants.expoConfig?.extra?.profile,
});

export default Bugsnag;

export const BugsnagErrorBoundary: React.FC<{
  FallbackComponent: (p: any) => JSX.Element;
  children?: ReactNode;
}> = (Bugsnag.getPlugin('react') as any).createErrorBoundary(React);

export type BugsnagEvent = {
  severity: 'info' | 'warning' | 'error';
  addMetadata: (key: string, data: Record<string, unknown>) => void;
};

export const bugsnagLogger: LogTransport = ({ level, message, error, data }) => {
  if (level === 'debug') {
    Bugsnag.leaveBreadcrumb(message, data);
    return;
  }

  // use error to maintain stacktrace otherwise build event
  const errorEvent = isErrorLike(error)
    ? error
    : {
        name: level[0].toUpperCase() + level.slice(1),
        message,
      };

  // merge log and error messages
  errorEvent.message = `${message}${isErrorLike(error) ? ` (${error.message})` : ''}`;

  Bugsnag.notify(errorEvent, (event: BugsnagEvent) => {
    event.severity = level === 'warn' ? 'warning' : level;
    if (data) event.addMetadata('data', data);
  });
};

function isErrorLike(err: unknown): err is { name: string; message: string } {
  return err instanceof Error || (isObject(err) && 'name' in err && 'message' in err);
}
