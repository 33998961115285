import React, { ReactNode, useEffect, useRef } from 'react';
import { Animated, Easing, Platform } from 'react-native';

export type RotateProps = {
  children?: ReactNode;
  rotated: boolean;
  start?: number;
  end?: number;
};

export default function Rotate({ rotated, start = 0, end = 180, children }: RotateProps) {
  const rotateAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(rotateAnim, {
      duration: 200,
      easing: Easing.linear,
      toValue: rotated ? 1 : 0,
      useNativeDriver: Platform.OS !== 'web' && process.env.NODE_ENV !== 'test',
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotated]);

  return (
    <Animated.View
      style={{
        transform: [
          {
            rotate: rotateAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [`${start}deg`, `${end}deg`],
            }),
          },
        ],
      }}
      testID="Rotate"
    >
      {children}
    </Animated.View>
  );
}
