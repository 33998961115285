import React, { ReactNode, useEffect } from 'react';
import { Pressable, Text, View } from 'react-native';

import { useI18n } from 'app/providers/I18nProvider';
import { MAX_WIDTH_WEB } from 'app/theme/breakpoints';

export type ErrorViewProps = {
  children?: ReactNode;
  error: Error;
  clearError: () => void;
};

/**
 * Cannot use NativeBase here since it wraps all other components.
 */
export default function ErrorFallback({ clearError }: ErrorViewProps) {
  const { t } = useI18n();

  useEffect(() => {
    global.process = { ...global.process };
  }, []);
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        maxWidth: MAX_WIDTH_WEB,
        padding: 30,
        alignItems: 'center',
        marginHorizontal: 'auto',
        borderColor: 'black',
      }}
      testID="ErrorFallback"
    >
      <Text style={{ fontWeight: 'bold', fontSize: 18, marginBottom: 20, textAlign: 'center' }}>
        {t('app_error_boundary_title')}
      </Text>
      <Text style={{ marginBottom: 20, textAlign: 'center' }}>
        {t('app_error_boundary_description')}
      </Text>

      <Pressable
        style={{
          backgroundColor: '#333',
          borderRadius: 1000,
          paddingHorizontal: 24,
          paddingVertical: 10,
        }}
        onPress={clearError}
      >
        <Text style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>
          {t('app_error_boundary_cta')}
        </Text>
      </Pressable>
    </View>
  );
}
