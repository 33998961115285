import { IModalProps, Modal } from 'native-base';
import React from 'react';

import BlurOverlay from 'app/components/BlurOverlay';
import { useI18n } from 'app/providers/I18nProvider';
import { tID } from 'app/services/TestHelper';

export type CPModalProps = IModalProps;

function CPModal({ children, ...props }: CPModalProps) {
  const { t } = useI18n();
  return (
    <Modal testID={tID('CPModal')} {...props}>
      <BlurOverlay
        accessibilityLabel={
          props.closeOnOverlayClick && props.onClose ? `${t<string>('gen_close')} Modal` : undefined
        }
        onPress={props.closeOnOverlayClick ? props.onClose : undefined}
      />
      {children}
    </Modal>
  );
}

CPModal.Body = Modal.Body;
CPModal.CloseButton = Modal.CloseButton;
CPModal.Content = Modal.Content;
CPModal.Footer = Modal.Footer;
CPModal.Header = Modal.Header;

export default CPModal;
