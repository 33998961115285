import { Heading } from 'native-base';
import React, { ReactNode } from 'react';

export type AuthTitleProps = {
  children?: ReactNode;
};

export default function AuthTitle({ children }: AuthTitleProps) {
  return (
    <Heading fontSize="xl" mb="3" testID="AuthTitle" textAlign="center">
      {children}
    </Heading>
  );
}
