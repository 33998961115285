import React from 'react';
import { Circle, Path, Svg } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function LogoSpotHouston({ color, size = 24 }) {
  return (
    <Svg height={size} viewBox="0 0 194.03 194.03" width={size}>
      <Circle cx="97.01" cy="97.01" fill="#fff" r="97.01" />
      <Path
        d="M39.06 129.2V64.83h11.06v26.21h11.77V64.83h11.06v64.37H61.89v-28.53H50.12v28.53H39.06Zm41.01-48.77c0-9.09 5.35-16.49 16.94-16.49s16.94 7.4 16.94 16.49v33.17c0 9.09-5.35 16.49-16.94 16.49s-16.94-7.4-16.94-16.49V80.43Zm11.05 32.9c0 4.81 1.43 7.13 5.88 7.13s5.88-2.32 5.88-7.13V80.7c0-4.81-1.43-7.13-5.88-7.13s-5.88 2.32-5.88 7.13v32.63Zm29.97-48.5h11.06v48.59c0 4.73 1.87 7.04 5.88 7.04s5.88-2.32 5.88-7.04V64.83h11.06v48.15c0 11.06-6.42 17.12-16.94 17.12s-16.94-6.06-16.94-17.12V64.83Z"
        fill="#000"
      />
    </Svg>
  );
});
