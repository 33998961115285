import { Box, Text, VStack } from 'native-base';
import React, { useMemo } from 'react';

import Accordion from 'app/components/Accordion';
import Hero from 'app/components/Hero';
import OfferCard from 'app/components/OfferCard';
import PartnerOffers from 'app/components/PartnerOffers';
import Screen from 'app/components/Screen';
import useAssets from 'app/hooks/useAssets';
import useProduct from 'app/hooks/useProduct';
import { ProductStackParamList } from 'app/navigation/ProductStackNavigator';
import { RootScreenProps } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics, { Events } from 'app/services/Analytics';
import { getOfferDisplayGroups } from 'app/services/ProductHelper';
import { cdn } from 'app/utils/image';

export type ProductOffersScreenProps = RootScreenProps<ProductStackParamList, 'ProductOffers'>;

export default function ProductOffersScreen({ navigation, route }: ProductOffersScreenProps) {
  const { t } = useI18n();
  const { productContent } = useProduct({ productContentKey: route.params?.productId });
  const offerGroups = getOfferDisplayGroups(productContent, t);
  const offerGroupSections = useMemo(() => {
    return offerGroups.map((group) => ({
      id: group.id,
      title: group.title,
      attractionKey: group.attractionContentKey,
      _title: group.theme === 'highlight' ? { color: 'brand.primary' } : undefined,
      content: (
        <VStack space="4">
          {group.offers.map((offer) => (
            <OfferCard
              key={offer.id}
              offer={offer}
              productContentKey={productContent?.key ?? ''}
              theme={group.theme}
              w="full"
            />
          ))}
        </VStack>
      ),
    }));
  }, [offerGroups, productContent?.key]);

  const { data: { productOffers } = {} } = useAssets();

  return (
    <Screen
      beforeContainer={<Hero url={cdn(productOffers?.url ?? '', [])} />}
      testID="ProductOffersScreen"
    >
      <Text mb="6" mt="2">
        {t('app_product_offers_screen_intro')}
      </Text>

      <Accordion
        sections={offerGroupSections}
        onExpand={({ id, attractionKey }) => {
          const data = { productKey: productContent?.key ?? '' };

          if (id === 'optionTickets') {
            Analytics.trackEvent(Events.OffersExpandOptionTickets, data);
          } else {
            Analytics.trackEvent(Events.OffersExpandAttraction, {
              ...data,
              attractionKey,
            });
          }
        }}
      />

      <Box mt="8">
        <PartnerOffers placement="productOffers" productContent={productContent} />
      </Box>
    </Screen>
  );
}
