import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function ReportAnIssue({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 20.75 19.25" width={size} {...props}>
      <G
        fill="none"
        stroke="#373737"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
        transform="translate(.5 .5)"
      >
        <Path
          d="M13.217 7.652v7.652a.7.7 0 01-.7.7H.7a.7.7 0 01-.7-.7V2.091a.7.7 0 01.7-.7h9.043"
          data-name="Path 155726"
        />
        <Path
          d="M8.574 9.4l-3.443 1.471 1.475-3.444L14.035 0l1.967 1.967z"
          data-name="Path 155727"
        />
        <Path d="M1.968 1.968L0 0" data-name="Line 612" transform="translate(12.757 1.276)" />
        <Path d="M1.967 1.969L0 0" data-name="Line 613" transform="translate(6.605 7.427)" />
      </G>
    </Svg>
  );
});
