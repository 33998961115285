import { setStatusBarStyle } from 'expo-status-bar';
import { Box, IBoxProps } from 'native-base';
import React, { useEffect } from 'react';
import { StatusBar } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export type StatusBarHeaderProps = {
  bg?: IBoxProps['bg'];
};

export default function HeaderStatusBar({ bg = 'white' }: StatusBarHeaderProps) {
  const { top } = useSafeAreaInsets();

  useEffect(() => {
    setStatusBarStyle('dark');
    return () => setStatusBarStyle('light');
  }, []);

  return (
    <>
      <StatusBar barStyle="dark-content" />
      <Box bg={bg} h={`${top}px`} />
    </>
  );
}
