import { Dimensions } from 'react-native';

export type ImageTransform =
  // https://www.filestack.com/docs/api/processing/#file-type
  | [
      'output',
      {
        format: string;
        density?: number;
        quality?: number;
      }
    ]
  // https://www.filestack.com/docs/api/processing/#resize
  | [
      'resize',
      {
        height?: number;
        width?: number;
        fit?: 'clip' | 'crop' | 'scale' | 'max';
        align?: 'top' | 'bottom' | 'left' | 'right' | 'faces' | 'center';
      }
    ]
  // https://www.filestack.com/docs/api/processing/#crop
  | [
      'crop',
      {
        dim: [x: number, y: number, width: number, height: number];
      }
    ]
  // https://www.filestack.com/docs/api/processing/#quality
  | [
      'quality',
      {
        value: number;
      }
    ]
  // https://www.filestack.com/docs/api/processing/#compress
  | ['compress'];

export function withTransforms(url: string, transforms: ImageTransform[]) {
  const str = transforms.reduce((acc, [transform, opts]) => {
    if (opts) {
      const optsList = Object.entries(opts)
        .reduce((prev, [k, v]) => `${prev},${k}:${stringify(v)}`, '')
        .slice(1);
      return `${acc}/${transform}=${optsList}`;
    }
    return `${acc}/${transform}`;
  }, '');

  return url.replace(/\.(com|net)\//, `.$1${str}/`);
}

function stringify(value: string | number | unknown[]): string {
  if (typeof value === 'object') {
    return JSON.stringify(Array.isArray(value) ? value.map(sanitize) : value);
  }
  return `${sanitize(value)}`;
}

function sanitize(value: unknown): unknown {
  if (typeof value === 'number') return Math.floor(value);
  return value;
}

export function cdn(url: string, transforms: ImageTransform[]) {
  const imageTransforms = transforms ?? [];
  if (!imageTransforms.some(([type]) => type === 'compress')) imageTransforms.push(['compress']);

  return withTransforms(url, imageTransforms).replace(
    /^https?:\/\/.+\.(com|net)\//,
    'https://s1.citypass.net/_next/gcms/media/'
  );
}

export function fullWidth(url: Maybe<string>) {
  return cdn(url ?? '', [
    ['resize', { width: Dimensions.get('window').width * 2 }],
    ['quality', { value: 25 }],
  ]);
}

export function getRatio(
  image: Maybe<{ width: Maybe<number>; height: Maybe<number> }>,
  fallback?: number
) {
  if (!image?.width || !image?.height) return fallback ?? 1;
  return image.width / image.height;
}

export function getCropDim(
  image: { width: number; height: number },
  { width, height }: { width: number; height: number }
): [x: number, y: number, width: number, height: number] {
  return [
    Math.max(0, image.width / 2 - width / 2),
    Math.max(0, image.height / 2 - height / 2),
    width,
    height,
  ];
}
