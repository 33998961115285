import { Box, Heading, HStack, IBoxProps, Text, useToken, VStack } from 'native-base';
import React, { useState } from 'react';

import HTML from 'app/components/HTML';
import RatingStarRibbon from 'app/components/icons/RatingStarRibbon';
import RestaurantForkSpoon from 'app/components/icons/RestaurantForkSpoon';
import ShoppingBagTag from 'app/components/icons/ShoppingBagTag';
import TicketAdd from 'app/components/icons/TicketAdd';
import TicketExchange from 'app/components/icons/TicketExchange';
import TextLink from 'app/components/TextLink';
import { AllAttractionPropsFragment, AttractionOfferType } from 'app/generated/hygraph';
import { IconComponent } from 'app/hocs/withIconWrapper';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics from 'app/services/Analytics';
import { Events } from 'app/services/Analytics.types';
import { formatDate as formatDateShort } from 'app/services/I18n';

const icons: Record<AttractionOfferType, IconComponent> = {
  dining: RestaurantForkSpoon,
  exclusive: RatingStarRibbon,
  retail: ShoppingBagTag,
  shared: TicketExchange,
  upgrade: TicketAdd,
};

export type OfferCardProps = IBoxProps & {
  offer: Pick<
    Single<AllAttractionPropsFragment['offers']>,
    | 'id'
    | 'internalTitle'
    | 'title'
    | 'description'
    | 'conditions'
    | 'code'
    | 'type'
    | 'expirationDate'
    | 'attraction'
  > & { subtitle?: string };
  productContentKey: string;
  theme?: 'default' | 'highlight';
};

export default function OfferCard({
  offer: {
    id,
    internalTitle,
    title,
    subtitle,
    description,
    conditions,
    code,
    expirationDate,
    type,
    attraction,
  },
  productContentKey,
  theme = 'default',
  ...props
}: OfferCardProps) {
  const { t, locale } = useI18n();
  const brandSecondaryColor = useToken('colors', 'brand.secondary');
  const Icon = icons[type] ?? icons.exclusive;
  const [showCode, setShowCode] = useState(false);

  const handleRedeemCode = () => {
    setShowCode(true);
    Analytics.trackEvent(Events.OffersRedeemOffer, {
      offerId: id,
      internalTitle,
      attractionKey: attraction?.key ?? '',
      productKey: productContentKey,
    });
  };

  return (
    <Box
      borderColor={theme === 'highlight' ? 'brand.primary' : 'brand.secondary'}
      borderStyle="dashed"
      borderWidth="1"
      p="3"
      rounded="md"
      testID="OfferCard"
      w="72"
      {...props}
    >
      <VStack space="2">
        <HStack alignItems="center" space="2">
          <Icon color={brandSecondaryColor} />
          <Box>
            <Heading fontSize="sm">{title}</Heading>
            {subtitle && <Text fontSize="sm">{subtitle}</Text>}
          </Box>
        </HStack>

        <VStack space="2">
          {!!description && (
            <HTML
              defaultTextProps={{
                selectable: true,
              }}
              html={`${description} ${conditions || ''}`}
            />
          )}
          {!!expirationDate && (
            <Text fontSize="xs">Exp: {formatDateShort(expirationDate, locale)}</Text>
          )}
          {showCode ? (
            <Text selectable>
              {t('app_offer_redeem_label', { code: code || t<string>('app_offer_redeem_no_code') })}
            </Text>
          ) : (
            <TextLink onPress={handleRedeemCode}>{t('app_offer_redeem_cta')}</TextLink>
          )}
        </VStack>
      </VStack>
    </Box>
  );
}
