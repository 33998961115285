import { Box, IBoxProps, IImageProps, Image } from 'native-base';
import React from 'react';

export type AspectImageProps = IBoxProps & {
  url: string;
  ratio: number;
  alt: string;
  _image?: IImageProps;
};

export default function AspectImage({
  url,
  ratio = 4 / 3,
  alt = 'Image',
  _image,
  ...props
}: AspectImageProps) {
  return (
    <Box style={{ aspectRatio: ratio }} {...props} testID="AspectImage">
      <Image h="full" source={{ uri: url }} w="full" {..._image} alt={alt} />
    </Box>
  );
}
