import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Reservation({ color, size, ...props }) {
  return (
    <Svg fill="none" height={size} viewBox="0 0 23.767 23.767" width={size} {...props}>
      <Path
        d="M0 11.259A11.258 11.258 0 1011.258 0 11.258 11.258 0 000 11.259zM17.289 17.289l-4.845-4.846"
        stroke={color}
        transform="translate(.625 .625)"
      />
      <Path
        d="M9.614 11.258a1.645 1.645 0 101.645-1.645 1.645 1.645 0 00-1.645 1.645zM11.259 4.602v4.3"
        stroke={color}
        transform="translate(.625 .625)"
      />
    </Svg>
  );
});
