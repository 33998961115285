import * as Linking from 'expo-linking';

import { GetProductScreenBlocksQuery, Locale } from 'app/generated/hygraph';
import { ProductContent } from 'app/hooks/useProduct';
import { expandTpl } from 'app/services/I18n';
import { getSubdomainForLocale } from 'app/services/LinkHelper';

export type CMSBlock = Single<NonNullable<GetProductScreenBlocksQuery['blockGroup']>['blocks']>;

export function createBlock(
  block: CMSBlock,
  { productContent, locale }: { productContent?: ProductContent; locale?: Locale }
) {
  return {
    id: block.id,
    title: getTitle(block),
    body: getBody(block),
    cta: getCTA(block),
    image: getImage(block),
    url: getURL(block, { productContent, locale }),
    ui: getUI(block),
    theme: getTheme(block),
    hasContent: hasContent(block),
    isEmpty: isEmpty(block),
  };
}

function getTitle(block: CMSBlock) {
  return block.title?.value ?? '';
}

function getBody(block: CMSBlock) {
  return block.body?.value ?? '';
}

function getCTA(block: CMSBlock) {
  return block.cta?.value ?? '';
}

function getImage(block: CMSBlock) {
  return block.image?.url ?? '';
}

export function getImageRatio(block: CMSBlock) {
  return (block.image?.width ?? 1) / (block.image?.height ?? 1);
}

function getURL(
  block: CMSBlock,
  { productContent, locale = Locale.En }: { productContent?: ProductContent; locale?: Locale }
) {
  const url = expandTpl(block.url ?? '', {
    props: {
      productId: productContent?.key ?? '',
      locale,
      subdomain: getSubdomainForLocale(locale),
    },
  });

  return url.startsWith('http') ? url : Linking.createURL(url);
}

function getUI(block: CMSBlock) {
  return block.ui?.[0] || {};
}

function getTheme(block: CMSBlock) {
  const { colorScheme } = getUI(block);
  const themes: Record<string, any> = {
    light: {
      title: {
        color: undefined,
      },
      body: {
        color: undefined,
      },
    },
    dark: {
      title: {
        color: 'white',
      },
      body: {
        color: 'white',
      },
    },
  };

  return themes[colorScheme];
}

export function getComponentName(block: CMSBlock) {
  return getUI(block).component ?? null;
}

function hasContent(block: CMSBlock) {
  return !!(getTitle(block) || getBody(block) || getCTA(block));
}

function isEmpty(block: CMSBlock) {
  return !hasContent(block) && !getImage(block);
}
