import { useEffect, useRef } from 'react';

export default function usePrevious<T>(value: T): [T, (newValue: T) => void] {
  const ref = useRef<T>(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return [
    ref.current,
    (newValue: T) => {
      ref.current = newValue;
    },
  ];
}
