import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient';
import { Box } from 'native-base';
import React, { ReactNode } from 'react';
import { ImageBackground } from 'react-native';

import { tID } from 'app/services/TestHelper';

export type HeroProps = {
  url: string;
  hideGradient?: boolean;
  aspectRatio?: number;
  children?: ReactNode;
  dynamicHeight?: boolean;
  _gradient?: Partial<LinearGradientProps>;
};

export default function Hero({
  children,
  aspectRatio: $aspectRatio = 5 / 2,
  dynamicHeight = false,
  hideGradient,
  _gradient,
  url,
}: HeroProps) {
  const aspectRatio = dynamicHeight ? undefined : $aspectRatio;
  const imageHeight = aspectRatio ? '100%' : undefined;

  return (
    <Box bg="gray.200" position="relative" style={{ aspectRatio }} testID={tID('Hero')}>
      <ImageBackground source={{ uri: url }} style={{ width: '100%', height: imageHeight }}>
        {!hideGradient && (
          <LinearGradient
            colors={['rgba(0,0,0,0)', 'rgba(0,0,0,.1)', 'rgba(0,0,0,.5)']}
            locations={[0, 0.6, 1]}
            style={{
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              aspectRatio,
            }}
            {..._gradient}
          />
        )}
        {children}
      </ImageBackground>
    </Box>
  );
}
