import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function SvgComponent({ color, size, ...props }) {
  return (
    <Svg height={size} viewBox="0 0 23.75 23.75" width={size} {...props}>
      <G fill="none" stroke={color} strokeLinejoin="round" strokeWidth={1.25}>
        <Path
          d="M8.315 15.652H.978A.978.978 0 010 14.674v-2.966a.489.489 0 01.427-.489 3.424 3.424 0 000-6.795A.489.489 0 010 3.935V.978A.978.978 0 01.978 0h20.543a.978.978 0 01.978.978v2.965a.489.489 0 01-.427.489 3.424 3.424 0 00-2.959 3.9"
          transform="translate(.625 .625)"
        />
        <Path
          d="M10.761 16.631a5.87 5.87 0 105.87-5.87 5.87 5.87 0 00-5.87 5.87zM16.63 13.695v5.87M19.565 16.63h-5.87"
          transform="translate(.625 .625)"
        />
      </G>
    </Svg>
  );
});
