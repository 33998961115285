import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import withIconWrapper from 'app/hocs/withIconWrapper';

export default withIconWrapper(function Share({ color, size, ...props }) {
  return (
    <Svg fill={color} height={size} viewBox="0 0 13.233 17.5" width={size} {...props}>
      <G
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        transform="translate(-3)"
      >
        <Path
          d="M13.35 6.083h1.067a1.067 1.067 0 011.067 1.067v8.533a1.067 1.067 0 01-1.067 1.067h-9.6a1.067 1.067 0 01-1.067-1.067V7.15a1.067 1.067 0 011.067-1.067h1.066"
          data-name="Path 155153"
        />
        <Path d="M0 0L0 7.467" data-name="Line 596" transform="translate(9.617 .75)" />
        <Path d="M6.95 3.417L9.617.75l2.667 2.667" data-name="Path 155154" />
      </G>
    </Svg>
  );
});
