import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { IAlertProps } from 'native-base';

import { GetNoticesQuery, NoticePlacements, NoticeTheme } from 'app/generated/hygraph';
import { isActive } from 'app/services/TargetingHelper';

export type Notice = Single<GetNoticesQuery['notices']>;

export function getTitle(notice: Maybe<Notice>) {
  return notice?.title ?? '';
}

export function getDescription(notice: Maybe<Notice>) {
  return notice?.description ?? '';
}

export function getTheme(notice: Maybe<Notice>): NoticeTheme {
  return notice?.theme ?? NoticeTheme.Default;
}

const themeToStatus: Record<NoticeTheme, IAlertProps['status']> = {
  [NoticeTheme.Default]: 'info',
  [NoticeTheme.Info]: 'info',
  [NoticeTheme.Warn]: 'warning',
  [NoticeTheme.Danger]: 'error',
};

export function getThemeAsAlertStatus(notice: Maybe<Notice>) {
  return themeToStatus[getTheme(notice)] ?? 'info';
}

export function getProductName(notice: Maybe<Notice>) {
  return notice?.products[0]?.name ?? '';
}

export function getAttractionName(notice: Maybe<Notice>) {
  return notice?.attractions[0]?.name ?? '';
}

export function hasPlacement(notice: Maybe<Notice>, placement: NoticePlacements) {
  return notice?.placements.includes(placement) ?? false;
}

export function hasProduct(notice: Maybe<Notice>, key: string) {
  return notice?.products.some((p) => p.key === key) ?? false;
}

export function hasAttraction(notice: Maybe<Notice>, key: string) {
  return notice?.attractions.some((a) => a.key === key) ?? false;
}

export function hasAttractions(notice: Maybe<Notice>) {
  return (notice?.attractions.length ?? 0) > 0;
}

export function getActive(notices: Maybe<Notice[]>) {
  return notices?.filter(isActive) ?? [];
}

export function getByPlacement(notices: Maybe<Notice[]>, placement: NoticePlacements) {
  return notices?.filter((notice) => hasPlacement(notice, placement)) ?? [];
}

export type ProductNoticeGroup = {
  product: { key: Maybe<string>; name: Maybe<string> };
  notices: Notice[];
};

export function groupByProducts(
  notices: Maybe<Notice[]>,
  products: { key: Maybe<string>; name: Maybe<string> }[]
) {
  const $products = sortBy(
    uniqBy(products, (p) => p.key),
    (p) => p.key
  );
  const noticesByProductContentKey = (notices ?? []).reduce<{
    [productKey: string]: ProductNoticeGroup;
  }>((acc, notice) => {
    notice.products.forEach((product) => {
      const key = product.key ?? '';
      acc[key] = acc[key] || {
        product,
        notices: [],
      };

      const $notice = decorateWithAttractionName(notice);
      const hasSameContent = acc[key].notices.some((n) => isSameContent(n, $notice));
      if (!hasSameContent) {
        acc[key].notices.push($notice);
      }
    });

    return acc;
  }, {});

  return $products.map((product) => {
    return {
      product,
      notices: sortBy(
        noticesByProductContentKey[product.key ?? '']?.notices ?? [],
        (n) => n.attractions?.[0]?.key ?? ''
      ),
    };
  });
}

function isSameContent(a: Notice, b: Notice) {
  return getTitle(a) === getTitle(b) && getDescription(a) === getDescription(b);
}

function decorateWithAttractionName(notice: Notice) {
  if (!hasAttractions(notice)) return notice;

  return {
    ...notice,
    title: `${getAttractionName(notice)}: ${getTitle(notice)}`,
  };
}
