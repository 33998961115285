/* eslint-disable react/no-array-index-key */
import React, { Children, useEffect, useState } from 'react';

type StaggeredItemProps = {
  children: JSX.Element;
  position: number;
  delay?: number;
};

function StaggeredItem({ children, position, delay = 100 }: StaggeredItemProps) {
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (render) return undefined;
    const to = setTimeout(() => setRender(true), position * delay);
    return () => clearTimeout(to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, delay]);

  return render ? children ?? null : null;
}

export type StaggeredListProps = {
  children: JSX.Element[];
  interval?: number;
};

function StaggeredList({ children, interval }: StaggeredListProps) {
  return Children.map(children, (child, i) => (
    <StaggeredItem key={i} delay={interval} position={i}>
      {child}
    </StaggeredItem>
  ));
}

StaggeredList.Item = StaggeredItem;

export default StaggeredList;
