import { NativeBaseProvider } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';

import DevToolsFAB from 'app/components/DevToolsFAB';
import RenderHTMLProvider from 'app/providers/RenderHTMLProvider';
import theme from 'app/theme';

export default function withNativeBase(Component: (props: any) => JSX.Element | null) {
  if (Platform.OS !== 'ios') return Component;

  function WrappedComponent(props: any) {
    return (
      <NativeBaseProvider theme={theme}>
        <RenderHTMLProvider>
          {Component ? <Component {...props} /> : null}
          {__DEV__ && Platform.OS !== 'web' && <DevToolsFAB />}
        </RenderHTMLProvider>
      </NativeBaseProvider>
    );
  }
  WrappedComponent.name = Component?.name ?? 'UNKNOWN';

  return WrappedComponent;
}
