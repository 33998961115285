import {
  Box,
  HStack,
  IBoxProps,
  IIconButtonProps,
  IIconProps,
  IPopoverProps,
  ITextProps,
  Popover as NBPopover,
} from 'native-base';
import React, { ReactNode } from 'react';

import { useI18n } from 'app/providers/I18nProvider';

export type CPPopoverProps = IPopoverProps & {
  children?: ReactNode;
  bg?: IBoxProps['bg'];
  color?: ITextProps['color'];
  _close?: IIconButtonProps;
};

function CPPopover({ children, trigger, bg, color, _close, ...props }: CPPopoverProps) {
  const bgTheme = bg ? { bg } : undefined;
  const colorTheme = color ? { color } : undefined;

  return (
    <NBPopover placement="bottom" {...props} trigger={trigger}>
      <NBPopover.Content testID="Popover" {...bgTheme} maxWidth="330px">
        <NBPopover.Arrow {...bgTheme} />
        <NBPopover.Body {...bgTheme}>
          <HStack alignItems="flex-start" justifyContent="flex-start">
            <Box flexShrink="1">{children}</Box>
            <Box _web={{ flexShrink: 0 }} flexShrink="1">
              <CPPopover.CloseButton _icon={colorTheme} {..._close} />
            </Box>
          </HStack>
        </NBPopover.Body>
      </NBPopover.Content>
    </NBPopover>
  );
}

CPPopover.CloseButton = function CPCloseButton({
  colorTheme,
  ...props
}: IIconButtonProps & {
  colorTheme?: IIconProps;
}) {
  const { t } = useI18n();
  return (
    <NBPopover.CloseButton
      _hover={{ bg: 'black:alpha.10' }}
      _icon={colorTheme}
      _pressed={{ bg: 'black:alpha.20' }}
      accessibilityLabel={`${t<string>('gen_close')} Popover`}
      m="-3"
      padding={18}
      position="relative"
      right="0"
      rounded="md"
      top="0"
      {...props}
    />
  );
};

export default CPPopover;
