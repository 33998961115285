/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';

import HeaderStatusBar from 'app/components/HeaderStatusBar';
import useHeaderScreenOptions from 'app/hooks/useHeaderScreenOptions';
import useStore from 'app/hooks/useStore';
import { useI18n } from 'app/providers/I18nProvider';
import ProductFAQScreen from 'app/screens/ProductFAQScreen';
import ProductOffersScreen from 'app/screens/ProductOffersScreen';
import ProductScreen from 'app/screens/ProductScreen';

export type {
  ProductTabParamList,
  ProductTabScreenCompositeProps as ProductTabScreenProps,
} from 'app/navigation/ProductTabNavigator';

export type ProductStackParamList = {
  Product: {
    productId: string;
  };
  ProductFAQ: {
    productId: string;
  };
  ProductOffers: {
    productId: string;
  };
};

export type ProductStackScreenProps<Screen extends keyof ProductStackParamList> =
  NativeStackScreenProps<ProductStackParamList, Screen>;

const Stack = createNativeStackNavigator<ProductStackParamList>();

export default function ProductStackNavigator() {
  const { t } = useI18n();
  const productId = useStore((s) => s.productId);
  const headerScreenOptions = useHeaderScreenOptions();

  return (
    <Stack.Navigator initialRouteName="Product" screenOptions={headerScreenOptions}>
      <Stack.Screen
        component={ProductScreen}
        initialParams={productId ? { productId } : undefined}
        name="Product"
        options={{ header: () => <HeaderStatusBar /> }}
      />

      <Stack.Screen
        component={ProductFAQScreen}
        initialParams={productId ? { productId } : undefined}
        name="ProductFAQ"
        options={{
          title: t<string>('app_product_faq_screen_title'),
        }}
      />

      <Stack.Screen
        component={ProductOffersScreen}
        initialParams={productId ? { productId } : undefined}
        name="ProductOffers"
        options={{
          title: t<string>('app_product_offers_screen_title'),
        }}
      />
    </Stack.Navigator>
  );
}
